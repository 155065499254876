export const enData = `
[global]

Account = Account
AccountNumber = Account Number
Accounts = Accounts
Add = Add
AddToQueue = Add to queue
adding = adding...
AddMoreItem = Add more item information
AddMoreVendor = Add more vendor information
AddNewIndicator = Add new
AddNewItem = Add new item
addNewSentence = Add new
AddNewVendor = Add new vendor
AddOtherCosts = Add other costs
Address = Address
Addresses = Addresses
AddLines = Add lines
AddLine = Add line
AltPhone = Alt Phone
Apply = Apply
Assembly = Assembly
Attachment = Attachment
Amount = Amount
Archive = Archive
archived = archived
AsOf = As of
at = at
AsOfDate = As of date
AssignedByQuickBooks = Assigned by QuickBooks
assignedTo = assigned to
Automatic = Automatic
Available = Available

BatchActions = Batch actions
Bill = Bill
BillofMaterials = Bill of Materials
Billing = Billing
Boxes = Boxes

Calendar = Calendar
Cancel = Cancel
Category = Category
Categories = Categories
Checkbox = Checkbox 
City = City
clearOtherCostsTitle = Clear other costs
Columns = Columns
CommentHistory = Comment history
Completed = Completed
Compute = Compute
Customize = Customize
Class = Class
Clear = Clear
clearLinesMessage = You are about to clear data from all line items. Proceed?
clearOtherCostsMessage = You are about to clear data from all other costs. Proceed?
ClearLines = Clear lines
Close = Close
Closed = Closed
colon = ":"
Contents = Contents
CostBasisAdjustment = Cost basis adjustment
Contact = Contact
Company = Company
Country = Country
CreditAmount = Credit amount
Currency= Currency
CurrentlyUnavailable = currently unavailable
CustomField = Custom field
CustomFields = Custom fields
Customer = Customer

Date = Date
DateSelector = Date selector
Days = Days
Dashboard = Dashboard
Default = Default
DefaultBilling = Default billing
DefaultShipping = Default shipping
DefaultTax = Default tax
Delete = Delete
deleteSelectedRecords = You are about to delete the selected records. Proceed?
deleteRecordMessage = You are about to permanently delete {{objectType}} {{identifier}}. Proceed?
Department = Department
Description = Description
Details = Details
Discount = Discount
DiscountAmount = Discount Amt
DiscountPercent = 'Discount %'
Display = Display
Documents = Documents
Done = Done
Download = Download
DropdownLoading = loading...

Edit = Edit
Email = Email
Entered = Entered
endingIn = ending in
Error = Something went wrong.
Example = Example
ExchangeRate = Exchange rate
Expand = Expand
expiring = expiring
ExitConfiguration = Exit configuration

Fax = Fax
FeatureRequests = Feature requests
Fill = Fill
LineItemFillHoverText = Fill all lines with the value on line 1
FirstName = First name
for = for
Footer = Footer
Format = Format
from = from
FromDate = From date
Fulfillment = Fulfillment

Generate = Generate
GeneratePdf = Generate PDF
GeneratePrintLabel = Generate print label
GeneratePurchaseOrders = Generate purchase orders
GlobalSearch = Global search

Header = Header
Height = Height
History = History
Home = Home

input = input
Inputs = Inputs
Inventory = Inventory
Item = Item

JobDashboard = Job dashboard

Label = Label
LastName = Last name
LeaveThisPage = Are you sure you want to leave this page?
LeaveThisPageHeader = Leave this page?
Line = Line
LineNumber = "Line #"
List = List
Loading = Loading
Loading.Lower = loading
Location = Location
LocationSettings = Location settings
Lots = Lots

Manual = Manual
Master = Master
MerchantTrack = Merchant track
Merge = Merge
mergeSelectedRecords = Are you sure you want to merge these {{objectType}}?
MiddleName = Middle name
MobilePhone = Mobile phone
Modify = Modify
more = more
Money = Money
Months = Months
MultiLineText = Multiline text

Name = Name
Next = Next
No = No
NoCommentHistory = No comment history
NoAddressEntered = No address entered
NoSignature = No signature
Notes = Notes
None = None
Now = Now
Number = Number

of = of
OK = OK
Open = Open
or = or
Other = Other
Output = Output
output = output
Outputs = Outputs
OtherTransactions = Other transactions

Pdf = PDF
per = per
pendingApproval = pending approval
Phone = Phone
predefinedMessages = Predefined messages
Print = Print
Production = Production
PurchaseCost = Purchase
Purchasing = Purchasing
Produce = Produce
ProfitLoss = "Profit/loss"

Quantity = Quantity
QuantityAdjustment = Quantity adjustment
QuickDateSelector = Quick date selector 
QuickSearchPlaceholder = Quick search ...

Random = Random
Ratio = Ratio
RecentTransactions = Recent transactions
RefreshExchangeRate = Refresh exchange rate
Refund = Refund
RefundSelectedRecords = Are you sure you want to refund these payments?
Remove = Remove
Reports = Reports
ReportSchedule = Report schedule
RestoreStages = Restore stages
RestoreDefaultTemplates = Restore default templates

Sales = Sales
SalesPrice = Sales price
Sample = Sample
Save = Save
Saved = Saved
SaveReport = Save report
Search = Search
SearchTips = Search tips
SearchResults = Search results 
SeeImage = See image
Selected = Selected
selectOptionNone = (none)
Send = Send
Sequential = Sequential
SerialInventory = Serial Inventory
Serials = Serials
Settings = Settings
Shipping = Shipping
ShippingAmount = Shipping Amt
showMore = show more
ShowOnForms = Show on forms
SmartSearch = Smart search
SomethingWentWrong = Something went wrong. Please check your data and resubmit, or contact support by email (support@sosinventory.com) or phone (817-422-5909).
SosPayments = SOS payments
SosInventory = SOS Inventory
Stages = Stages
State/Province = State/Province
Status = Status
Subtotal = Subtotal
Suffix = Suffix
Submit = Submit
Summary = Summary
Sync = Sync

tagged = tagged
Tax = Tax
TaxPercent = 'Tax %'
Template = Template
TemplateLibrary = Template library
Terms = Terms
Text = Text
Title = Title
to = to
ToDate = To date
Total = Total
Totals = Totals
Type = Type
TypeYourQuery = Type your query

UOM = UOM
Update = Update
Unarchive = Unarchive
Unfavorite = Unfavorite
Unit = Unit
UnitsOfMeasure = Units of measure
UpdateInputs = Update inputs
UseAsTemplate = Use as template

Variants = Variants
Vendor = Vendor
Via = Via
Volume = Volume

Website = Website
Weeks = Weeks
Weight = Weight
What = What
When = When
WhichOne = Which one
Width = Width
Who = Who

Years = Years
Yes = Yes

OnSO = On SO
OnWO = On WO
OnRMA = On RMA
OnPO = On PO
OnSR = On SR
OnRental = On rental
OnHand = On hand

QuickDateSelector = Quick date selector

[objectType]
account.Lower = account
account.SentencePlural = Accounts

adjustment.Lower = adjustment
adjustment.LowerPlural = adjustments
adjustment.New = New adjustment
adjustment.Sentence = Adjustment
adjustment.SentencePlural = Adjustments

alert.Lower = alert
alert.LowerPlural = alerts
alert.New = New alert
alert.Sentence = Alert
alert.SentencePlural = Alerts

aoprule.Lower = AOP rule
aoprule.LowerPlural = AOP rules
aoprule.New = New AOP rule
aoprule.Sentence = AOP rule
aoprule.SentencePlural = AOP rules

attachment.SentencePlural = Attachments

bins.Lower = bin
bins.LowerPlural = bins
bins.New = New bin
bins.Sentence = Bin
bins.SentencePlural = Bins

bill.Lower = bill
bill.Sentence = Bill
bill.SentencePlural = Bills

build.in.SentencePlural = Build inputs
build.Lower = build
build.LowerPlural = builds
build.New = New build
build.out.SentencePlural = Build outputs
build.Sentence = Build
build.SentencePlural = Builds

channel.Lower = channel
channel.LowerPlural = channels
channel.New = New channel
channel.Sentence = Channel
channel.SentencePlural = Channels

class.Lower = class
class.LowerPlural = classes
class.New = New class
class.Sentence = Class
class.SentencePlural = Classes

creditmemo.Lower = credit memo
creditmemo.Sentence = Credit memo
creditmemo.SentencePlural = Credit memos

customfield.Lower = custom field
customfield.LowerPlural = custom fields
customfield.New = New custom field
customfield.Sentence = Custom field
customfield.SentencePlural = Custom fields

customer.Lower = customer
customer.LowerPlural = customers
customer.Sentence = Customer
customer.SentencePlural = Customers

customermessage.Lower = customer message
customermessage.LowerPlural = customer messages
customermessage.New = New customer message
customermessage.Sentence = Customer message
customermessage.SentencePlural = Customer messages

customertype.Lower = customer type
customertype.LowerPlural = customer types
customertype.New = New customer type
customertype.Sentence = Customer type
customertype.SentencePlural = Customer types

deleteditem.LowerPlural = deleted items
deleteditem.Lower = deleted item
deleteditem.SentencePlural = Deleted items

department.Lower = department
department.LowerPlural = departments
department.New = New department
department.Sentence = Department
department.SentencePlural = Departments

document.Lower = document or file
document.LowerPlural = documents and files
document.New = New document or file
document.Sentence = Document or file
document.SentencePlural = Documents and files

employee.SentencePlural = Employees

estimate.Lower = estimate
estimate.LowerPlural = estimates
estimate.New = New estimate
estimate.Sentence = Estimate
estimate.SentencePlural = Estimates

fileanddocument.Lower = file and document
fileanddocument.LowerPlural = files and documents
fileanddocument.New = New file and document
fileanddocument.Sentence = File and document
fileanddocument.SentencePlural = Files and documents

formtemplate.Lower = form template
formtemplate.LowerPlural = form templates
formtemplate.New = New form templates
formtemplate.Sentence = Form template
formtemplate.SentencePlural = Form templates

fspayment.Lower = SOS payment
fspayment.LowerPlural = SOS payments
fspayment.Sentence = SOS payment
fspayment.SentencePlural = SOS payments

invoice.Lower = invoice
invoice.LowerPlural = invoices
invoice.New = New invoice
invoice.Sentence = Invoice
invoice.SentencePlural = Invoices

item.Lower = item
item.LowerPlural = items
item.Sentence = Item
item.SentencePlural = Items

itemreceipt.Lower = item receipt
itemreceipt.LowerPlural = item receipts
itemreceipt.New = New item receipt
itemreceipt.Sentence = Item receipt
itemreceipt.SentencePlural = Item receipts

job.Lower = job
job.LowerPlural = jobs
job.New = New job
job.Sentence = Job
job.SentencePlural = Jobs

journalentry.Lower = journal entry
journalentry.Sentence = Journal entry
journalentry.SentencePlural = Journal entries

location.Lower = location
location.LowerPlural = locations
location.New = New location
location.Sentence = Location
location.SentencePlural = Locations

lot.Lower = lot
lot.LowerPlural = lots
lot.New = New lot
lot.Sentence = Lot
lot.SentencePlural = Lots

notification.Lower = notification
notification.LowerPlural = notifications
notification.New = New notification
notification.Sentence = Notification
notification.SentencePlural = Notifications

orderstage.Lower = order stage
orderstage.LowerPlural = order stages
orderstage.New = New order stage
orderstage.Sentence = Order stage
orderstage.SentencePlural = Order stages

payment.Lower = payment
payment.LowerPlural = payments
payment.New = New payment
payment.Sentence = Payment
payment.SentencePlural = Payments

paymentmethod.Lower = payment method
paymentmethod.LowerPlural = payment methods
paymentmethod.New = New payment method
paymentmethod.Sentence = Payment method
paymentmethod.SentencePlural = Payment methods

pickticket.Lower = pick ticket
pickticket.LowerPlural = pick tickets
pickticket.New = New pick ticket
pickticket.Sentence = Pick ticket
pickticket.SentencePlural = Pick tickets

process.in.SentencePlural = Process inputs
process.Lower = process
process.LowerPlural = processes
process.New = New process
process.out.SentencePlural = Process outputs
process.Sentence = Process
process.SentencePlural = Processes

processtemplate.Lower = process template
processtemplate.LowerPlural = process templates
processtemplate.New = New process template
processtemplate.Sentence = Process template
processtemplate.SentencePlural = Process templates

pricetier.Lower = price tier
pricetier.LowerPlural = price tiers
pricetier.New = New price tier
pricetier.Sentence = Price tier
pricetier.SentencePlural = Price tiers

priority.Lower = priority
priority.LowerPlural = priorities
priority.New = New priority
priority.Sentence = Priority
priority.SentencePlural = Priorities

purchase.Lower = purchase
purchase.Sentence = Purchase
purchase.SentencePlural = Purchases

purchaseorder.Lower = purchase order
purchaseorder.LowerPlural = purchase orders
purchaseorder.New = New purchase order
purchaseorder.Sentence = Purchase order
purchaseorder.SentencePlural = Purchase orders

reorder.Lower = reorder
reorder.LowerPlural = reorder reminders
reorder.Sentence = Reorder
reorder.SentencePlural = Reorder list

reorder.Lower = reorder
reorder.LowerPlural = reorder reminders
reorder.New = New reorder item
reorder.Sentence = Reorder
reorder.SentencePlural = Reorder list

rental.Lower = rental
rental.LowerPlural = rentals
rental.New = New rental
rental.Sentence = Rental
rental.SentencePlural = Rentals

rentalreturn.Lower = rental return
rentalreturn.LowerPlural = rental returns
rentalreturn.New = New rental return
rentalreturn.Sentence = Rental return
rentalreturn.SentencePlural = Rental returns

return.Lower = return
return.LowerPlural = returns
return.New = New return
return.Sentence = Return
return.SentencePlural = Returns

returntovendor.Lower = return to vendor
returntovendor.LowerPlural = returns to vendors
returntovendor.New = New return to vendor
returntovendor.Sentence = Return to vendor
returntovendor.SentencePlural = Returns to vendors

rma.Lower = RMA
rma.LowerPlural = RMAs
rma.New = New RMA
rma.Sentence = RMA
rma.SentencePlural = RMAs

salesorder.Lower = sales order
salesorder.LowerPlural = sales orders
salesorder.New = New sales order
salesorder.Sentence = Sales order
salesorder.SentencePlural = Sales orders

salesreceipt.Lower = sales receipt
salesreceipt.LowerPlural = sales receipts
salesreceipt.New = New sales receipt
salesreceipt.Sentence = Sales receipt
salesreceipt.SentencePlural = Sales receipts

salesterm.Sentence = Sales term

salesrep.Lower = sales rep
salesrep.LowerPlural = sales reps
salesrep.New = New sales rep
salesrep.Sentence = Sales rep
salesrep.SentencePlural = Sales reps

serial.Lower = serial item
serial.LowerPlural = serial items
serial.New = New serial item
serial.Sentence = Serial item
serial.SentencePlural = Serial items

serialinventory.SentencePlural = Serial inventory

shipment.Lower = shipment
shipment.LowerPlural = shipments
shipment.New = New shipment
shipment.Sentence = Shipment
shipment.SentencePlural = Shipments

shipmethod.Lower = Shipping method
shipmethod.LowerPlural = Shipping methods
shipmethod.New = New shipping method
shipmethod.Sentence = Shipping method
shipmethod.SentencePlural = Shipping methods

syncerror.Lower = sync error
syncerror.LowerPlural = sync errors
syncerror.New = New sync error
syncerror.Sentence = Sync error
syncerror.SentencePlural = Sync errors

syncitem.Sentence = Sync item
syncitem.Lower = sync item
syncitem.LowerPlural = sync items
syncitem.SentencePlural = Sync items

tag.Lower = tag
tag.LowerPlural = tags
tag.New = New tag
tag.Sentence = Tag
tag.SentencePlural = Tags

task.Lower = task
task.LowerPlural = tasks
task.New = New task
task.Sentence = Task
task.SentencePlural = Tasks

taxcode.SentencePlural = Tax codes

templatelibrary.Lower = library template
templatelibrary.LowerPlural = template library
templatelibrary.New = New library template
templatelibrary.Sentence = Library template
templatelibrary.SentencePlural = Template library

terms.Lower = term
terms.LowerPlural = terms
terms.New = New term
terms.Sentence = Term
terms.SentencePlural = Terms

transfer.Lower = transfer
transfer.LowerPlural = transfers
transfer.New = New transfer
transfer.Sentence = Transfer
transfer.SentencePlural = Transfers

user.Lower = user
user.LowerPlural = users
user.New = New user
user.Sentence = User
user.SentencePlural = Users

vendor.Lower = vendor
vendor.LowerPlural = vendors
vendor.Sentence = Vendor
vendor.SentencePlural = Vendors

vendorcredit.Sentence = Vendor credit

vendoritem.Lower = vendor item catalog
vendoritem.LowerPlural = vendor items catalog
vendoritem.New = New vendor item
vendoritem.Sentence = Vendor-item catalog
vendoritem.SentencePlural = Vendor-item catalog

workorder.Lower = work order
workorder.LowerPlural = work orders
workorder.New = New work order
workorder.Sentence = Work order
workorder.SentencePlural = Work orders

uom.Lower = unit of measure
uom.LowerPlural = Units of measure
uom.New = New unit of measure
uom.Sentence = Unit of measure
uom.SentencePlural = Units of measure

upslabel.Sentence = UPS label

upsshipment.Lower = UPS shipment
upsshipment.LowerPlural = UPS shipments
upsshipment.New = New UPS shipment
upsshipment.Sentence = UPS shipment
upsshipment.SentencePlural = UPS shipments

vendormessage.Lower = vendor message
vendormessage.LowerPlural = vendor messages
vendormessage.Sentence = Vendor message
vendormessage.New = New vendor message
vendormessage.SentencePlural = Vendor messages

warranty.Lower = warranty
warranty.LowerPlural = warranties
warranty.New = New warranty
warranty.Sentence = Warranty
warranty.SentencePlural = Warranties

workcenter.Lower = work center
workcenter.LowerPlural = work centers
workcenter.New = New work center
workcenter.Sentence = Work center
workcenter.SentencePlural = Work centers

worker.Lower = worker
worker.LowerPlural = workers
worker.New = New worker
worker.Sentence = Worker
worker.SentencePlural = Workers

[restricted]
Title = Your account does not have the correct access
Info = You tried to reach a page that your user account does not have access to. Please contact your account administrator 

[listPage]

Widgets.export = Export


NoResultsFound = No results found

[filter]

Action = Action
All = All
all = all
Any = Any
archive.yes = Show archived
archive.no = Show not archived
archive.both = Show all
AssignedTo = Assigned to

BinTrackedLocation = Bin tracked location
BinTrackedLocations = Bin tracked locations

CameFromOrWentTo = Trace where lot...
Category = Category
Categories = Categories
Class = Class
Customer = Customer
Department = Department

expiredRecalled.all = All
expiredRecalled.notExpiredNotRecalled = Not expired, not recalled
expiredRecalled.expired = Expired
expiredRecalled.recalled = Recalled

FromLocation = From location
Heading = Filters
Item = Item
Location = Location
Locations = Locations
label = Filter
Type = Type
ResultsPerPage = Results per page
SyncObject = Object

Lot = Lot
Status = Status

status.Accepted = Show accepted only
status.Active = Show active only
status.AdjustedOut = Adjusted out
status.Closed = Show closed only
status.Completed = Show completed only
status.Inactive = Show inactive only
status.InStock = In-stock
status.NotVoided = Show not voided only
status.Open = Show open only
status.Pending = Show pending only
status.Rejected = Show rejected only
status.NotVoided = Show not voided only
status.Voided = Show voided only
status.InStock = In stock
status.Used = Used
status.Shipped = Shipped
status.Voided = Show voided only

Tags = Tags
ToLocation = To location
TotalOnly = Total only
Type = Type
Unassigned = Unassigned
Variants = Variants
variant.show = Show variants
Vendor = Vendor

heading.asOf = as of
heading.archive.Both = archived and unarchived
heading.archive.No = unarchived
heading.archive.no = unarchived
heading.archive.Yes = archived
heading.from = from
heading.intro.listPage = showing
heading.intro.report = selecting
heading.to = to

heading.cameFromOrWentTo.CameFrom = where lot came from
heading.cameFromOrWentTo.WentTo = where lot went to

heading.status.Accepted = accepted
heading.status.Active = active
heading.status.Inactive = inactive
heading.status.AdjustedOut = adjusted out
heading.status.all = all
heading.status.Both = open and closed
heading.status.Closed = closed
heading.status.completed = completed
heading.status.Expired = expired
heading.status.InStock = in stock
heading.status.No = open
heading.status.open = open
heading.status.NotExpiredNotRecalled = not expired and not recalled
heading.status.notvoided = unvoided
heading.status.Pending = pending
heading.status.Recalled = recalled
heading.status.Rejected = rejected
heading.status.Shipped = shipped
heading.status.Used = used
heading.status.voided = voided
heading.status.Yes = closed

heading.itemType.Account = account
heading.itemType.Assembly = assembly
heading.itemType.Bill = bill
heading.itemType.Category = category
heading.itemType.Class = class
heading.itemType.CreditMemo = credit memo
heading.itemType.Customer = customer
heading.itemType.CustomerType = customer type
heading.itemType.Department = department
heading.itemType.Estimate = estimate
heading.itemType.Expense = expense
heading.itemType.InventoryItem = regular
heading.itemType.Invoice = invoice
heading.itemType.Item = item
heading.itemType.ItemGroup = item group
heading.itemType.JournalEntry = journal entry
heading.itemType.Labor = labor
heading.itemType.Non-inventoryItem = non-inventory
heading.itemType.Other = other
heading.itemType.Overhead = overhead
heading.itemType.PaymentMethod = payment method
heading.itemType.Purchase = purchase
heading.itemType.PurchaseOrder = purchase order
heading.itemType.SalesReceipt = sales receipt
heading.itemType.SalesTerm = sales term
heading.itemType.Service = service
heading.itemType.Vendor = vendor
heading.itemType.VendorCredit = vendor credit

heading.fromExpiration = from expiration
heading.toExpiration = to expiration
heading.variants.HideVariants = "(excluding variants)"

[columnSelect]

dragDropInstructions = Drag and drop column names to add or remove from the display, or to reorder the columns names currently displayed.
current = Current
available = Available
Heading = Columns

[iconAltText]
search = Search SOS Inventory
create = Add new transaction
toolsAndSettings = Tools and settings
sync = Sync with QuickBooks
print = Print
messages = Notifications
help = Help
user = User

[topNav]
companyMenu.accountInfo = Account info
companyMenu.userAdmin = User administration

syncMenu.syncNow = Sync Now
syncMenu.previewSync = Preview Sync
syncMenu.syncErrors = Sync Errors
syncMenu.accounts = Accounts
syncMenu.bills = Bills
syncMenu.classes = Classes
syncMenu.credits = Credit Memos
syncMenu.departments = Departments
syncMenu.employees = Employees
syncMenu.journals = Journal Entries
syncMenu.purchases = Purchases
syncMenu.taxCodes = Tax Codes

settingsMenu.settings = Configuration
settingsMenu.financialList = Financial lists
settingsMenu.dataTools = Data tools
settingsMenu.import = Import data
settingsMenu.export = Export data
settingsMenu.dataUtilities = Data utilities
settingsMenu.support = Support console

helpMenu.userGuide = User guide
helpMenu.faq = Frequently asked questions
helpMenu.videos = Video tutorials
helpMenu.howTo = How-to guides
helpMenu.customerTraining = Customer training
helpMenu.new = What's new
helpMenu.features = Feature requests
helpMenu.status = System status
helpMenu.mobile = Mobile app
helpMenu.contact = Contact us
helpMenu.about = About

userMenu.userSettings = User settings
userMenu.tasks = Tasks
userMenu.signOut = Sign out

notificationMenu.NoNotifications = You have no notifications
notificationMenu.SeeAll = See all notifications

[leftNav]
Rentals = Rentals
Reports = Reports
QuickLinks = Quick links
AddThisPage = Add This Page

[login]
Title = SOS Inventory
Subtitle = © SOS Inventory Software, LLC
EmailField = Email
PasswordField = Password
RememberMe = Remember Me:
SignIn = Sign in
QBOAltText = Sign in with QuickBooks Online
NeedAccount = I need an account
ForgotPassword = I forgot my password
Support = Need help logging in?
Agreement = By signing in, you agree to the SOS Inventory 
Terms = Terms of Service
Privacy = PrivacyPolicy
TimeoutMessage = You will be logged out due to inactivity in one minute.
Logout = Logout
Stay = Stay
LoggingIntoSOS = We are currently logging you into SOS Inventory...

[chooseCompany]
Link = Change company
SubscribeButton = Subscribe
Header = Choose a company
AutomaticCheck = Automatically go to this company when I log in
HelpText = If you choose this option, you'll still be able to switch companies at any time.

[button]
Add = ADD
AddDocument = ADD DOCUMENT
Apply = APPLY
Cancel = CANCEL
Clear = CLEAR
Close = CLOSE
Delete = DELETE
Done = DONE
Generate = GENERATE
Merge = MERGE
Reset = RESET
Send = SEND
Submit = SUBMIT
UploadCSV = UPLOAD CSV
Test = TEST

[saveAndSend]
Title = Send form via email
To = To
ToHelper = Separate multiple addresses with commas
ToError = Must contain one or more valid email addresses, separated by commas
Subject = Subject
Message = Message
CopyMe = Send copy to me
Template = Template
TemplateError = Must select a template

[search]
Tips = Search tips
Results = Search Results
For = Search for customers, items, vendors, or transactions. 
TipsAndKeywords = Search tips and keywords
Description = Smart search allows you to perform a global search in SOS Inventory for a specific list item or transaction. Enter any text to return all matches for that text, or use a keyword to specify the type of transaction to return.
AvailableKeywordsBelow = The available keywords are listed below:
TipExample = As an example, you could enter "5177" (without quotes) to find anything with 5177 in the name or ID. But if you search for "po 5177" (without quotes) it would look for purchase order ID 5177 only. In this case it would take you directly to the appropriate list page.
QuotesTip = If you are not using an SOS keyword, enclose a phrase in quotes to obtain precise search results. For example, "Flight Jacket" finds anything with that phrase as part of the name or description (depending on the inventory settings you have configured for searches). Without the quotes, SOS looks for every possible match for that phrase.

[serials]
Enter = Enter serial numbers
Upload = Upload serial numbers
EnterUpload = Enter/upload serial numbers
SelectUpload = Select/upload serial numbers
UploadCSV = Upload CSV file
CSVUploadInfo = One or more of the serials you uploaded is not available in inventory at this location, or is already selected.
Available = Available
ClickToRemove = click to remove
MustSelectLocation = Select a location from which to select serialized inventory.
UploadSerialNumbers = Upload serial numbers
ChooseSerialFileAndImport = Choose serial number file and import

[lots]
DeleteRecordsLotHasOnhand = The selected lot has inventory on hand and cannot be deleted.
DeleteRecordsLotUsedOnTransactions = The selected lot has been used on transactions and cannot be deleted.

[history]
customerName = To/from customer
fromLocation = From location
LineItems = Line items
Location = Location
refNumber = "Txn #"
toLocation = To location
Total = Total
txnDate = Date
txnType = Txn type
type = Transaction type
quantity = Quantity
value = Value
vendorName = From/to vendor
NET = NET

[locationSettings]
location = Location
reorderPoint = Reorder point
maxStock = Max stock
defaultBin = Default bin

[payment]
None = None
Bill = Bill
CashPurchase = Cash
Check = Check
CreditCardCharge = Credit Card
endingIn = ending in
EnterAchInfo = Enter ACH info
EnterCardInfo = Enter card info
PaymentMethodDisabledOnEdit = Updating the payment method is disabled on payment transactions where an SOS payment has already been made
SosPayOptionsDisabled = SOS pay options are only available on new payment transactions
LearnHowToProcessPayments = Learn how to process payments on sales receipts.

[address]
Shipping = Shipping address
BillTo = Bill to
Billing = Billing address
Vendor = Vendor address
Customer = Customer
CustomerAddress = Customer address
ShipTo = Ship to

[vendor]
LineItemError.Name = Name field is required.
Error.Email = You must enter a valid email.
Error.Website = Invalid URL.

Address = Vendor address

[transactionHistory]
txnDate = Date/time
type = Transaction type
refNumber = "Transaction #"
lines = Line items
location = Location
total = Total
customerPO = Customer PO

[file]
FileTypeNotSupported = File type of {{fileType}} is not currently supported.
ImageScaledToFit = Image (scaled to fit)
Image = Image

[item]
placeholderSalesDescription = Description on sales forms
placeholderPurchasingDescription = Description on purchasing forms

typeOptionInventoryItem = Inventory item
typeOptionNonInventory = Non-inventory
typeOptionCategory = Category
typeOptionExpense = Expense
typeOptionItemGroup = Kit (item group)
typeOptionAssembly = Assembly
typeOptionService = Service
typeOptionLabor = Labor
typeOptionOverhead = Overhead
typeOptionOther = Other

headingSync = Sync with
headingStartingInventory = Starting inventory
headingUsedOn = Used on
headingSalesTransactionFields = For sales transactions:
headingPurchasingTransactionFields = For purchasing transactions:
headingAccounts = Accounts

switchLabelFixedPricing = Fixed pricing
switchLabelMarkupPricing = Markup pricing

imageChange = Change image
imageAdd = Add image
imageRemove = Remove image

Error.needInitialBin = This location requires a bin to be chosen.
Error.Name = Name field is required.
Error.IncomeAccount = You must specify an income account to sync with QuickBooks.
Error.CogsAccount = You must specify a COGS account to sync with QuickBooks.
Error.ExpenseAccount = You must specify an expense account to sync with QuickBooks.

fieldError.incomeAccount = If you do not have an income account selected, QuickBooks will delete the sales description and sales price when this item synchronizes.
fieldError.cogsAccount = If you do not have a COGS account selected, QuickBooks will delete the purchase description and purchase price when this item synchronizes.
fieldError.expenseAccount = If you do not have an expense account selected, QuickBooks will delete the purchase description and purchase price when this item synchronizes.

[bin]
NoBins = No bins with this item

[worker]
NoWorkers = No workers are associated with this account

[reorder]
Error.Item = You must choose an inventory item.

[serial]
Error.Item = You must specify the item type.
Error.Number = You must specify a serial number.

[itemReceipt]
fieldReceived = Received

labelNumber = "Item receipt reference #"

OtherCostsHeader = Other costs

Error.Date = You must enter a valid receipt date and time.
Error.Vendor = You must select a vendor.
Error.MissingBins = You must select a bin on every line that requires one.

LineItemError.MissingBin = You must select a bin on line {{lineNumber}}.
LineItemError.QuantitySerialsMismatch = Quantity and serial number count don't match on line {{lineNumber}}.
LineItemError.QuantityOverflow = Quantity on line {{lineNumber}} must have 8 or fewer digits left of the decimal point.
LineItemError.UnitpriceOverflow = Unit price on line {{lineNumber}} must have 8 or fewer digits left of the decimal point.
LineItemError.AmountOverflow = Amount on line {{lineNumber}} must have 8 or fewer digits left of the decimal point.

[returnToVendor]

labelNumber = "Return to vendor reference #"
labelAccount = Account

[purchaseOrder]
labelAbbreviated = PO
labelAbbreviatedNumber = "PO #"
labelExpectedDate = Expected date
labelExpectedShip = Expected ship
ShippingAddress = Shipping address
labelNumber = "Purchase order reference #"
blanketPO = Blanket PO
ExpandShippingAddress = Update your shipping address
ExpandVendorAddress = Update your vendor address
DoneEditing = Done editing
Error.Customer = You must select a customer for drop ship purchase orders.
SuggestedQuantity = Suggested quantity

[salesOrder]
labelAbbreviated = SO
labelNumber = "Sales order reference #"

[shipment]
labelNumber = "Shipment reference #"

[pickTicket]
labelNumber = "Pick ticket reference #"

[transfer]
labelNumber = "Transfer reference #"

[adjustment]
labelNumber = "Adjustment reference #"

[salesReceipt]
labelNumber = "Sales receipt reference #"

[workOrder]
labelAbbreviatedNumber = "WO #"
labelNumber = "Work order reference #"

[process]
labelNumber = "Process reference #"

[build]
labelNumber = "Build reference #"

[rental]
labelAbbreviated = Rental
labelNumber = "Rental reference #"

[rentalReturn]
labelNumber = "Rental return reference #"

[rma]
labelAbbreviated = RMA
labelNumber = "RMA reference #"

[return]
labelNumber = "Return reference #"

[payment]
labelNumber = "Payment reference #"

[lineItemErrors]
InvalidSerialQty = Invalid qty for serial entry

[hoverHelp]
alert.EnterAValueToCompare = Enter a value to compare against
alert.Max30Min30 = Enter a number. The maximum is 30 and the minimum is -30.
alert.RepeatAlert = After the initial alert, how often do you want to be notified again (if the condition still exists)?
alert.AlertInterval = How often do you want to check for the condition?
alert.ActiveAlert = Check to make this alert active.

alwaysShippable = Always show on shipments.
AssetAccount = Account to record inventory on hand for this work center.
AutomatedSalesTax = Your QuickBooks account is configured for automated sales tax. When you synchronize, QuickBooks will calculate the tax automatically.
AutomatedSalesTaxSalesOrder = Your QuickBooks account is configured for automated sales tax. When you invoice this sale, QuickBooks will calculate the tax automatically.
autoSerialLots = If checked, serial and lot numbers will be generated automatically, according to your settings.
barcode = Scan or type the barcode that the system will use to recognize this item.
BinTracking = Enable this option if this location is organized into bins. If not enabled at the time the location is created, this setting cannot be changed later. A new location must be created with the desired bin tracking setting, and then inventory must be transferred into the proper bins at the new location. If you need assistance, please email support@sosinventory.com. 
BaseUnit = Select the base unit of measure for this item. This would typically be the smallest unit that you use for this item.
blanketPO = Check the box above to make this a blanket PO (open-ended). The quantity column is ignored on a blanket PO.

comment = This comment will not be shown when this form is printed or emailed.
commissionExempt = Check this box if you don't pay commission for this item.
commissionRate = Percentage commission for sales reps on this item.
commissionAmount = Per unit commission for sales reps for this item.
CreateBill = This will create a bill from the vendor associated with the shipping method.
CreateCreditMemo = Check this box to send a credit memo to QuickBooks on the next sync. This will remove any existing credit memos linked to this return.
createVendorCredit = Check this box to send a vendor credit to QuickBooks on the next sync. This will remove any existing credits linked to this return.
CustomerHoverHelp = Customer who has this serial item, if applicable.
CustomerText = This is the text that will show to the customer on the customer portal.
CustomerTypeName = Enter the name for this customer type. Note that QuickBooks does not allow customer types created in third-party apps to be created in QuickBooks. So you might want to create your customer types in QuickBooks and have them synchronize to SOS Inventory.
CustomFieldList = Enter the possible values for this list, seperated by commas.
CustomListNoOptions = This custom list field has no list values. Add them in the custom field definition.
CustomerOptional = This field is optional. You do not have to specify a customer.
ForceToShipStation = Checking this box will send the transaction to ShipStation the next time ShipStation retrieves data from SOS, even if ShipStation does not request it.
deposit = For your reference only. It will not sync to QuickBooks.

customField.dd/MM/yyyy = Please enter the date in dd/mm/yyyy format, where the day and month can be 1 or 2 digits, and the year must be 4 digits. Use / as the separator between day, month, and year segments. For example, 12/30/2023
customField.M/d/yyyy = Please enter the date in mm/dd/yyyy format, where the day and month can be 1 or 2 digits, and the year must be 4 digits. Use / as the separator between day, month, and year segments. For example, 30/12/2023

EmailTemplate = Select the template that you want to use when emailing this form.
EmailAttachment = Only linked files can be sent as attachments.
itemName = The item name should be whatever you want to use to refer to this item in the system.
leadTime = Depending on your settings, this value might be overridden by automatic calculations.
LocationDefault = Check the box to make this location the default location.
minimumSalesPrice = Transactions attempting to sell the item for less than this price will be rejected.
NonNettable = If this option is selected, items in this location will not count toward the available-for-sale quantity. This can be convenient for work-in-progress or out-of-service units, for example.
OrderStageSortOrder = This is the order of the stages. For example, the first stage would have a 1 here. 
payment = Method you used to pay for the items. If you choose "None", no transaction will be sent to QuickBooks.
PriceTier = Apply will update all lines to the selected price tier.
PrioritySortOrder = This is the order of the priorities. For example, priorty 1 would have a 1 here. 
reorderPoint = Depending on your settings, this value might be overridden by automatic calculations.
SalesTaxRatePercent = Default sales tax rate for this location, specified as a percentage. This setting applies only when not using the Sales Tax Center in QuickBooks Online. 
SequenceNumber = Sequence number to order the bins. For example, 1, 2, 3, etc. The minimum value is 0 and the maximum is 9999. The default is 0. Typically this would be the order of the bins in the warehouse.
SeparateEmailByComma = Separate multiple email addresses with a comma.
Share = Check this box to allow other users to view this document.
sku = Scan or type the SKU that the system will use to recognize this item. This field syncs with the SKU field in QuickBooks Online.
StatusMessage = This message shows when the customer views the order status.
UniqueName = Use a unique name so that you can remember what this {{objectType}} means.

PercentCommission = Calculates commission as a percentage of sales on transactions assigned to this sales rep.
CommissionAmount = Calculates commission as a flat rate per sale on transactions assigned to this sales rep. 
preferredVendor = If you need to set up more than one vendor for this item, use the vendor-item catalog.
RestrictCustomers = Check this box to restrict this sales rep to information for their customers only.
StorePaymentInformation = Check this box to store this payment information securely for later use. The payment information is not stored by SOS. It is stored in a highly-secure, PCI II compliant data center.
UpdateDefaultCosts = If checked, the costs on this receipt will become the new default costs for those items.
UpdateInputs = This action requires that the output selection is an assembly.
variantNew = Check this box if you will set up variations of this item later (color, size, version, etc).
vendorCreditAccount = Select the account to which this transaction will post. A credit will post to the asset account and a debit will post to the selected account.
willSync = Uncheck this box if you don't want this item to sync with QuickBooks.
User = Select the SOS user account for this sales rep. This is optional, and you can add it at any time.
VariantItemName = Leave this column blank if adding a new variant item. Note: The name of any existing item that is linked will be changed to match the variant name.

Term.DayOfMonthDue = Date of the month by which payment must be received. This setting is used only when a Due days value is not specified. 
Term.DueDays = Number of days from the delivery of goods or services until the payment is due. If a Due days value is specified, only Discount days and Discount percent can also be specified. 
Term.DueNextMonthDays = When a transaction is issued within the specified number of days prior to the Day of month due, the payment will be due the following month. This setting is used only if a Due days value is not specified. 
Term.DiscountDays = Used only when a Due days value is specified. Discount days indicates the number of days for which the discount is applicable, if the payment is made within Due days. If Discount days is used, you must also specify the Discount percent. 
Term.DiscountDayOfMonth = Discount applies if paid before that day of month. This value is used in conjunction with Date discount percent. Discount day of month can be used only if a Due days value is not specified. 
Term.DiscountPercent = Used only when a Due Days value is specified. The percentage of discount to be applied against the sales list price, if paid within the days specified by Discount Days. If Discount percent is used, you must also specify the Discount days. 
Term.DateDiscountPercent = Percentage of discount that is available against a price, if paid before Discount day of month. This value is used in conjunction with Discount day of month. Date discount percent can be used only if a Due days value is not specified.

valuationMethod.LIFO = If you want to designate LIFO as your valuation method, please email support@sosinventory.com.

shipMethod.ShipStationCarrier = Optional, but helpful if integrating with ShipStation. This setting allows SOS to match the carrier on ShipStation notifications.
shipMethod.ShipStationService = Optional, but helpful if integrating with ShipStation. This setting allows SOS to match the service on ShipStation notifications.
shipMethod.CarrierCode = This setting allows SOS to match the carrier on incoming EDI transactions.
shipMethod.Vendor = Vendor associated with this shipping method. This is used when SOS generates bills for shipping costs.

vendorItem.LeadTime = Expected lead time for this item from this vendor.
vendorItem.Price = Price you pay this vendor for this item.
vendorItem.VendorPartNumber = The part number that this vendor uses when referring to the item.

WorkCenter.SortOrder = Sequence number to order the work centers. For example, 1, 2, 3, etc. The minimum value is 0 and the maximum is 999. The default is 0.


[Dates]
30DaysAgo = 30 days ago
365DaysAgo = 365 days ago
60DaysAgo = 60 days ago
90DaysAgo = 90 days ago

AllDates = All dates
Dates = Dates

endOf.LastMonth = End of last month
endOf.LastQuarter = End of last quarter
endOf.LastWeek = End of last week
endOf.LastYear = End of last year
endOf.NextMonth = End of next month
endOf.NextQuarter = End of next quarter
endOf.NextWeek = End of next week
endOf.NextYear = End of next year
endOf.ThisMonth = End of this month
endOf.ThisQuarter = End of this quarter
endOf.ThisWeek = End of this week
endOf.ThisYear = End of this year

startOf.LastMonth = Start of last month
startOf.LastQuarter = Start of last quarter
startOf.LastWeek = Start of last week
startOf.LastYear = Start of last year
startOf.NextMonth = Start of next month
startOf.NextQuarter = Start of next quarter
startOf.NextWeek = Start of next week
startOf.NextYear = Start of next year
startOf.ThisMonth = Start of this month
startOf.ThisQuarter = Start of this quarter
startOf.ThisWeek = Start of this week
startOf.ThisYear = Start of this year

From = From

LastMonth = Last month
LastMonthtoDate = Last month-to-date
LastQuarter = Last quarter
LastQuarterToDate = Last quarter-to-date
LastWeek = Last week
LastWeekToDate = Last week-to-date
LastYear = Last year
LastYearToDate = Last year-to-date

Next4Weeks = Next 4 weeks
NextMonth = Next month
NextQuarter = Next quarter
NextWeek = Next week
NextWeek = End of next week
NextYear = Next year

To = To
Today = Today

ThisMonth = This month
ThisMonthToDate = This month-to-date
ThisQuarter = This quarter
ThisQuarterToDate = This quarter-to-date
ThisWeek = This week
ThisWeekToDate = This week-to-date
ThisYear = This year
ThisYearToDate = This year-to-date

Yesterday = Yesterday

Since30DaysAgo = Since 30 days ago
Since365DaysAgo = Since 365 days ago
Since60DaysAgo = Since 60 days ago
Since90DaysAgo = Since 90 days ago

[alert]
ClearCache = Cache cleared succesfully
CopiedToClipboard = Copied to Clipboard
DirtyPageCloseConfirm = Are you sure? Changes you made may not be saved
ErrorDuplicateSerial = You already have an item of type serial with serial number {{name}}
ErrorGeneral = An error occurred
ErrorGeneratingPos = Error generating purchase orders
ErrorDeletedComment = Error deleting comment
ErrorNotAddedToReorderList = Item(s) could not be added to the reorder list
ErrorMerchantTrack = Error retrieving merchant track information
ErrorProduceAll = Error producing all

RefundPaymentSuccess = Successfully refunded payment
SaveDocumentSuccess = Successfully saved document
SaveUnitsOfMeasureSuccess= Successfully save units of measures
ErrorNotAddedToReorderList = Item(s) could not be added to the reorder list
ErrorGeneral = An error occurred
ErrorDuplicateSerial = You already have an item of type serial with serial number {{name}}

SaveAddressesSuccess = Successfully saved addresses
SaveBillOfMaterialsSuccess = Successfully saved bill of materials
SaveBoxesSuccess = Successfully saved boxes
SaveDocumentSuccess = Successfully saved document
SaveLocationSettingsSuccess = Successfully saved location settings
SaveUnitsOfMeasureSuccess= Successfully save units of measures
SaveVariantSuccess = Successfully saved variants
sendEdi = Message sent successfully

SuccessAddedToReorderList = Item(s) successfully added to the reorder list
SuccessApproved = Successfully approved
SuccessActivated = Successfully activated
SuccessArchived = Successfully archived
SuccessClosed = Successfully closed
SuccessCopyTemplateToAccount = Successfully copied template to your account
SuccessComplete = Succesfully completed
SuccessConfirmed = Successfully confirmed
SuccessDeactivated = Successfully deactivated
SuccessDeleted = Successfully deleted
SuccessDeletedComment  = Successfully deleted comment
SuccessGeneratePos = Successfully generated purchase orders
SuccessGeneratePosFromReorders = Your purchase orders are being generated; you will see them in your account soon
SuccessInvoicesGenerated = Invoices successfully generated
SuccessMerged = Successfully merged
SuccessOpened = Successfully opened
SuccessRefund = Succesffully refunded
SuccessSave = successfully saved
SuccessSync = Successfully added to sync
SuccessSent = Email successfully sent
SuccessUnarchived = Successfully unarchived
SuccessUnconfirmed = Successfully unconfirmed
SuccessRemovePaymentInfo = Successfully removed payment info
SuccessProduceAll = We are processing all the assembly item lines in the background. You will receive a notification when this completes.
SuccessTestAlert = The alert ran successfully 
SuccessUnlocked = Successfully unlocked
SuccessVoid = Succesfully voided UPS shipment

SuccessRestoreDefaultTemplates = Default templates successfully restored
SuccessInvoicesGenerated = Invoices successfully generated
SuccessMerged = Successfully merged
SuccessOpened = Successfully opened
SuccessRestore = Successfully restored
SuccessSave = successfully saved
SuccessSent = Email successfully sent
SuccessSync = Successfully added to sync
SuccessUnarchived = Successfully unarchived
SuccessUnconfirmed = Successfully unconfirmed

sendEdi = Message sent successfully
updateEdi = All shipments updated
VariantTypeMismatch = All variant item types must match master item type
VariantOfAnotherItem = A linked item is already the variant of another item

RetrieveItemFailed = Temporarily unable to retrieve information about the inventory item you selected. Vendor part number and unit price have not been updated.

AvailableForSale = Available for sale
BillDue = Bill due
CustomField = Custom field
InvoiceDue = Invoice past due
LotExpiration = Lot expiration
LowInventory = Low inventory
SalesOrderDue = Sales order due
SalesReceiptDue = Sales receipt due
TaskDue = Task due
SendAnEmail = Send an email
NotifyAUser = Notify a user
SendAText = Send a text
Always = Always
Daily = Daily
Never = Never
Hourly = Hourly
Weekly = Weekly
InStock = In stock
DefaultIsAllCategories = Default is all categories
DefaultIsAllLocations = Default is all locations

Available = Available
OnHand = On hand

[tooltip]
AddToReorderList = Add to reorder list. Select at least one row in order to add the corresponding item(s) to the reorder list
AddToSync = Send the transaction(s) for this {{objectType}} on the next sync with QuickBooks
ApplyNewDocument = You must first select a document.
BillWithParent = This setting applies only to sub-customers. For others, it is ignored.
ClickToExpand = Click to expand
ClickToGoToList = Click to go to list
ClickToRetrieveAvailable = Click here to retrieve available inventory quantity as of the transaction date
Collapse = Collapse
CopyThisItem = Copy this item into a new one
CopyToClipboard = Copy to clipboard
CreateTask = Create task
CurrencyDisabled = This {{objectType}} is in QuickBooks, and QuickBooks does not allow the currency of an existing {{objectType}} to be changed.
Delete = Delete this item receipt
ExpandToEdit = Expand to edit
favorite = favorite
GeneratePurchaseOrders = Generate purchase orders.  Select at least one row in order to generate the corresponding purchase order(s)
History = Show history of this transaction
NoSelectableReportColumns = This report does not have selectable columns
NotSyncedQBO = This {{objectType}} has not synced with QuickBooks.
Pdf = Print or download item receipt
ProduceAll = This will produce any assemblies that are not lot or serial tracked on inputs or outputs.
ReturnToVendor = Create return to vendor
PODisabled = This PO has been closed. You can not edit it unless you reopen it first.
SaveDisabled = Save will be available when the update completes
SelectBinFirst = Select a bin first.
SelectCustomerFirst = You must first select a customer
ShowOnForms = If this box is checked, the {{objectType}} will show in the dropdown list on purchase forms. If the box is unchecked, the {{objectType}} will not show. This allows you to refine your vendor list to remove extraneous {{objectType}}s that are not used in normal operations.
SyncComplete = Sync complete
SyncError =  Sync error. Please refer to the sync errors menu option (by clicking on this icon) for more information.
Taxable = At the present time, QuickBooks does not allow 3rd-party apps to update the tax-exempt setting in their automated tax tool. You will need to update it manually there.
ToggleDiscountTaxable = Click to take discount after tax.
ToggleDiscountNotTaxable = Click to take discount before tax.
UseAsTemplate = Copy this {{objectType}} into a new one
unfavorite = unfavorite

[customer]
CreditHold = Credit hold
FoundUsVia = Found us via
BusinessLicense = Business license
ResaleNumber = Resale number
BillingAddress = Billing Address
ShippingAddress = Shipping Address
NewCustomer = New Customer
ContractorNumber = Contractor Number
PortalPassword = Portal Password
CustomerType = Customer Type
ParentCustomer = Parent Customer
BillWithParent = Bill with parent
Taxable = Taxable
LineItemError.Name = Name field is required.
SameAsBilling = Same as billing
Error.Email = You must enter a valid email.
GenerateNewPassword = Generate new password
labelCustomerNotes = Customer notes
AddNewCustomer = Add new customer
AddMoreCustomer = Add more customer details
CreditHold = This customer is on a credit hold.

[estimate]
labelNumber = "Estimate reference #"
Error.Customer = You must select a customer.

[invoice]
labelNumber = "Invoice reference #"

[frmLabel]
Abbreviation = Abbreviation
AcceptedBy = Accepted by
AcceptedDate = Accepted date
Account = Account
AccountNumber = "Account #"
AddOrder = Add order
AddRental = Add rental
AddRMA = Add RMA
AdjustmentNumber = "Adjustment #"
AlertMethod = Alert method
AlertOn = Alert on
AlertType = Alert type
AlwaysShippable = Always shippable
AssetAccount = Asset account
AssignedToUser = Assigned to
AssignedUponSave = Assigned upon save
AsOf = As of
Attach = Attach
AutoSerialLots = Auto serial/lot numbers
Barcode = Barcode
BaseUnit = Base unit
BaseSalesPrice = Sales price
Bin = Bin
BinNumber = Bin number
BinTracking = Bin tracking
BlanketPO = Blanket PO

Category = Category
CarrierCode = Carrier code
Calculation = Calculation
Channel = Channel
CogsAccount = COGS account
Comment = Comment
CommissionAmount = Commission amount
CommissionExempt = Exempt from commission
CommissionRate = Commission rate
CheckNumber = "Check #"
Completed = Completed
CompleteBy = Complete by
Comment = Comment
CommissionAmount = Per sale commission
ComparisonValue = Comparison value
CreateBill = Create bill
CreateVendorCredit = Create vendor credit
CreateCreditMemo = Create credit memo
ContractManufacturing = Contract manufacturing
Currency = Currency
CustomerMessage = Customer message
Customer = Customer
CustomerText = Customer text
CustomerPartNumber = Customer part number
CustomerPO = Customer PO

Date = Date
DateTime = Date/time
DataType = Data type
Date = Date
DateDiscountPercent = Date discount percent
DiscountDayOfMonth = Discount day of month
DayOfNextMonth = Day of Next Month
DayNextMonthDays = Day next Month days
DayOfMonthDue = Day of month due
DaysBeforeDueDate = Days before due date
DaysPriorToExpiration = Days prior to expiration
DiscountPercent = Discount percent
DiscountDays = Discount days
DueDays = Due days
DueNextMonthDays = Due next month days
Duration = Duration
Default = Default
DefaultBin = Default bin
DefaultClass = Default class
DeletedTheLinkedNotification = Delete the linked notification
Department = Department
DepositAmount = Deposit amount
DepositPercent = Deposit %
DepositAccount = Deposit account
Description = Description
DimensionUnit = Dimension unit
DocumentAdd = Add document
DocumentChange = Change document
DropShip = Drop ship
DueDate = Due date

Email = Email
Enabled = Enabled
EstimateNumber = "Estimate #"
ExchangeRate = Exchange rate
ExpenseAccount = Expense account
ExpectedDate = Expected date
ExpirationDate = Expiration date
ExpectedShip = Expected ship

Field = Field
File = File
FirstName = First name
ForceToShipStation = Force to ShipStation
FormType = Form type
FromLocation = From location
FromBin = From bin

HasVariants = Has variants

IncomeAccount = Income account
Interval = Interval
InvoiceNumber = "Invoice #"
Item = Item
ItemNameContains = Item name contains

Job = Job
JobAddress = Job address
JobNumber = Job number

KeepOnFile = Keep on file

LastName = Last name
LeadTime = Lead time
ListValues = List values
Location = Location
Lot = Lot
LotNumber = Lot number
LotTracking = Track lot numbers

MaxStock = Max stock
MarkupPercent = Markup %
Message = Message
MinimumPrice = Minimum price
Multiplier = Multiplier
MobileCarrier = Mobile carrier

Name = Name
NonNettable = Non-nettable
Notes = Notes
Number = Number

OrderNumber = "Order #"
OrderStage = Order stage

Payment = Payment
PaymentNumber = "Payment #"
PaymentMethod = Payment method
PercentCommission = "Commission %"
PickBy = Pick by
PlaceOfSupply = Label of supply
PoNumber = "PO #"
PickTicketNumber = "Pick ticket #"
PreferredVendor = Preferred vendor
Price = Price
PriceTier = Price tier
Priority = Priority
ProcessNumber = "Process #"
ProductionForms = production forms
PurchaseCost = Purchase cost
PurchaseOrderDate = Purchase order date
PurchaseTaxCode = Purchase tax code
PurchasingForms = purchasing forms

Quantity = Quantity
Question = Question

Recalled = Recalled
ReceiptNumber = "Receipt #"
RentalNumber = "Rental #"
RentalReturnNumber = "Rental return #"
ReturnNumber = "Return #"
RestrictCustomers = Restrict customers
ReorderPoint = Reorder point
RepeatAlert = Repeat alert
RMANumber = "RMA #"
RtvNumber = "RTV #"

SaleNumber = "Sale #"
SalesForms = sales forms
SalesRep = Sales rep
SalesTaxCode = Sales tax code
SalesTaxRatePercent = "Sales tax rate %"
SequenceNumber = "Sequence #"
SerialNumber = Serial number
SerialNumberAbbreviated = S/N
SerialTracking = Track serial numbers
ShipmentNumber = "Shipment #"
Signature = Signature
Sku = SKU
SortOrder = Sort order
Status = Status
StatusMessage = Status message
StatusUrl = Status URL
ShipBy = Ship By
ShipDate = Ship date
Shipments = Shipments
ShipMethod = Ship method
ShippedVia = Shipped via
ShippingCost = Shipping cost
ShippingDiscountTaxCode = Shipping/discount tax code
ShippingDiscountTax = 'Shipping/discount tax %'
ShipStationCarrier = ShipStation carrier
ShipStationService = ShipStation service
ShowOn = Show on
Subject = Subject
SyncWithQuickBooks = Sync with QuickBooks
StartDate = Start date
Status = Status

Tags = Tags
Taxable = Taxable
TaxCode = Tax code
TaxPercent = 'Tax %'
Template = Template
Terms = Terms
TrackingNumber = Tracking number
ToBin = To bin
ToLocation = To location
TrackingNumber = Tracking number
TransactionLocation = Transaction location
TransferNumber = "Transfer #"
Type = Type

Unitprice = Unit price
Units = Units
UpdateDefaultCosts = Update default costs
UpdateInventoryInShopify = Update inventory in Shopify
UpdateInventoryInBigCommerce = Update inventory in BigCommerce
User = User

Value = Value
Vendor = Vendor
VendorBillDate = Vendor bill date
VendorNotes = Vendor notes
VendorMessage = Vendor message
VendorPartNumber = Vendor part number
Volume = Volume
VolumeUnit = Volume unit

Warranty = Warranty
WarrantyEnd = Warranty end
WarrantyStart = Warranty start
Weight = Weight
WeightUnit = Weight unit
WorkCenter = Work center
WorkOrderNumber = "Work order #"

[columns]
colon.Worker = Worker: 
colon.Bin = Bin:
colon.FromBin = From bin:
colon.ToBin = To bin:
colon.Expiration = Expiration:
colon.Serials = S/N:
colon.Lot = Lot:
Total = Total

Abbreviation = Abbreviation
AccountNumber = Account number
AccountType = Account type
Action = Action
Active = Active
Account = Account
Address = Address
AdjustCostBasisBy = Adjust cost basis by
AdjustQuantityBy = Adjust qty by
AdjustmentAccount = Adj Acct
AlertMethod = Alert method
Admin = Admin
AltPhone = Alt phone
Amount = Amount
AppliedTo = Applied to
AppliedToWO = Applied to WO
Approved = Approved
ApplyToRMA = Apply to RMA
Assembly = Assembly
AssignedTo = Assigned to
AssetAccount = Asset account
AuthCode = Auth code
Available = Available

Backordered = Backordered
Barcode = Barcode
BaseUOM = Base UOM
Bill = Bill
BillDate = Bill date
Bin = Bin
BinNumber = Bin number
BinTracking = Bin tracking
BillWithParent = Bill with parent
Balance = Balance
BillingAddress = Billing address
BlanketPO = Blanket PO
BusinessLicense = Business license
Billing = Billing

CarrierCode = Carrier code
CreditAmount = Credit amount
CanShip = Can ship
Category = Category
Class = Class
CostBasis = Cost basis
Comment = Comment
CommissionRate = Commission rate
Company = Company
Contact = Contact
Contents = Contents
Cost = Cost
CostEach = Cost each
Currency = Currency
CardOnFile = Card on file
CustomerType = Customer type
CustomerAddress = Customer address
Currency = Currency
CogsAccount = Cogs account
Confirmed = Confirmed
Channel = Channel
CostBasis = Cost basis
AvgValue = Avg value
CustomerPO = Customer PO
ContractManufacturing = Contract Manufacturing
CustomerNotes = Customer notes
CustomerMessage = Customer message
ContractorNumber = Contractor number
CreditHold = Credit hold
ComissionExempt = Commision exempt
Comment = Comment
Credits = Credits
CustomerPartNumber = Customer part number
CanShip = Can Ship
Customer = Customer

DataType = Data type
Debits = Debits
DefaultBin = Default bin
DefaultUOM = Default UOM
Date = Date
Default = Default
Description = Description
Documents = Documents
DueDate = Due date
Discount = "Discount %"
Due Date = Due date
DepositAccount = Deposit account
DefaultTax = Default Tax
DateTime = Date/Time
Delete = Delete
DropShip = Drop ship
Department = Department
Deposit = Deposit
DisplayName = Display name

Email = Email
Entity = Entity
ETA = ETA
ETS = ETS
Enabled = Enabled
Event = Event
ExpDate = Exp date
ExchangeRate = Exchange rate
ExpectedYield = Expected yield
ExpenseAccount = Expense account
Expiration = Expiration

Fax = Fax
FirstName = First name
For = For
FromLocation = Location
ForOrder = For order
FoundUsVia = Found us via
FreightClass = Freight class
From = From
FullName = Full name
FullName = Full name
FromLocation = From location
FromJob = From job

GoTo = Go to

Height = Height
HireDate = Hire date
Image = Image
Instructions = Instructions
Item = Item
Income = Income 
InStock = In stock
ItemToMake = Item to make
ItemDescription = Item description
InvoiceDate = Invoice Date
Invoices = Invoices
Invoiced = Invoiced
IncomeAccount = Income account
Interval = Interval

Job = Job
Line = Line
FromJob = From job
Last4 = Last 4
LastLogin = Last login
LastName = Last name
LastUpdatedBy = Last updated by
Line = Line
LinkedItem = Linked item
ListPrice = List price
Location = Location
Locked = Locked
LotNumber = Lot number
LotTracked = Lot tracked
LeadTime = Lead time
Length = Length

MaxStock = Max stock
Margin = "Markup %"
Message = Message
Method = Method
Memo = Memo
MemoDate = Memo date
Minutes = Minutes
Mobile = Mobile
Monitor = Monitor
MinimumSalesPrice = Minimum sales price
NewQuantity = New quantity
Name = Name
Number = Number
Net = Net
NetTotal= Net total
NonNettable = Non-nettable
Notes = Notes
Number = Number

Object = Object
ObjectType = Type
ObjectAndIdentifier = Object
OldQuantity = Old quantity
OnHand = On hand
OnShortOrder = On SO
OnSO = On SO
OnSR = On SR
OnPO = On PO
OnWO = On WO
OnRMA = On RMA
OnRental = On rental
OpenAmt = Open Amt
OpenAmount = Open Amt
Ordered = Ordered
OriginalAmount = Original amount

Parent = Parent
PartNumber = "Part #"
Payment = Payment
Payments = Payments
PaymentMethod = Payment method
PercentCommision = "Commission %"
PerUnitCommission = Per unit commission
Phone = Phone
PhoneNumber = "Phone #"
PickBy = Pick by
Picked = Picked
Priority = Priority
Price = Price
PickQuantity = Pick qty
PickTickets = Pick tickets
Placeholder = Placeholder
PmtRef = Pmt ref
PmtMethod = Pmt method
PriceTier = Price tier
ProduceAll = Produce all
Produced = Produced
ProcessTemplate = Process template
PurchaseDescription = Purch description
PurchaseDate = Purchase date
PurchasePrice = Purch price
PurchaseCost = Purchase cost

QuickBooksMessage = QuickBooks message
Quantity = Quantity
QuantityUsed = Qty used
QuantityToBuild = Qty to build
Qty = Qty
QtyDiff = Qty diff

Rate = Rate
RateName = Rate name
RatePercent Rate percent
Rates = Rates
Ratio = Ratio
Reason = Reason
ReferenceNumber = "Ref #" 
Refund = Refund
Request = Request
RestockValue = Restock value
Restore = Restore
ReorderPoint = Reorder pt
Received = Received
Repeat = Repeat
Returns = Returns
ResaleNumber = Resale number
Reset = Reset
Returned = Returned
Recalled = Recalled

SalesPrice = Sales price
SalesRep = Sales rep
Sequence = Sequence
SerialTracked = Serial tracked
ShipBy = Ship by
Shipments = Shipments
ShipDate = Ship date
ShipMethod = Ship method
ShippingAddress = Shipping address
Shipped = Shipped
Shipping = Shipping
ShipQuantity = Ship qty
ShipTo = Ship to
Signature = Signature
SortOrder = Sort order
SosPmt = SOS pmt
Status = Status
Stage = Stage
StatusMessage = Status Message
StartDate = Start date
ShippingCost = Shipping cost
SyncWithQuickBooks = Sync with QuickBooks
ShowOnSales = Show on sales
ShowOnPurchasing = Show on purchasing
ShowOnProduction = Show on production
SerialNumber = Serial number
Shipments = Shipments
Sku = SKU
Sync = Sync
SyncError = Sync error
System = "Sys #"
ShowOn = Show on
Shipping = Ship to
TotalAmount = Total Amount

Tags = Tags
Tax = Tax
Taxable = Taxable
TaxCode = Tax code
Terms = Terms
TimeCreatedEdited = Time created/edited
To = To 
ToJob = To job
ToLocation = To location
TotalAmt = Total amt
TotalCost = Total cost
TotalCostBasis = Total cost basis
TotalInBuild = Total in build
TotalQty = Total qty
Tracking = Tracking
TrackingNumber = "Tracking #"
Transaction = Transaction
Transfer = Transfer
TrackingNumber = Tracking number
TrackingNumber = Tracking number
Trigger = Trigger
TxnDate = Txn date
TxnId = Txn Id
TxnCostPerUnit = Txn cost/unit
TxnQty = Txn qty
Type = Type

Unit = Unit
UOM = UOM
UnitPrice = Unit price
Url = URL
UsedPer = Used per
UpdateShopify = Update Shopify
UpdateBigCommerce = Update Big Commerce
User = User

ValueDiff = Value diff
VariantName = Variant name
Variance = Variance
Vendor = Vendor
VendorMessage = Vendor message
VendorNotes = Vendor notes
VendorPartNumber = Vendor part number
Void = Void
Voided = Voided
Volume = Vol

Warranty = Warranty
WarrantyStart = Warranty start
WarrantyEnd = Warranty end
Waste = Waste
Weight = Weight
Width = Width
Workcenter = Work center
WorkOrder = Work order
WorkCenter = Work center
Yield = Yield

[quickView]
SUBTOTAL = SUBTOTAL
DISCOUNT = DISCOUNT
SHIPPING = SHIPPING
TAX = TAX
TOTAL = TOTAL
PAYMENTS = PAYMENTS
BALANCEDUE = BALANCE DUE
ShowItemNames = Show item names
ShowBackorderedSwitch = Show backordered quantity

[entityActions]
Activate = Activate
AddToReorderList = Add to reorder list
AddToSync = Add to sync
Addresses = Addresses
Approve = Approve
BatchActionDisabled = Select at least one {{objectType}}
BillOfMaterials = Bill of materials
Boxes = Boxes

ChargeCardOnFile = Charge card on file
ClearCache = Clear cache
CreateReturnToVendor = Create return to vendor
CreateTransfer = Create transfer
Close = Close
Confirm = Mark as confirmed
CreateDropShip = Create drop-ship purchase order
CreateInvoice = Create invoice
CreateReturnLabel = Create return label
CreateReturnToVendor = Create return to vendor
CreatePurchaseOrder = Create purchase order
CreateSalesOrder = Create sales order
CreateSalesReceipt = Create sales receipt
CreateSubCustomer = Create sub-customer
CreateTransfer = Create transfer
CopyThisItem = Copy this item

Deactivate = Deactivate
Disassemble = Disassemble
EdiImport = Import via EDI
Get = Get (copy to account)
Invoices = Generate invoices
Job = Create job
JobDashboard = Job dashboard
LocationSettings = Location settings
MarkAsComplete = Mark as complete
Open = Open
PickTicket = Create pick ticket
PrintLabel = Print label
ProfitLoss = View profit/loss
PurchaseOrders = Generate purchase orders
QuickView = Quick view

Receive = Receive
RemovePaymentInfo = Remove payment info
Refund = Refund
ReceivePayment = Receive payment
Rental = Create rental
RentalReturn = Create rental return
RentalHistory = Rental history
Restore = Restore
Return = Create return
RMA = Create RMA
SendEdi = Send via EDI
SendEdi = Send via EDI
Shipment = Create shipment
ShipUps = Ship via UPS
ShowCustomerHistory = Show customer history
ShowHistory = Show transaction history
ShowVendorHistory = Show vendor history
TestNow = Test now

Unconfirm = Mark as unconfirmed
Unlock = Unlock
UnitsOfMeasure = Units of measure
UpdateEdi = Update via EDI
Unconfirm = Mark as unconfirmed
UseAsTemplate = Use as template
Variants = Variants
ViewInQuickBooks = View in QuickBooks
WorkOrder = Create work order

[saveActions]
More = More
SaveAndClose = Save and close
SaveAndGoTo = Save and go to
SaveAndKeepEditing = Save and keep editing
SaveAndNew = Save and new
SaveAndPDF = Save and PDF
SaveAndSend = Save and send
SaveAndShip = Save and ship

[auditAction]
Accepted = Accepted
Added = Added
Edited = Edited
Deleted = Deleted
Activated = Activated
Restored = Restored
Logged In = Logged In
Synchronized with QuickBooks = Synchronized with QuickBooks
Emailed = Emailed
Received = Received
Shipped = Shipped
Built = Built
Used To Build = Used to build
Returned = Returned
ReturnedToVendor = Returned to vendor

[mobile]
ClickToInstall =  Click the image below to install SOS Inventory on a mobile (iOS) device.
OrSearchAppStore = Or search in the Apple app store for SOS Inventory.
MobileApp = Mobile app

[imgAlt]
AppStoreBadge = App store badge

[uom]
EnterAllUnits = Enter all the possible units of measure for this item besides the base unit.
ConversionRatio = The conversion ratio is the number of base units in the specified unit. For example, if you sell 12 widgets in a case, the ratio for the case would be 12.

[auditObject]
adjustment = inventory adjustment
buildassembly = build
build = build
estimate = estimate
invoice = invoice
itemreceipt = item receipt
lot = lot
payment = payment
pickticket = pick ticket
purchaseorder = purchase order
process = process transaction
rental = rental
rentalreturn = rental return
return = return
returntovendor = return to vendor
rma = RMA
salesorder = sales order
salesreceipt = sales receipt
shipment = shipment
transfer = transfer
workorder = work order

[serialLotEvent]
adjustment = Adjusted
build.in = Used in build
build.out = Built
itemreceipt = Received
pickticket = Picked
process.in = Used in process
process.out = Output of process
rental = Rented
rentalreturn = Returned from rental
return = Returned from customer
returntovendor = Returned to vendor
salesorder = Sold
shipment = Shipped
transfer = Transferred

[itemEvent]
adjustment = Adjustment
build.in = Used to build
build.out = Build
itemreceipt = Receipt
process.in = Used to process
process.out = Process
rental = Rental
rentalreturn = Return from rental
return = Return
returntovendor = Return to vendor
shipment = Shipment
transfer = Transfer

[document]
AddDocument = Add document
AddNewDocument = Add new document
AssociatedDocuments = Documents associated with this {{objectType}}:
FileSelector = File selector
Description = Description
Documents = Documents
DragDropDocument = Drop file here, or use the "file selector" button.
DropDocumentHere = Drop the document here ...
FileName = File name
NoAssociatedDocuments = There are no documents attached to this {{objectType}}
Share = Share
SearchPlaceholder = Search for/add a document
DuplicateFileName = Duplicate file name. Rename the file and retry.
SaveFirst = You can add and manage attached documents after saving this {{objectType}}.

[beta]
Maintenance.1 = V9 beta is currently down for maintenance. Click
Maintenance.2 = here
Maintenance.3 = to use our V8 product.

NotFinished = This page has not yet been finished for the version 9 beta.
GoToProduction = If you need this functionality now, please go back to our current production version at 

[colon]
Comment = Comment: 
Document = Document:
CurrentQuantity = Current quantity:
CurrentQuantity = Current quantity: 
Date = Date:
Document = Document:
Location = Location:
PulledBy = Pulled by:
ReceivedBy = Received by:
Terms = Terms:
Total = Total:
Vendor = Vendor:

[carat]
noVendor = <no vendor>
noCategory = <no category>

[auth]
Invalid = Login failed. Please verify that your email address and password are correct.
Locked = For your protection, this user account has been locked. Please contact your account administrator or support@sosinventory.com for assistance.
NonUser = You are not currently a user with any companies for SOS Inventory. Please contact your account administrator or support@sosinventory.com for assistance.
IntuitError = An error occurred when trying to log in via Intuit. Please try again or contact support at support@sosinventory.com
IntuitEmailNotVerified = Your email is not yet verified. Please login to your Intuit account at https://accounts.intuit.com/app/account-manager/security

[error]
CouldNotRetrieveItemLocationSettings = There was a problem retrieving the location settings.
CouldNotSaveUom = There was a problem saving the UOM selections.
CouldNotSaveAddresses = There was a problem saving the address selections.
global.1 = SOS Inventory has encountered an unexpected condition. If you retry and still receive this error and are not able to determine its cause, please email
global.2 = with details on the error received and the task you were attempting to accomplish.
global.3 = Include the following error message:
InternalError = SOS Inventory has encountered an unexpected condition. If you retry and still receive this error and are not able to determine its cause, please email support@sosinventory.com with details on the error received and the task you were attempting to accomplish. Include the following error message: {{errorMessage}}
PositiveSerialAdjustment = You cannot add serial inventory using this form. Use the serial items list or the import tools to add serial inventory.
RecordLoadError = There has been an error loading the {{objectType}} record.
SaveLocationSettingsFailure = Location settings could not be saved
SaveVariantsFailure = Variants could not be saved
SubCustomerError = There has been an error creating the sub-customer for this customer.
GoToTheSOSInventoryHomePage = Go to the SOS Inventory home page
CustomFieldDateError.dd/MM/yyyy = Please review the {{name}} field, as it requires the date in dd/mm/yyyy format
CustomFieldDateError.M/d/yyyy = Please review the {{name}} field, as it requires the date in mm/dd/yyyy format

[isValid]
Abbreviation = You must enter a valid abbreviation.
address.NameFieldRequired = The name field is required on line {{lineNumber}}
AlertMethod = You must select a valid alert method.
AlertMethodEmail = You must enter a valid email address.
AlertMethodMobileNumber = You must enter a valid phone number.
AlertMethodMobileCarrier = You must select a valid mobile carrier.
CustomFieldType = You must select a valid alert on value.
CustomFieldField = You must select a valid field.
Customer = You must select a customer.
Date = You must enter a valid date and time.
Duration = You must enter a valid duration.
EnterCreditInfoThroughSOSPay = Please enter Credit payment info through SOS Pay.
EnterACHInfoThroughSOSPay = Please enter ACH payment info through SOS Pay.
FormTemplate.Content = Please enter a form template file.
LibraryTemplate.Content = Please enter a library template file.
FirstName = You must enter a valid first name.
InputItemRequired = You must enter at least one input line item, with an inventory item selected.

LineItem.MissingBin = You must select a bin on line {{lineNumber}}.
LineItem.QuantitySerialsMismatch = Quantity and serial number count don't match on line {{lineNumber}}.
LineItem.QuantityOverflow = Quantity on line {{lineNumber}} must have 8 or fewer digits left of the decimal point.
LineItem.UnitpriceOverflow = Unit price on line {{lineNumber}} must have 8 or fewer digits left of the decimal point.
LineItem.AmountOverflow = Amount on line {{lineNumber}} must have 8 or fewer digits left of the decimal point.
LineItemRequired = You must enter at least one line item, with an inventory item selected.

EmailMismatch = The email addresses you entered do not match.
Location = You must select a valid Location.
LowInventoryCalculation = You must select a a valid calculation.
Name = Name field is required.
Number = You must enter a valid bin number.
OutputItemRequired = You must enter at least one output line item, with an inventory item selected.
PaymentMethodRequired = A payment method selection is required.
ProvideReferenceNumber = You must provide a reference number.
Question = Question field is required.
salesTransaction.Date = You must enter a valid date.
SequenceNumber = You must enter a valid sequence number.
DisplayOn = You must select at least one entity to show this custom field on.
SortOrder = Sort order field is required.
Subject = Subject line is required.

uom.BaseUnitAlsoInTable = UOM on line {{lineNumber}} is the same UOM list as the base unit.
uom.DuplicateUom = Only one conversion per UOM selection is permitted.
uom.RatioRequired = Ratio value is required for line {{lineNumber}}.
uom.UomRequired = UOM selection is required for line {{lineNumber}}.
uom.ConversionMissingUom = Conversion on line {{lineNumber}} is missing the UOM selection.
uom.MustHaveBaseUnitSelected = A base unit selection is required.

[modalHeader]
onSo = Items on sales order
onSr = Items on sales receipt
onPo = Items on purchase order
onRma = Items on RMA
onWo = Items on work order
onRental = Items on Rental

[reports]

AddToReorderList = Add to reorder list
AddToSync = Re-sync
DeleteThisReport = Are you sure you want to delete {{reportName}}?
Email = Send via email
CustomDesignation = Custom
GeneratePos = Generate POs
Pos = POs
Reorder = Reorder
ReportTooLarge = This report is too large. Change the report settings to reduce the size.
ReportTooLargeToDisplay = This report is too large to display. Change the report settings or send it via email.
ProbableTimeOut = Report could not be fetched. Most likely cause is that generating the report timed out. Try setting one or more filters.
UnfavoriteThisReportHeader = Unfavorite
Unschedule = Unschedule
UnfavoriteThisReport = Are you sure you want to unfavorite {{reportName}}?
UnscheduleThisReport = Are you sure you want to unschedule the {{reportName}} report?
UnscheduleAndDelete = This will unschedule and delete {{reportName}}. If you only want to unschedule it, select it and unschedule it on the report page. Are you sure you want to proceed?

Report.MasterPurchaseReport = Master purchase report
Report.OpenPurchaseOrderDetail = Open purchase order detail
Report.PurchaseVariances = Purchase variances
Report.PurchasesByCategorySummary = Purchases by category - summary
Report.PurchasesByClassSummary = Purchases by class - summary
Report.PurchasesByCustomerSummary = Purchases by customer - summary
Report.PurchasesByDepartmentSummary = Purchases by department - summary
Report.PurchasesByItemSummary = Purchases by item - summary
Report.PurchasesByLocationSummary = Purchases by location - summary
Report.PurchasesByVendorSummary = Purchases by vendor - summary
Report.ReorderReport = Reorder report
Report.VendorEfficiency = Vendor efficiency
Report.WhereToBuy = Where to buy
Report.BinContents = Bin contents
Report.InventoryAging = Inventory aging
Report.InventoryStockStatus = Inventory stock status
Report.InventorySummary = Inventory summary
Report.InventoryTurnover = Inventory turnover
Report.InventoryValuation = Inventory value
Report.ItemAnalysis = Item analysis
Report.ItemMovement = Item movement
Report.ItemsInBins = Items in bins
Report.LotHistory = Lot history
Report.LotStockStatus = Lot stock status
Report.LotTraceability = Lot traceability
Report.LotsInBins = Lots in bins
Report.OverstockUnderstockReport = Overstock/understock report
Report.PhysicalInventoryWorksheet = Physical inventory worksheet
Report.SerialHistory = Serial unit history
Report.SerialsInBins = Serials in bins
Report.CommissionReport = Commission report
Report.MasterSalesReport = Master sales report
Report.OpenSalesOrderDetail = Open sales order detail
Report.OpenSalesReceiptDetail = Open sales receipt detail
Report.PriceBook = Price book
Report.ProfitLossByCustomer = Profit/loss by customer
Report.ProfitLossByItem = Profit/loss by item
Report.ProfitLossByJob = Profit/loss by job
Report.ReadyToInvoice = Ready to invoice
Report.SalesByCategorySummary = Sales by category - summary
Report.SalesByChannelSummary = Sales by channel - summary
Report.SalesByClassSummary = Sales by class - summary
Report.SalesByDepartmentSummary = Sales by department - summary
Report.SalesByCustomerSummary = Sales by customer - summary
Report.SalesByCustomerTypeSummary = Sales by customer type - summary
Report.SalesByItemSummary = Sales by item - summary
Report.SalesByLocationSummary = Sales by location - summary
Report.SalesByVendorSummary = Sales by vendor - summary
Report.SellThroughReport = Sell through
Report.BackorderReport = Backorder report
Report.FulfillmentEfficiency = Fulfillment efficiency
Report.ReadyToPickShip = Ready to pick/ship
Report.ShippingReport = Shipping report
Report.ExpandedBOM = Expanded BOM report
Report.OpenWorkOrderDetail = Open work order detail
Report.ProductionReport = Production report
Report.WhereUsed = Where used
Report.WorkInProgress = Work in progress
Report.ReconcileItemReceipts = Reconcile item receipts
Report.ReconcileJournalEntries = Reconcile journal entries
Report.ReconcileVendorCredits = Reconcile vendor credits
Report.OpenRentalDetail = Open rental detail
Report.RentalCalendar = Rental calendar
Report.DeletedItems = Deleted items
Report.TransactionsAssigned = Transactions assigned

WidgetText.Filters = Filters
WidgetText.Options = Options
WidgetText.Columns = Columns
WidgetText.RetrievingReport = Retrieving...

Format.Excel = Excel (.xlsx)
Format.LegacyExcel = Legacy Excel (.xls)
Format.CSV = Comma-separated values (.csv)

RetrieveForms.ReportType = Report type
RetrieveForms.Send = SEND
RetrieveForms.Email = Email

Action.Retrieve = Retrieve your report
Action.Favorite = Favorite
Action.SaveAsCustom = Save as...
Action.SaveSettings = Save
Action.Schedule = Schedule
Action.ChangeSchedule = Change
Action.Unschedule = Unschedule
Action.RenameCustom = Rename

Schedule.Frequency = Frequency
Schedule.Time = Time
Schedule.Day = Day
Schedule.Email = Email
Schedule.FTP = FTP

Alert.Custom.ErrorRetrieving = There was a problem retrieving your custom reports.
Alert.Custom.DuplicateName = Please enter a new name for the report. Duplicate custom report names are not allowed.
Alert.Scheduled.ErrorRetrieving = There was a problem retrieving your scheduled reports.
Alert.Saved = Saved.
Alert.Scheduling.Success = Scheduled successfully.
Alert.Scheduling.Error = There was an error scheduling this report.
Alert.Scheduling.NoFrequency = Select a schedule frequency.
Alert.Scheduling.MissingTimeOfDay = Select a time of day.
Alert.Scheduling.MissingDayOfWeek = Select a day of the week.
Alert.Scheduling.MissingEmailOrFtp = Enter an email address and/or select an FTP server.
Alert.ConvertScheduledStandardError = There was a problem converting your scheduled standard report to a custom report.
Alert.Scheduling.Test.Successful = The report has been queued to run once. Check your email or ftp server.
Alert.Scheduling.Test.Unsuccessful = There was a problem queueing your report.

CustomScheduled.Name = Name
CustomScheduled.Heading = Custom and scheduled

CategoryName.Purchasing = Purchasing
CategoryName.Inventory = Inventory
CategoryName.Sales = Sales
CategoryName.Fulfillment = Fulfillment 
CategoryName.Production = Production
CategoryName.Accounting = Accounting
CategoryName.Rentals = Rentals
CategoryName.Miscellaneous = Miscellaneous

FilterLabel.AdjustedOut = Adjusted out
FilterLabel.AsOfDate = As of date
FilterLabel.AssignedTo = Assigned to
FilterLabel.BinNumberContains = Bin number contains
FilterLabel.Bills = Bills
FilterLabel.Category = Category
FilterLabel.CashPurchases = Cash purchases
FilterLabel.Channel = Channel
FilterLabel.ChannelNameContains = Channel name contains
FilterLabel.Check = Check
FilterLabel.Class = Class
FilterLabel.ClassNameContains = Class name contains
FilterLabel.CreditCardCharges = Credit card charges
FilterLabel.JobNameContains = Job name contains
FilterLabel.Customer = Customer
FilterLabel.CustomerNameContains = Customer name contains
FilterLabel.CustomerType = Customer type
FilterLabel.CustomerTypeNameContains = Customer type name contains
FilterLabel.DepartmentNameContains = Department name contains
FilterLabel.Department = Department
FilterLabel.DropShip = Drop ship
FilterLabel.DropShipPurchaseOrder = Drop ship purchase order
FilterLabel.Expired = Expired
FilterLabel.FromDate = From date
FilterLabel.InStock = In stock
FilterLabel.InputItemNameContains = Input item name contains
FilterLabel.ItemNameContains = Item name contains
FilterLabel.ItemProduced = Item produced
FilterLabel.Location = Location
FilterLabel.LotNumberContains = Lot number contains
FilterLabel.LocationNameContains = Location name contains
FilterLabel.NotExpiredNotRecalled = Not expired, not recalled
FilterLabel.OrderType = Order type
FilterLabel.Outputs = Outputs
FilterLabel.OutputItemNameContains = Output item name contains
FilterLabel.PaymentType = Payment type
FilterLabel.PriceTier = Price tier
FilterLabel.Quantity = Quantity
FilterLabel.Recalled = Recalled
FilterLabel.SalesRep = Sales rep
FilterLabel.SerialNumberContains = Serial number contains
FilterLabel.Shipped = Shipped
FilterLabel.ShowAllOutputs = Show all outputs
FilterLabel.ShowWasteOnly = Show waste only
FilterLabel.ShowNonWasteOnly = Show non waste only
FilterLabel.ToDate = To date
FilterLabel.TransactionType = Transaction type
FilterLabel.Unassigned = Unassigned
FilterLabel.Used = Used
FilterLabel.Vendor = Vendor
FilterLabel.VendorNameContains = Vendor name contains

OptionLabel.AutoAddToReorderList = Auto-add to reorder list
OptionLabel.Customer = Customer
OptionLabel.Class = Class
OptionLabel.Channel = Channel
OptionLabel.Category = Category
OptionLabel.Component = Component
OptionLabel.Department = Department
OptionLabel.ExpandSubAssemblies = Expand sub-assemblies
OptionLabel.FromPaymentDate = From payment date
OptionLabel.GroupBy = Group by
OptionLabel.HideEmptyBins = Hide empty bins
OptionLabel.HideRowsWithNoPurchases = Hide rows with no purchases
OptionLabel.HideRowsWithNoTransactions = Hide rows with no transactions
OptionLabel.HideRowsWithAllZeros = Hide rows with all zeros
OptionLabel.HideRowsWithNoShipmentsOrInventory = Hide rows with no shipments or inventory
OptionLabel.HideRowsWithNoSales = Hide rows with no sales
OptionLabel.HideRowsWithNoSalesOrShipments = Hide rows with no sales or shipments
OptionLabel.HighlightReorders = Highlight reorders
OptionLabel.HighlightPastDueDates = Highlight past due dates
OptionLabel.IncludeArchivedItems = Include archived items
OptionLabel.IncludeArchivedJobs = Include archived job
OptionLabel.IncludeArchivedCategories = Include archived categories
OptionLabel.IncludeArchivedCustomers = Include archived customers
OptionLabel.IncludeArchivedVendors = Include archived vendors
OptionLabel.IncludeDeletedItems = Include deleted items
OptionLabel.IncludePOsInCalculation =  Include POs in calculation
OptionLabel.IncludeRMAsInCalculation = Include RMAs in calculation
OptionLabel.IncludeRentalsInCalculation = Include rentals in calculation
OptionLabel.IncludeWOsInCalculation = Include WOs in calculation
OptionLabel.Item = Item
OptionLabel.Location = Location
OptionLabel.NonZeroOnly = Non-zero only
OptionLabel.OpenClosed = Open / closed
OptionLabel.OverstockUnderstock = Overstock / understock
OptionLabel.OnlyShowFullyShippedOrders = Only show fully-shipped orders
OptionLabel.Output = Output
OptionLabel.ShowOnlyItemsThatHaveBeenPicked = Show only items that have been picked
OptionLabel.Parent = Parent
OptionLabel.SalesRep = Sales rep
OptionLabel.ShowOnlyIfInBin = Show only if in bin
OptionLabel.ShowSerials = Show serials
OptionLabel.ShowFromAndToDates =  Show "from" and "to" dates
OptionLabel.ShowLots = Show lots
OptionLabel.ShowBins = Show bins
OptionLabel.ReportValue =  Report value
OptionLabel.ShowAsOfDate = Show "as of" date
OptionLabel.ToPaymentDate = To payment date
OptionLabel.TreatEmptyReorderPointsAsZero = Treat empty reorder points as zero
OptionLabel.Vendor = Vendor
OptionLabel.UseStandardCosts =  Use standard costs
OptionLabel.UseStandardSalePrices = Use standard sales prices
OptionLabel.UseAvailableQuantityInsteadOfOnHandQuantity = Use available qty instead of on hand qty
OptionLabel.ShowTransactionSummaryOnly = Show transaction summary only
OptionLabel.SubtractCustomerReturns = Subtract customer returns
OptionLabel.ShowOnlyIfOrderInvoicedOrInvoicePaid = Show only if order invoiced or invoice paid
OptionLabel.ShowOnlyOrdersThatCanShipInFull = Show only orders that can ship in full
OptionLabel.UseNetInsteadOfGrossBeta = Use net instead of gross (beta)  

oneYearMax = one year max

hoverHelp.SalesKeywordSearch= Keyword search in customer PO, comment, and customer message fields.
hoverHelp.PurchaseKeywordSearch = Keyword search in comment and customer message fields.
hoverHelp.ShowTransactionSummaryOnly = Only transaction-level filters and columns will work if this option is enabled.
hoverHelp.UseNetInsteadOfGrossBeta = This feature is in an early-release state and might have more issues than normal. Use with caution and contact us if you run into any problems.
hoverHelp.RunThisReportNow = Run this report now, with the given email and/or FTP server
hoverHelp.ShowOnlyIfOrderInvoicedOrInvoicePaid = If the Order type filter is set for Sales orders, the report will reflect invoiced sales orders only. If the Order type filter is set for Invoices, the report will reflect only those invoices which have been paid.

categories = categories
earliest = earliest
From = From 
ItemFilterRequired = An item must be selected
KeywordSearch = Keyword search
latest = latest
LocationFilterRequired = A location must be selected
SalesRepFilterRequired = A sales rep must be selected
AssignedToFilterRequired = An assigned to user selection is required
OrderTypeFilterRequired = An order type must be selected
locations = locations
LotFilterRequired = A lot filter must be selected
to = to
salesReps = sales reps
ShowBothOverstockAndUnderStock = Show both overstock and understock
ShowDropShippedOnly = Drop-shipped
ShowNotDropShippedOnly = Not drop-shipped
ShowOverstockOnly = Show overstock only
ShowUnderstockOnly = Show understock only
ThisReportDoesNotHaveSelectableColumns = This report does not have selectable columns

Subheader.categories.singular = category
Subheader.categories.plural = categories
Subheader.locations.singular = location
Subheader.locations.plural = locations
Subheader.salesReps.singular = sales rep
Subheader.salesReps.plural = sales reps

withChannel = with channel
withDepartment = with department
withClass = with class
withPriceTier = with price tier
containing = containing
NoSelectionDefaultsToTotalsOnly = No selection defaults to totals only

placeholder.DefaultIsAll = Default is all {{type}}
placeholder.DefaultIsTotalsOnly = Default is totals only

ScheduledReportOptions.Frequency.Daily = Daily
ScheduledReportOptions.Frequency.Weekly = Weekly
ScheduledReportOptions.Frequency.Monthly = Monthly

ScheduledReportOptions.Days.Monday = Mon
ScheduledReportOptions.Days.Tuesday = Tue
ScheduledReportOptions.Days.Wednesday = Wed
ScheduledReportOptions.Days.Thursday = Thu
ScheduledReportOptions.Days.Friday = Fri
ScheduledReportOptions.Days.Saturday = Sat
ScheduledReportOptions.Days.Sunday = Sun

ScheduledReportOptions.Times.0 = Midnight
ScheduledReportOptions.Times.1 = 1:00 AM
ScheduledReportOptions.Times.2 = 2:00 AM
ScheduledReportOptions.Times.3 = 3:00 AM
ScheduledReportOptions.Times.4 = 4:00 AM
ScheduledReportOptions.Times.5 = 5:00 AM
ScheduledReportOptions.Times.6 = 6:00 AM
ScheduledReportOptions.Times.7 = 7:00 AM
ScheduledReportOptions.Times.8 = 8:00 AM
ScheduledReportOptions.Times.9 = 9:00 AM
ScheduledReportOptions.Times.10 = 10:00 AM
ScheduledReportOptions.Times.11 = 11:00 AM
ScheduledReportOptions.Times.12 = Noon
ScheduledReportOptions.Times.13 = 1:00 PM
ScheduledReportOptions.Times.14 = 2:00 PM
ScheduledReportOptions.Times.15 = 3:00 PM
ScheduledReportOptions.Times.16 = 4:00 PM
ScheduledReportOptions.Times.17 = 5:00 PM
ScheduledReportOptions.Times.18 = 6:00 PM
ScheduledReportOptions.Times.19 = 7:00 PM
ScheduledReportOptions.Times.20 = 8:00 PM
ScheduledReportOptions.Times.21 = 9:00 PM
ScheduledReportOptions.Times.22 = 10:00 PM
ScheduledReportOptions.Times.23 = 11:00 PM

explanation.BackorderReport = Lists data on backordered (unshipped) sales orders and sales receipts
explanation.BinContents = Lists bin contents by location and bin number, along with the quantity on hand for each bin item
explanation.DeletedItems = Displays a list of deleted Items and allows restore of such items
explanation.CommissionReport = Shows commission data filtered by the sales representative, item name, customer, and order type
explanation.ExpandedBOM = Provides details on an assembly item, including each component, its vendor, the quantity on hand, the quantity used in a build, the cost of each component, and the line total
explanation.FulfillmentEfficiency = Provides information on the turnaround time in fulfilling customer orders from the time a sales order or sales receipt is placed until such sale is shipped
explanation.InventoryAging = Provides a list of items on hand and how long they have been in inventory, along with the transaction that brought them into inventory
explanation.InventoryStockStatus = Shows a list of items and their on-hand quantities
explanation.InventorySummary = Shows a list of items to include the on hand, on transactions, and available for sale quantities
explanation.InventoryTurnover = Shows the number of times inventory is sold or consumed in a given time period
explanation.InventoryValuation = Shows a list of items and their on-hand quantities, along with the value
explanation.ItemAnalysis = Shows a list of items that have sold or shipped in the report period along with cost and sales price average; highlights indicate areas where standard costs/prices used
explanation.ItemMovement = Provides details about items’ movements on transactions: the quantity received, returned to vendors, shipped, adjusted, etc., as well as the total quantity in and out
explanation.ItemsInBins = Compiles data on bin-tracked items that includes item location, bin number, and quantity on hand
explanation.LotHistory = Shows the transaction history of lot-tracked items
explanation.LotStockStatus = Gives data on the current status of a lot-tracked item
explanation.LotsInBins = Provides data on a lot-tracked item stored in bins
explanation.LotTraceability = Allows you to enter a lot and trace either where the lot came from or where the lot went
explanation.MasterPurchaseReport = Provides line detail information regarding purchase orders, item receipts, and returns to vendors
explanation.MasterSalesReport = Provides line level detail of sales transactions in the report period
explanation.OpenPurchaseOrderDetail = Provides data on purchase orders with items that have yet to be received
explanation.OpenRentalDetail = Shows line level detail of rental transactions that have yet to be returned
explanation.OpenSalesOrderDetail = Shows open sales order transactions with unshipped items
explanation.OpenSalesReceiptDetail = Shows open sales receipt transactions with unshipped items
explanation.OpenWorkOrderDetail = Shows status of open work orders
explanation.OverstockUnderstockReport = Gives a breakdown of inventory for a specified item; the report includes the item’s reorder and maximum stock level, as well as the total quantity
explanation.PhysicalInventoryWorksheet = Creates a worksheet that includes the system count of one or more items and a column for you to enter the actual count of each item listed
explanation.PriceBook = Provides pricing information about items, the price tier and customer to whom an item is sold, along with the quantity and sales price
explanation.ProductionReport = Provides information on build and process transactions, the outputs, and the output quantity
explanation.ProfitLossByCustomer = Provides a list of customers and reflects sales revenue from sales in the report period along with COGS from shipments in the report period
explanation.ProfitLossByItem = Provides a list of items and reflects sales revenue from sales in the report period along with COGS from shipments in the report period
explanation.ProfitLossByJob = Provides a list of Jobs and reflects sales revenue from sales in the report period along with COGS from shipments in the report period
explanation.PurchaseVariances = Shows the difference between standard purchase price and actual purchasing costs
explanation.PurchasesByCategorySummary = Provides a list of categories along with quantities purchased of each category in the report period
explanation.PurchasesByClassSummary = Provides a list of classes along with quantities purchased within each class in the report period
explanation.PurchasesByCustomerSummary = Provides a list of customers along with quantities purchased for each customer in the report period; customer must have been listed on the purchase transaction
explanation.PurchasesByDepartmentSummary = Provides a list of departments along with quantities purchased in the report period
explanation.PurchasesByItemSummary = Provides a list of items along with the quantities purchased in the report period
explanation.PurchasesByLocationSummary = Provides a list of locations along with quantities purchased for each location
explanation.PurchasesByVendorSummary = Provides a list of vendors along with totals purchased from each vendor
explanation.ReadyToInvoice = Shows lines of open sales orders that have shipped but not yet been invoiced
explanation.ReadyToPickShip = Shows a list of sales orders and sales receipts that are ready to be picked and/or shipped
explanation.RentalCalendar = Shows the due dates for items on open rental transactions
explanation.ReorderReport = Provides a list of items whose available quantity is equal to or less than the item reorder point
explanation.SalesByCategorySummary = Provides a list of Categories and reflects sales revenue from sales along with COGS from shipments for sales that shipped in the report period
explanation.SalesByChannelSummary = Provides a list of Channels and reflects sales revenue from sales along with COGS from shipments for sales that shipped in the report period
explanation.SalesByClassSummary = Provides a list of Classes and reflects sales revenue from sales along with COGS from shipments for sales that shipped in the report period
explanation.SalesByCustomerSummary = Provides a list of Customers and reflects sales revenue from sales along with COGS from shipments for sales that shipped in the report period
explanation.SalesByCustomerTypeSummary = Provides a list of Customer Types and reflects sales revenue from sales along with COGS from shipments for sales that shipped in the report period
explanation.SalesByDepartmentSummary = Provides a list of Departments and reflects sales revenue from sales along with COGS from shipments for sales that shipped in the report period
explanation.SalesByItemSummary = Recaps by item the units shipped, revenue, COGS, and profit, as well as the percentages in each of these categories
explanation.SalesByLocationSummary = Provides a list of Items and reflects sales revenue from sales along with COGS from shipments for sales that shipped in the report period
explanation.SalesByVendorSummary = Provides a list of Vendors and reflects sales revenue from sales of items assigned vendor as preferred along with COGS from shipments for sales that shipped in the report period
explanation.SellThroughReport = Shows the percentage of a product that is sold to customers after being received from vendors for a certain date range
explanation.SerialHistory = Shows the transaction history of serial-tracked items
explanation.SerialsInBins = Compiles data on serial-tracked items that includes the serial number, item name, location, and bin number
explanation.ShippingReport = Shows completed shipments by transaction, ship date, customer, item and quantity shipped of an inventory item
explanation.TransactionsAssigned = Provides a list of transactions with the name of the individuals to whom they have been assigned
explanation.VendorEfficiency = Provides information on the turnaround time used by a vendor from the time a purchase order is placed until it is received
explanation.WhereToBuy = Compiles data from the Vendor-Item Catalog so that you can see which of your vendors sell an item, each vendor’s price, and the quantity you need to order
explanation.WhereUsed = Shows a list of assemblies and kits that an item is included in such bill of materials (BOM)
explanation.WorkInProgress = Lists builds and processes that are in a work in progress workcenter
explanation.ReconcileItemReceipts = Compares SOS Inventory transactions in the selected period to the associated transactions in QuickBooks Online, highlighting any differences
explanation.ReconcileJournalEntries = Compares SOS Inventory transactions in the selected period to the associated transactions in QuickBooks Online, highlighting any differences
explanation.ReconcileVendorCredits = Compares SOS Inventory transactions in the selected period to the associated transactions in QuickBooks Online, highlighting any differences

[sosPayments]

01 = SOS Payments
02 = Why add payment processing to SOS?
03 = Receive payments in SOS against invoices, sales orders, and sales receipts.
04 = Sync SOS payments with QuickBooks.
05 = Process credit card and ACH payments in SOS at low rates.
06 = Let customers pay invoices online via a convenient payment link.
07 = Click the button below to get started.
08 = 2.8%
09 = + 30 cents per successful charge
10 = Simple, fixed, straightforward pricing at a low rate.
11 = No monthly fees. No setup fees. No hidden fees.
12 = Easy to budget and plan.
13 = Streamlined onboarding process.
14 = Request SOS Payments registration information
15 = Contact us
16 = Thanks for your interest in using SOS Inventory's payments solution. Please complete and submit the form below, and we'll be in touch soon with information on completing your registration.
17 = Contact name
18 = Contact email
19 = Contact phone
20 = Company name
21 = Brief description of the business
22 = We've got your information and will be in touch soon!
23 = Something went wrong. Please call us at 817-422-5909.

[notes]
BlueMeansNotSentToQbo = Blue means that the {{type}} could not be sent to QuickBooks.
GreenMeansNoMatchingInQbo = Green means that SOS could not find the {{type}} in QuickBooks when trying to reconcile.
journalEntry = journal entry
matchingTranasction = matching transaction
RedMeansCostDoesNotMatch = Red means that the cost in SOS does not match the cost in QuickBooks.
ReconcileVendorCredits = This report helps you to determine differences between return to vendor transactions (affecting SOS Inventory value) and corresponding vendor credit transactions in QuickBooks (usually affecting the asset account). There might be times when the credit that the vendor gives you is not the same as the inventory value of the item. In this case, you will need to make an adjustment (either in QuickBooks or in SOS) for your inventory values to reconcile. Please contact support@sosinventory.com if you have any questions.
WillBeAvailableForPeriodOfTime = The old commission report will be available for period of time while our customers transition to this one.
ShownOnFirstLine = Per-transaction commissions and transaction-level discounts are shown on the first line of a transaction.
TaxCodesMustBeEditedInQBO = Tax codes must be created and edited in QuickBooks. They cannot be edited in SOS Inventory.
vendorCredit = vendor credit
YellowMeansNoMatchingInSOS = Yellow means that no {{type}} was created in SOS.
onSo = Note: The quantity column above shows the quantity of the item ordered. If the item in the report is a component of the item ordered, the quantities might not match the total in the "On SO" column of the Item List. This behavior is by design.
onSr = Note: The quantity column above shows the quantity of the item ordered. If the item in the report is a component of the item ordered, the quantities might not match the total in the "On SR" column of the Item List. This behavior is by design.
onWo = Note: The quantity column above shows the quantity of the item on the work order. If the item in the report is a component of the item on the work order, the quantities might not match the total in the "On WO" column of the Item List. This behavior is by design.
onRental = Note: The quantity column above shows the quantity of the item ordered. If the item in the report is a component of the item ordered, the quantities might not match the total in the "On Rental" column of the Item List. This behavior is by design.

[expiredDisabled]
SubscriptionExpired = Subscription expired
Admin.ExpiredMessage.1 = Your company's subscription to SOS Inventory has expired. Please provide updated
Admin.ExpiredMessage.2 = payment information
Admin.ExpiredMessage.3 = to access your account.
NonAdmin.ExpiredMessage = Your company's subscription to SOS Inventory has expired. Please have an admin update the payment information to access the account.
SubscriptionDisabled = Account disabled
DisabledMessage = Your account is currently disabled for administrative reasons.
ContactSupport.1 = You may contact support at
ContactSupport.2 = if you have issues reactivating your account.

[home]
beta.header = SOS Inventory V9 Beta site
beta.title = Welcome to the early-access beta.

beta.p1 = Our team is excited about version 9’s many enhancements and its new, more modern interface. Some of the new features include:

beta.bullet.1 = The capability to upload and manage documents from within a transaction.
beta.bullet.2 = The ability to reorder columns displayed on lists, as well as list view performance enhancements.
beta.bullet.3 = Improved serial number interface on transactions.
beta.bullet.4 = The inclusion of amount-on-hand quantities for lot- and bin-tracked items entered on a transaction.
beta.bullet.5 = The capability to create complete data records for new items, vendors and customers that are entered via transactions.
beta.bullet.6 = An improved interface for reports and payments. 

beta.p2.1 = For more details on these and many other improvements, please refer to the
beta.p2.2 = SOS Inventory 9 Beta User Guide.

beta.p3.1 = As one of our beta testers, we ask that you provide us feedback on what you like or dislike, any bugs that you may find, and suggestions for further improvements. You can submit this feedback to our existing support email,
beta.p3.2 = . Please add “V9 Beta” to the subject line so we can easily see this is related to the beta.

beta.p4.1 = Upgrades currently available in the beta are focused on transaction, item, vendor, and customer pages, as well as reports. Other pages in version 9 have yet to be completed and will arrive in the beta in the months to come. Among the currently disabled features are most top menu items, and sync functionality. You can return to our current version at
beta.p4.2 = at any time if you need to access these features. The beta and current version operate on the same set of data.

beta.p5 = We are excited to get your feedback. The Development team looks forward to bringing all of our current features into the new version as we prepare to launch version 9 later this year.

live.header = Home
live.1 = This website is for testing and temporary use ONLY. The official SOS Inventory app is available at
live.2 = Features and functionality on this website might not be fully operational. Use this site at your own risk. If you have any questions, please contact
live.3 = Thank you.

trial.1 = There are
trial.2 = remaining in your trial period. 
trial.3 = Click here to subscribe.

help.title = How Can We Help?

help.p1.1 = Did you know that in addition to email and phone support, we offer demos, training, and implementation assistance? For more information, email our support team at
help.p1.2 = , or send us a message via our
help.p1.3 = Contact
help.p1.4 = page.

help.p2.1 = As always, please email us at
help.p2.2 = if you have any comments, questions, or suggestions.

[whatsNew]
WhatsNew = What's new

1.heading = v9 beta 1 (July 2022)
1.1 = Initial public release of v9
1.2 = Added item, customer, and vendor records support
1.3 = Added support for all primary transaction types

2.heading = v9 beta 2 (August 2022)
2.1 = Added lot and serial lists

3.heading = v9 beta 3 (October 2022) 
3.1 = Added support for RMA, returns, rentals, and rental returns
3.2 = Added functionality for item BOM editing and item variant editing

4.heading = v9 beta 4 (January 2024)
4.1 = Added item transaction history and rental history
4.2 = Added job profit/loss

5.heading = v9 beta 5 (September 2023)
5.1 = Added reports
5.2 = Added support for global search
5.3 = Added payments
5.4 = Added sync menu list items

6.heading = v9 beta 6 (December 2023)
6.1 = Added manual sync with QBO
6.2 = Added support for sync preview and sync error 
6.3 = Added quick view support
6.4 = Added the home page
6.5 = Added support for the reorder list

7.heading = v9 beta 7 (February 2024)
7.1 = Added support for print formatting on most pages
7.2 = Added the calendar
7.3 = Completed the help menu items
7.4 = Added the dashboard
7.5 = Added tasks

[contactUs]
Success = We received your message and will get back to you shortly.
Confirmation = Your request was succesfully sent, and support will be reaching out to you.
header = Contact us
SubmitAnotherTicket = Submit another support ticket
WeWillReplyTo = We will reply to 

1 = For fastest results, we recommend that you contact our Support team via email. This allows us to track and assign your case to the appropriate person, as well as look up your account (if necessary) to help answer your question.
2.1 = Email us directly at
2.2 = or complete the form below. We will respond to you via email as quickly as possible.
3 = 'Please describe your problem in detail and refer to a specific transaction when appropriate. For example, "Please look at PO #123. I need to edit it, but the system says it is closed."'

[dashboard]
pendingEstimates = Pending estimates
openSos = Open lines on sales orders
openSrs = Open sales receipts
readyToShip = Lines ready to ship
readyToInvoice = Lines ready to invoice
openPos = Open purchase orders
openWos = Open work orders
openRmas = Open RMAs
lastSync = Date of last sync
txnsToSend = Transactions to send
showTxnsSent = Transactions sent
showRentalsPastDue = Rentals past due
Never = Never

RefreshDashboard = Refresh dashboard
Settings = Settings
DashboardSettings = Dashboard settings

show.pendingEstimates = Pending estimates
show.openSos = Open sales orders
show.openSrs = Open sales receipts
show.readyToShip = Ready to ship
show.readyToInvoice = Ready to invoice
show.openPos = Open purchase orders
show.openWos = Open work orders
show.openRmas = Open RMAs
show.lastSync = Last sync date
show.txnsToSend = "# of transactions to send to QuickBooks"
show.showTxnsSent = "# of transactions sent to QuickBooks"
show.showRentalsPastDue = Past due rentals

[about]
version = Version
apiVersion = API version
termsOfService = Terms of service
privacyPolicy = Privacy policy

[syncitem]
DeleteConfirm = Delete sync item?
MoveToEnd = Move to bottom
MoveToEndSuccess = Moved
MoveToEndFailure = Could not move the sync item
GoTo = Go to

labelAction = Action
labelObject = Object
labelType = Type

[calendar]

Heading = Calendar
monday = Monday
tuesday = Tuesday
wednesday = Wednesday
thursday = Thursday
friday = Friday
saturday = Saturday
sunday = Sunday

january = January
february = February
march = March
april = April
may = May
june = June
july = July
august = August
september = September
october = October
november = November
december = December

thisMonth = Return to current month

settingsButton = Settings
settingsHeader = Calendar settings

entryLabel.task = Tasks
entryLabel.invoice = Invoices
entryLabel.rma = RMAs
entryLabel.pickticket = Pick tickets
entryLabel.workorder = Work orders
entryLabel.salesorder = Sales orders
entryLabel.lot = Lots
entryLabel.purchaseorder = Purchase orders
entryLabel.salesreceipt = Sales receipts
entryLabel.shipment = Shipments
entryLabel.rental = Rentals

suffix.toBeCompleted = to be completed
suffix.expiring = expiring
suffix.expected = expected
suffix.withAnItemDue = with an item due
suffix.due = due
suffix.toBePicked = to be picked
suffix.toBeShipped = to be shipped

Error.UnableToSaveSettings = We were unable to save your calendar settings. Please try again."

[configuration]

Configuration = Configuration

[settings]

UnableToUpdateSettings = Unable to update settings

list.label = Lists

UserSettings.label = User settings
company.label = Company
general.label = General
inventory.label = Inventory
accounts.label = Accounts
transactions.label = Transactions
purchasing.label = Purchasing
fulfillment.label = Fulfillment
payments.label = Payments
barcode.label = Barcode
connections.label = Connections
quickbooks.label = QuickBooks
shipstation.label = ShipStation
ups.label = UPS
cart.label = Carts
ftp.label = FTP
customerPortal.label = Customer portal
userAdministration.label = User administration

nextNumberHelperText = Enter an integer greater than zero

valuationMethod.Average = Weighted average
valuationMethod.FIFO = FIFO
valuationMethod.LIFO = LIFO

itemReceiptSyncOptions.None = None
itemReceiptSyncOptions.Bill = Bill
itemReceiptSyncOptions.Cash = Cash
itemReceiptSyncOptions.Check = Check
itemReceiptSyncOptions.CreditCard = Credit card

transactionNumbering.explanation = If desired, you may enter a prefix that will be automatically prefixed to all {{transactionType}} transactions. If you want to (re)set the next {{transactionType}} number, you can do that as well. Both fields are optional. You can always enter a transaction number manually when creating or editing a transaction.

inventory.general.allowNegativeInventory.label = Allow negative inventory
inventory.general.allowNegativeInventory.explanation = If this option is checked, SOS Inventory will not warn you when shipping, using, or adjusting items that would cause your inventory to go negative.
inventory.general.allowNegativeInBins.label = Allow negative inventory in bins
inventory.general.allowNegativeInBins.explanation = If this option is checked, SOS Inventory will allow you to enter a transaction that would cause the inventory in a bin to go negative.
inventory.general.defaultItemIsTaxable.label = New items taxable
inventory.general.defaultItemIsTaxable.explanation = Check this option to set new items as taxable by default.
inventory.general.defaultItemSyncWithQBO.label = Sync new items
inventory.general.defaultItemSyncWithQBO.explanation = Check this option to sync new items with QuickBooks by default.
inventory.general.calculateReorderPoints.label = Calculate reorder points
inventory.general.calculateReorderPoints.explanation = Check this option to have SOS Inventory calculate reorder points automatically, on a weekly basis. The calculation makes an estimate based on historical data. Enabling this setting will override existing reorder points. You may wish to export your item data prior to enabling this setting, if you do not want to lose existing reorder points.
inventory.general.includeCategoryPrefix.label = Include category prefix on item names (formerly "Find items by short name")
inventory.general.includeCategoryPrefix.explanation = Check this box to include the category assigned to an item as a prefix to the item name on transactions, imports, exports, and reports.
inventory.general.searchAnyPartOfItem.label = Search any part of item name
inventory.general.searchAnyPartOfItem.explanation = Check this option to search for an item by any part of its name, instead of only the beginning of the name.
inventory.general.searchItemDescription.label = Search item description
inventory.general.searchItemDescription.explanation = Check this option to search for items by description, in addition to name. Enabling this can slow search results if you have a large number of items.
inventory.general.inventoryMethod.label = Valuation method
inventory.general.inventoryMethod.explanation = Select the method to be used for calculating the value of your inventory on reports, as well as item value as inventory is depleted (e.g., build input value, COGS on shipments, etc.). Any change in the valuation method will not affect transactions you created before making the switch to the new method.  However, if you resave historical transactions, this will cause the new valuation method to be used on that transaction and ALL transactions forward for the items on the saved transaction. If you are unsure about this change, contact a qualified accountant.
inventory.general.valueSerialsBySpecificId.label = Value serials by specific ID
inventory.general.valueSerialsBySpecificId.explanation = Check this box to value serialized items by specific unit cost, regardless of the valuation method for other items.
inventory.general.useTransactionsForSerialQOH.label = Count serials by unit
inventory.general.useTransactionsForSerialQOH.explanation = Check this box to count serialized items by the number of serialized units, regardless of the transaction totals. This is an advanced setting and should not normally be changed. If you aren't sure, leave the default setting.
inventory.general.overrideFIFObyLocation.label = Always use average costs for cost by location
inventory.general.overrideFIFObyLocation.explanation = Check this box to calculate the cost basis at a specific location using average costs instead of FIFO or LIFO, even if you use FIFO or LIFO otherwise. This can be helpful in producing reports that are more meaningful, even though not strictly FIFO or LIFO.
inventory.general.dontShowQuantityWhenEditing.label = Don't show quantity on builds and shipments
inventory.general.dontShowQuantityWhenEditing.explanation = For customers creating long builds or shipments, calculating item quantities for each item may slow input. Enable this option to remove that column.
inventory.general.pictureSize.label = Picture size (width x height)
inventory.general.pictureSize.explanation = Enter the pixel dimensions for inventory item images that will appear on the item page.
inventory.general.unitsOfMeasureEnabled.label = Use units of measure
inventory.general.unitsOfMeasureEnabled.explanation = Check this box to track multiple units of measure for your items.
inventory.general.rentalInventoryEnabled.label = Rental inventory
inventory.general.rentalInventoryEnabled.explanation = Check this box to enable rental inventory.
inventory.general.trackWorkersOnLaborItems.label = Track workers
inventory.general.trackWorkersOnLaborItems.explanation = Check this box to track individual workers when entering labor items on builds or process transactions.
inventory.general.serialLotNumbering.label = Serial/lot numbering
inventory.general.serialLotNumbering.explanation = ""
inventory.general.serialLotNumbering.explanation.1 = Use these settings to control how serial and lot numbers are created at item receipt time. You can enter numbers manually on item receipt transactions, or have the system assign them automatically.
inventory.general.serialLotNumbering.explanation.2 = If you choose automatic, choose a length, between 8 and 32 digits, for the numbers.
inventory.general.serialLotNumbering.explanation.3 = Numbers can be sequentially or randomly generated.
inventory.general.serialLotNumbering.explanation.4 = For both sequential and random serial and lot numbers, you can specify a prefix to be used for each serial or lot number.
inventory.general.serialLotNumbering.explanation.5 = For sequential numbering, you can specify the next number to be generated.
inventory.general.serialLotNumbering.autoLength = Serial/lot number length
inventory.general.serialLotNumbering.autoLength.helperText = Enter an integer between 8 and 32
inventory.general.serialLotNumbering.nextNumber.helperText = Enter an integer greater than zero
inventory.general.serialLotNumbering.prefix = Prefix
inventory.general.serialLotNumbering.nextNumber = Next number

inventory.general.jobTrackingEnabled.label = Job tracking
inventory.general.jobTrackingEnabled.explanation = Check this box to track transactions by job.

inventory.accounts.defaultIncomeAccount.label = "Default income account"
inventory.accounts.defaultAssetAccount.label = "Default asset account"
inventory.accounts.defaultCOGSAccount.label = "Default cost of goods account"
inventory.accounts.defaultAdjustmentAccount.label = "Default adjustment account"
inventory.accounts.defaultCheckAccount.label = "Default checking account"
inventory.accounts.defaultCreditCardAccount.label = "Default credit card account"
inventory.accounts.defaultCashPurchaseAccount.label = "Default cash account"

inventory.transactions.adjustmentNumber.label = Adjustment numbering
inventory.transactions.adjustmentNumber.explanation = ""
inventory.transactions.transferNumber.label = Transfer numbering
inventory.transactions.transferNumber.explanation = ""

inventory.accounts.defaultIncomeAccount.label = "Default income account"
inventory.accounts.defaultIncomeAccount.explanation = "Default income account for new items."
inventory.accounts.defaultAssetAccount.label = "Default asset account"
inventory.accounts.defaultAssetAccount.explanation = "Default inventory asset account for new items."
inventory.accounts.defaultCOGSAccount.label = "Default cost of goods account"
inventory.accounts.defaultCOGSAccount.explanation = "Default cost of goods sold account for new items. "
inventory.accounts.defaultAdjustmentAccount.label = "Default adjustment account"
inventory.accounts.defaultAdjustmentAccount.explanation = "Default account to use for tracking inventory adjustments. Typically, this would be something like Breakage, Spoilage, or Adjustments."
inventory.accounts.defaultCheckAccount.label = "Default checking account"
inventory.accounts.defaultCheckAccount.explanation = "Default account for new checks issued while receiving items. "
inventory.accounts.defaultCreditCardAccount.label = "Default credit card account"
inventory.accounts.defaultCreditCardAccount.explanation = "Default account for new credit card charges made while receiving items."
inventory.accounts.defaultCashPurchaseAccount.label = "Default cash account"
inventory.accounts.defaultCashPurchaseAccount.explanation = "Default account for new cash purchases made while receiving items."

inventory.barcode.settings.label = Barcode settings
inventory.barcode.settings.explanation = "Choose a format and enter the dimensions for printing, in inches."
inventory.barcode.settings.sampleScaledToFit = (MAY BE SCALED TO FIT)

fulfillment.shipments.shipmentNumber.label = Shipment numbering
fulfillment.shipments.shipmentNumber.explanation = ""
fulfillment.shipments.autoBuildShipment.label = Auto-build assemblies 
fulfillment.shipments.autoBuildShipment.explanation = Check this option to build assemblies automatically when creating a shipment. This feature builds only top-level assemblies, not all levels of a multi-level assembly. 
fulfillment.shipments.packingSlipText.label = Packing slip title 
fulfillment.shipments.packingSlipText.explanation = Change this value if a different title (for example, Packing List) is desired when printing or emailing work orders. The title should not be more than 50 characters. 
fulfillment.shipments.replyEmailPS.label = Packing slip Reply-To
fulfillment.shipments.replyEmailPS.explanation = The email address where you will receive replies to your packing slip emails.
fulfillment.shipments.packingSlipEmail.label = Packing slip message 
fulfillment.shipments.packingSlipEmail.explanation = The body of the email message to be sent with your packing slips. Do not use HTML. 
fulfillment.shipments.packingSlipFooter.label = Packing slip footer 
fulfillment.shipments.packingSlipFooter.explanation = Text to be displayed at the bottom of every packing slip. 
fulfillment.shipments.showCustEmailOnPackingSlip.label = Show on packing slips - Customer email address 
fulfillment.shipments.showCustEmailOnPackingSlip.explanation = Enable this setting to display the customer's email address on packing slips. 
fulfillment.shipments.showCustPhoneOnPackingSlip.label = Show on packing slips - Customer phone number 
fulfillment.shipments.showCustPhoneOnPackingSlip.explanation = Enable this setting to display the customer's phone number on packing slips. 
fulfillment.shipments.shipFromAddressOfLocation.label = Ship from address of location 
fulfillment.shipments.shipFromAddressOfLocation.explanation = By default, SOS displays the company header information on a packing slip. Enable this setting to use the address of the location from which you are shipping instead. Note that this also determines the company name and address shown on payment quick views.
fulfillment.shipments.billOfLadingFooter.label = Bill of lading footer 
fulfillment.shipments.billOfLadingFooter.explanation = The text to be displayed at the bottom of every bill of lading. 
fulfillment.shipments.defaultCCShipment.label = Default cc for shipments 
fulfillment.shipments.defaultCCShipment.explanation = If an email address is entered, the recipient will be copied on all emailed shipments. 
fulfillment.shipments.showWarrantyOnPackingSlip.label = Show warranty on serial units 
fulfillment.shipments.showWarrantyOnPackingSlip.explanation = Enable this setting to show warranty information next to serial numbers on your shipments.

fulfillment.pickTickets.pickTicketNumber.label = Pick ticket numbering
fulfillment.pickTickets.pickTicketNumber.explanation = ""
fulfillment.pickTickets.pickTicketHeaderText.label = Pick ticket title 
fulfillment.pickTickets.pickTicketHeaderText.explanation = Change this value if a different title (for example, Pick Slip) is desired when printing or emailing pick tickets. The title should not be more than 50 characters. 
fulfillment.pickTickets.pickTicketFooter.label = Pick ticket footer 
fulfillment.pickTickets.pickTicketFooter.explanation = The text to be displayed at the bottom of every pick ticket. 
fulfillment.pickTickets.replyEmailPickTicket.label = Pick ticket Reply-To
fulfillment.pickTickets.replyEmailPickTicket.explanation = The email address where you will receive replies to your pick ticket emails.
fulfillment.pickTickets.pickTicketEmailText.label = Pick ticket message 
fulfillment.pickTickets.pickTicketEmailText.explanation = The body of the email message to be sent with your pick tickets. Do not use HTML. 
fulfillment.pickTickets.pickTicketSerialsLots.label = Serials/lots on pick tickets
fulfillment.pickTickets.pickTicketSerialsLots.explanation = Check this box if you want to specify serial numbers and lot numbers on pick tickets. 
fulfillment.pickTickets.defaultCCPickTicket.label = Default cc for pick tickets 
fulfillment.pickTickets.defaultCCPickTicket.explanation = If an email address is entered, the recipient will be copied on all emailed pick tickets. 

purchasing.general.hideItemName.label = Hide item name
purchasing.general.hideItemName.explanation = Check this box to hide the item name on purchase orders, sales orders, and packing slips. This is often useful in drop shipping.
purchasing.general.searchByAnyPartOfVendor.label = Search any part of vendor name
purchasing.general.searchByAnyPartOfVendor.explanation = Check this option to search for vendors by any part of the name, instead of only what the name starts with.
purchasing.general.defaultExpenseAccount.label = Default expense account
purchasing.general.defaultExpenseAccount.explanation = Default expense account for expense items.
purchasing.general.updateDefaultCostOnPurchase.label = Update default cost
purchasing.general.updateDefaultCostOnPurchase.explanation = Check this box to update the default cost of an item automatically when a PO or item receipt is issued for that item.
purchasing.general.includeWOonReorderReport.label = Include work orders on reorder report
purchasing.general.includeWOonReorderReport.explanation = Check this box to include work order calculations on the Reorder Report.
purchasing.general.showCustomerOnPurchaseForms.label = Enter customer on line items
purchasing.general.showCustomerOnPurchaseForms.explanation = Check this box to allow the customer to be specified on line items of a purchasing form, if you are purchasing an item for a specific customer rather than for general inventory.
purchasing.general.showWeightOnPurchasingForms.label = Show weight on purchasing forms
purchasing.general.showWeightOnPurchasingForms.explanation = Check this box to display the weight of each item, as well as the total weight of all items, on purchasing forms.
purchasing.general.showVolumeOnPurchasingForms.label = Show volume on purchasing forms
purchasing.general.showVolumeOnPurchasingForms.explanation = Check this box to display the volume of each item, as well as the total volume of all items, on purchasing forms.

purchasing.purchaseOrders.purchaseOrderNumber.label = Purchase order numbering
purchasing.purchaseOrders.purchaseOrderNumber.explanation = ""
purchasing.purchaseOrders.defaultCCPO.label = Default cc for purchase orders
purchasing.purchaseOrders.defaultCCPO.explanation = If an email address is entered, the recipient will be copied on all emailed purchase orders.
purchasing.purchaseOrders.defaultLocationForPurchasing.label = Default ship-to location for purchase orders
purchasing.purchaseOrders.defaultLocationForPurchasing.explanation = Specifies the default location to which purchase orders will be shipped.
purchasing.purchaseOrders.requireApprovalPO.label = Require approval for purchase orders
purchasing.purchaseOrders.requireApprovalPO.explanation = If this box is checked, a company administrator must approve each purchase order before it can be emailed.
purchasing.purchaseOrders.replyEmailPO.label = Purchase orders Reply-To
purchasing.purchaseOrders.replyEmailPO.explanation = The email address where you will receive replies to your purchase order emails.
purchasing.purchaseOrders.purchaseOrderEmail.label = Purchase order message
purchasing.purchaseOrders.purchaseOrderEmail.explanation = The body of the email message to be sent with your purchase orders. Do not use HTML.
purchasing.purchaseOrders.purchaseOrderFooter.label = Purchase order footer
purchasing.purchaseOrders.purchaseOrderFooter.explanation = Text to be displayed at the bottom of every purchase order.
purchasing.purchaseOrders.suggestPOQuantity.label = Suggest purchase order quantities
purchasing.purchaseOrders.suggestPOQuantity.explanation = Check this box to have SOS automatically suggest a reorder quantity when an item is entered on a purchase order.
purchasing.purchaseOrders.syncPurchaseOrders.label = Sync purchase orders
purchasing.purchaseOrders.syncPurchaseOrders.explanation = Check this box to synchronize purchase orders with QuickBooks Online.
purchasing.purchaseOrders.journalEntryDropShipPO.label = Automate drop ship purchase orders
purchasing.purchaseOrders.journalEntryDropShipPO.explanation = If this box is checked, SOS closes the purchase order automatically and creates a journal entry in QuickBooks Online to record the payable and cost of goods sold. Uncheck this box to disable this behavior.

purchasing.itemReceipts.itemReceiptNumber.label = Item receipt numbering
purchasing.itemReceipts.itemReceiptNumber.explanation = ""
purchasing.itemReceipts.defaultSyncItemReceipts.label = Default payment method for synced item receipts
purchasing.itemReceipts.defaultSyncItemReceipts.explanation = Specifies the default payment method used when receiving items and syncing item receipts to QuickBooks Online. The method selected can be overridden on any individual item receipt. If None is selected, item receipts will not sync to QuickBooks.

purchasing.returnsToVendors.returnToVendorNumber.label = Returns to vendors numbering
purchasing.returnsToVendors.returnToVendorNumber.explanation = ""

rentals.rentalReturns.rentalReturnNumber.label = Rental return numbering
rentals.rentalReturns.rentalReturnNumber.explanation = ""
rentals.rentals.rentalHeaderText.label = Rental title
rentals.rentals.rentalHeaderText.explanation = Change this value if a different title (for example, Rental Ticket) is desired when printing or emailing rentals. The title should not be more than 50 characters.
rentals.rentals.rentalFooter.label = Rental footer
rentals.rentals.rentalFooter.explanation = Text to be displayed at the bottom of every rental.
rentals.rentals.replyEmailRental.label = Rental Reply-To
rentals.rentals.replyEmailRental.explanation = The email address where you will receive replies to your rental emails.
rentals.rentals.rentalEmailText.label = Rental message
rentals.rentals.rentalEmailText.explanation = The body of the email message to be sent with your rentals. Do not use HTML.

rentals.rentals.rentalNumber.label = Rental numbering
rentals.rentals.rentalNumber.explanation = ""
purchasing.returnToVendors.returnToVendorNumber.label = Returns to vendors numbering

sales.customerPortal.portalEnabled.label = Enabled
sales.customerPortal.portalEnabled.explanation = Check this box to enable the portal for your customers.
sales.customerPortal.portalDomain.label = Portal subdomain
sales.customerPortal.portalDomain.explanation = This is the URL to which you'll direct your customers.
sales.customerPortal.portalDomain.addressLabel = Portal address
sales.customerPortal.portalCompanyName.label = Company name for portal
sales.customerPortal.portalCompanyName.explanation = Enter the company name you want displayed to your customers. Leave this blank to default to the company name you entered in company settings.
sales.customerPortal.portalFooter.label = Footer
sales.customerPortal.portalFooter.explanation = Enter the text to be displayed at the bottom of all portal pages. Typically includes disclaimers, contact info, etc.
sales.customerPortal.portalPasswordOptional.label = Require password
sales.customerPortal.portalPasswordOptional.explanation = If enabled, requires the customer to enter a password to access information in the customer portal.
sales.customerPortal.showAssignedUser.label = Show assigned representative
sales.customerPortal.showAssignedUser.explanation = If enabled, allows the customer to see the name of the person assigned to the order.

sales.general.defaultTaxCode.label = Default tax code
sales.general.defaultTaxCode.explanation = The default tax code if you use the Sales Tax Center in QuickBooks Online. If you do not use the Sales Tax Center, this setting has no effect.
sales.general.retrieveQuantityOnHand.label = Show available for sale on forms
sales.general.retrieveQuantityOnHand.explanation = Show available for sale on forms
sales.general.defaultTaxRate.label = Default tax rate
sales.general.defaultTaxRate.explanation = Default sales tax rate, specified as a percentage. If you have multiple locations with varying tax rates, you can leave this setting blank and specify the rate for each location in the Sales tax rate setting of the Location edit page.
sales.general.defaultDiscountTaxable.label = Discount taxable
sales.general.defaultDiscountTaxable.explanation = Check this box if discounts are taxable. You can override this setting on any sales form.
sales.general.defaultShippingTaxable.label = Shipping is taxable
sales.general.defaultShippingTaxable.explanation = Check this box if shipping costs on sales forms are taxable.
sales.general.defaultPaymentMethod.label = The default payment method for customer payments.
sales.general.defaultPaymentMethod.explanation = The default payment method for customer payments. 
sales.general.defaultTerms.label = Default terms
sales.general.defaultTerms.explanation = Default terms specified in QuickBooks, or in SOS, if not connected to QuickBooks.
sales.general.hideItemName.label = Hide item name
sales.general.hideItemName.explanation = Check this box to hide the item name on purchase orders, sales orders, and packing slips. This is often useful in drop shipping.
sales.general.findCustomersByFullName.label = Find customers by long name
sales.general.findCustomersByFullName.explanation = Check this option to search for customers by their long name, which includes any parent customers, instead of the regular customer name. This is helpful if you use subcustomers.
sales.general.searchByAnyPartOfCustomer.label = Search any part of customer name
sales.general.searchByAnyPartOfCustomer.explanation = Check this option to allow searching for a customer by any part of its name, instead of only the beginning of the name.
sales.general.autoShipInvoices.label = Auto-ship invoices
sales.general.autoShipInvoices.explanation = Check this box to create a shipment automatically for new invoices created in QuickBooks Online.
sales.general.autoShipSalesReceipts.label = Auto-ship sales receipts
sales.general.autoShipSalesReceipts.explanation = Check this box to create a shipment automatically for new sales receipts created in QuickBooks Online.
sales.general.autoBackOrderSalesReceipts.label = Auto-backorder sales receipts
sales.general.autoBackOrderSalesReceipts.explanation = Check this box to create a backorder automatically for new sales receipts created in QuickBooks Online.
sales.general.autoSOForInvoice.label = Auto-sales order invoices
sales.general.autoSOForInvoice.explanation = Check this box to create a sales order automatically for new invoices created in QuickBooks Online. This setting will be ignored if a shipment is automatically created for the invoice.
sales.general.lineItemDiscountsEnabled.label = Enable line-item discounts on sales forms
sales.general.lineItemDiscountsEnabled.explanation = Check this box to display a regular price, discount percentage, and sale price on each line item of your sales forms.
sales.general.marginPricingEnabled.label = Show cost/markup on sales forms
sales.general.marginPricingEnabled.explanation = Check this box to display the markup on each item—and the total cost for that line—on sales forms. The user must also have the View Costs permission or be an administrator.
sales.general.markupOnActualCost.label = Markup on actual cost
sales.general.markupOnActualCost.explanation = Check this box to use actual cost (instead of standard cost) when showing cost/markup on sales forms.
sales.general.showWeightOnSalesForms.label = Show weight on sales forms
sales.general.showWeightOnSalesForms.explanation = Check this box to display the weight of each item, as well as the total weight of all items, on sales forms.
sales.general.showVolumeOnSalesForms.label = Show volume on sales forms
sales.general.showVolumeOnSalesForms.explanation = Check this box to display the volume of each item, as well as the total volume of all items, on sales forms.
sales.general.includeIncomingInAvailableForSale.label = Include incoming in available
sales.general.includeIncomingInAvailableForSale.explanation = Check this box if you want to include inbound goods (e.g., purchase orders, rentals due, etc.) in your available-for-sale calculations. Normally, this setting is enabled only if your incoming goods have very reliable delivery dates. If you change this option, it might take a little time for all your available-for-sale values to be updated.
sales.general.showInStockOnlySOToPT.label = Show only in-stock
sales.general.showInStockOnlySOToPT.explanation = Check this box if you want to show only items in-stock when generating a pick ticket or shipment from a sales order or sales receipt. You can override this option by entering any other items manually.
sales.general.defaultDepositAssetAccount.label = Default deposit asset account
sales.general.defaultDepositAssetAccount.explanation = Default asset account for customer deposits. Most commonly, this is an undeposited funds account.
sales.general.defaultDepositLiabilityAccount.label = Default deposit liability account
sales.general.defaultDepositLiabilityAccount.explanation = Default liability account for customer deposits.

sales.salesOrders.salesOrderNumber.label = Sales order numbering
sales.salesOrders.expandKitsOnSalesOrder.label = Expand kits on sales orders
sales.salesOrders.expandKitsOnSalesOrder.explanation = Check this box to expand kits automatically upon saving a sales order.
sales.salesOrders.salesOrderText.label = Sales order title
sales.salesOrders.salesOrderText.explanation = Change this value if you want a different title when printing or emailing sales orders. For example, some people use Work Order instead. The title should be no more than 50 characters.
sales.salesOrders.replyEmailSO.label = Sales order Reply-To
sales.salesOrders.replyEmailSO.explanation = The email address where you will receive replies to your sales order emails.
sales.salesOrders.salesOrderEmail.label = Sales order message 
sales.salesOrders.salesOrderEmail.explanation = The body of the email message to be sent with your sales orders. Do not use HTML. 
sales.salesOrders.salesOrderFooter.label = Sales order footer 
sales.salesOrders.salesOrderFooter.explanation = Text to be displayed at the bottom of every sales order. 
sales.salesOrders.defaultCCSalesOrder.label = Default cc for sales orders 
sales.salesOrders.defaultCCSalesOrder.explanation = If an email address is entered, the recipient will be copied automatically on all emailed sales orders. 
sales.salesOrders.editLocksEnabled.label = Lock sales orders when editing 
sales.salesOrders.editLocksEnabled.explanation = Check this box to prevent users from editing a sales order that someone else already has open.

sales.estimates.estimateNumber.label = Estimate numbering
sales.estimates.estimateText.label = Estimate title 
sales.estimates.estimateText.explanation = Change this value if you want a different title when printing or emailing estimates. For example, some people use Quote instead. The title should be no more than 50 characters. 
sales.estimates.replyEmailEstimate.label = Estimate Reply-To
sales.estimates.replyEmailEstimate.explanation = The email address where you will receive replies to your estimate emails.
sales.estimates.estimateEmail.label = Estimate message
sales.estimates.estimateEmail.explanation = The body of the email message to be sent with your estimates. Do not use HTML.
sales.estimates.estimateFooter.label = Estimate footer
sales.estimates.estimateFooter.explanation = Text to be displayed at the bottom of every estimate. 
sales.estimates.defaultCCEstimate.label = Default cc for estimates 
sales.estimates.defaultCCEstimate.explanation = If an email address is entered, it will be copied automatically on all emailed estimates. 

sales.invoices.invoiceNumber.label = Invoice numbering
sales.invoices.useQBInvoiceNumber.label = Use QuickBooks invoice numbers 
sales.invoices.useQBInvoiceNumber.explanation = If this setting is enabled, SOS Inventory will leave the invoice number blank when sending an invoice to QuickBooks, and QuickBooks will generate an invoice number.
sales.invoices.replyEmailInvoice.label = Invoice Reply-To
sales.invoices.replyEmailInvoice.explanation = The email address where you will receive replies to your invoice emails.
sales.invoices.invoiceEmail.label = Invoice message
sales.invoices.invoiceEmail.explanation = The body of the email message to be sent with your invoices. Do not use HTML.
sales.invoices.invoiceFooter.label = Invoice footer
sales.invoices.invoiceFooter.explanation = Text to be displayed at the bottom of every invoice.
sales.invoices.defaultCCInvoice.label = Default cc for invoices
sales.invoices.defaultCCInvoice.explanation = If an email address is entered, it will be copied automatically on all emailed invoices.
sales.invoices.useShippingCostFromShipment.label = Use shipping cost from shipment
sales.invoices.useShippingCostFromShipment.explanation = By default, SOS will use the shipping cost specified on the sales order when invoicing. Enable this setting to use the shipping cost from the associated shipment instead.

sales.salesReceipts.salesReceiptNumber.label = Sales receipt numbering
sales.salesReceipts.replyEmailSalesReceipt.label = Sales receipt Reply-To
sales.salesReceipts.replyEmailSalesReceipt.explanation = The email address where you will receive replies to your sales receipt emails.
sales.salesReceipts.salesReceiptEmail.label = Sales receipt message 
sales.salesReceipts.salesReceiptEmail.explanation = The body of the email message to be sent with your sales receipts. Do not use HTML.
sales.salesReceipts.salesReceiptFooter.label = Sales receipt footer
sales.salesReceipts.salesReceiptFooter.explanation = Text to be displayed at the bottom of every sales receipt.
sales.salesReceipts.defaultCCSalesReceipt.label = Default cc for sales receipts
sales.salesReceipts.defaultCCSalesReceipt.explanation = If an email address is entered, it will be copied automatically on all emailed sales receipts
sales.salesReceipts.includeSalesReceiptsInAvailableForSale.label = Include sales receipts in available
sales.salesReceipts.includeSalesReceiptsInAvailableForSale.explanation = Check this box if you want to include sales receipts in your available-for-sale calculations. If this option is turned off, only sales orders are included. If you change this option, it might take some time for all of your available-for-sale values to be updated.

sales.rmas.rmaNumber.label = RMA numbering
sales.rmas.rmaFooter.label = RMA footer
sales.rmas.rmaFooter.explanation = The text to be displayed at the bottom of every return merchandise authorization.
sales.rmas.replyEmailRMA.label = RMA Reply-To
sales.rmas.replyEmailRMA.explanation = The email address where you will receive replies to your RMA emails.
sales.rmas.rmaEmailText.label = RMA message
sales.rmas.rmaEmailText.explanation = The body of the email message to be sent with your RMAs. Do not use HTML.

sales.returns.returnNumber.label = Return numbering

customerPortal.general.PortalEnabled.label = Enabled
customerPortal.general.PortalEnabled.explanation = Check this box to enable the portal for your customers.
customerPortal.general.PortalDomain.label = Portal subdomain
customerPortal.general.PortalDomain.explanation = This is the URL to which you'll direct your customers.
customerPortal.general.PortalDomain.addressLabel = Portal address
customerPortal.general.PortalCompanyName.label = Company name for portal
customerPortal.general.PortalCompanyName.explanation = Enter the company name you want displayed to your customers. Leave this blank to default to the company name you entered in company settings.
customerPortal.general.PortalFooter.label = Footer
customerPortal.general.PortalFooter.explanation = Enter the text to be displayed at the bottom of all portal pages. Typically includes disclaimers, contact info, etc.
customerPortal.general.PortalPasswordOptional.label = Require password
customerPortal.general.PortalPasswordOptional.explanation = If enabled, requires the customer to enter a password to access information in the customer portal.
customerPortal.general.showAssignedUser.label = Show assigned representative
customerPortal.general.showAssignedUser.explanation = If enabled, allows the customer to see the name of the person assigned to the order.

user.general.dateFormat.label = Date format
user.general.timeZone.label = Time zone
user.general.timeZone.explanation = Select the current time at your location. If your local time (relative to GMT) changes throughout the year, might need to adjust this setting again. Alternating between Standard Time and Daylight Savings Time is one possible example, depending on your locale.
user.general.notificationMethod.label = Notification method
user.general.notificationMethod.explanation = In addition to the notifications sent to you within the SOS Inventory app, you may opt in to be notified via email, text, both—or opt out of receiving email and text message notifications. Messaging fees may apply, so check with your mobile carrier if you are unsure.
user.general.name.label = Display name
user.general.name.explanation = The name you want displayed on your User Menu.
user.general.email.label = Email
user.general.confirmEmail.label = Confirm email
user.general.email.explanation = The user email address tied to your SOS Inventory account.
user.general.passwordQuestion.label = Security question
user.general.passwordQuestion.explanation = Select a security question in case you need to reset your SOS password.
user.general.passwordAnswer.label = 
user.general.passwordAnswer.explanation = 
user.general.passwordAnswer.label = Security question answer
user.general.passwordAnswer.explanation = SOS will use the answer you provide to help confirm your identity should you need to reset your password.
user.general.smsEnabled.label = Receive notifications via SMS
user.general.smsEnabled.explanation = Check this box to receive text message notifications from SOS. You will need to select your mobile carrier and enter your mobile number.
user.general.mobileCarrier.label = Mobile carrier
user.general.mobileNumber.label = Mobile number

user.general.dateFormat.M/d/yyyy = M/d/yyyy
user.general.dateFormat.dd/MM/yyyy = dd/MM/yyyy

user.general.notificatioMethod.None = Don't email or text me
user.general.notificatioMethod.Email = Email me
user.general.notificatioMethod.Text = Text me
user.general.notificatioMethod.EmailText = Email me and text me

user.general.passwordQuestion.SchoolName = What was the name of your elementary / primary school?
user.general.passwordQuestion.HouseStreetNumber = What is the street number of the house you grew up in?
user.general.passwordQuestion.CityWhereParentsMet = In what city or town did your mother and father meet?
user.general.passwordQuestion.FirstCarColor = What was the color of your first car?
user.general.passwordQuestion.ChildhoodFriendName = What is the name of your favorite childhood friend?
user.general.passwordQuestion.FirstJobCity = In what city was your first job?

user.general.mobileCarrier.ATT = AT&T
user.general.mobileCarrier.AU = Australia (any)
user.general.mobileCarrier.Orange = Orange
user.general.mobileCarrier.Sprint = Sprint
user.general.mobileCarrier.Telus = Telus
user.general.mobileCarrier.TMobile = T-Mobile
user.general.mobileCarrier.Verizon = Verizon

connections.quickbooks.neverConnectQBO.label = Never connect
connections.quickbooks.neverConnectQBO.explanation = Check this box if you don't ever intend to connect to QuickBooks Online. This hides several menu options to make the user experience a little better. You can change this setting at any time. Best used by companies that use SOS Inventory as a stand-alone product.
connections.quickbooks.autoSync.label = Automatic sync
connections.quickbooks.autoSync.explanation = When enabled, SOS Inventory will automatically sync with QuickBooks Online according to the schedule you specify. Choose Never if you want to do all synchronizations manually.
connections.quickbooks.postCOGStoQB.label = Post COGS to QuickBooks
connections.quickbooks.postCOGStoQB.explanation = Uncheck this box if you do not want SOS to post cost of goods sold to Quickbooks. This option is enabled by default, and we recommend that you leave it enabled. However, manual control over COGS postings might be desired in certain situations—for example, if postings are being duplicated due to QuickBooks Online Inventory tracking. Check with your accountant and/or SOS Support if you are unsure.
connections.quickbooks.classTrackingEnabled.label = Class tracking
connections.quickbooks.classTrackingEnabled.explanation = Check the box to enable class tracking, which allows grouping transactions by items within the same class for reporting purposes. If selected on transactions, the classes will sync with QuickBooks Online.
connections.quickbooks.departmentTrackingEnabled.label = Department tracking
connections.quickbooks.departmentTrackingEnabled.explanation = Check the box to enable department tracking, which allows grouping transactions for reporting purposes. If selected on transactions, the departments will sync with QuickBooks Online.
connections.quickbooks.syncSOStoQBO.label = Sync SOS Inventory to QuickBooks
connections.quickbooks.syncSOStoQBO.explanation = Controls the information flow when synchronizing. 
connections.quickbooks.syncQBOtoSOS.label =  Sync QuickBooks to SOS Inventory
connections.quickbooks.syncQBOtoSOS.explanation = Controls the information flow when synchronizing. 

autoSyncOptions.Hourly = Hourly
autoSyncOptions.Daily = Daily
autoSyncOptions.Weekly = Weekly
autoSyncOptions.Never = Never

`.trim();
