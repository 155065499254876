import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, TextField, Typography, Box } from "@mui/material";

import { Tooltip } from "components/Tooltip";
import { WideSelectPopper } from "components/WideSelectPopper";
import {
  textFieldStyle,
  inputPropStyles,
  outlinedStyles,
  clearIndicatorStyles,
} from "components/formFields/lineItemFieldStyle";
import Truncate from "components/utility/Truncate";

import { i18n } from "services/i18nService";
import { getItemByNameBarcodeSku } from "services/sosInventoryService/sosApi";
import { checkForUnexpectedProps } from "services/utility/misc";

const ADD_TEXT = i18n("global.AddNewIndicator") + " item";

export function ItemSelect(props) {
  const {
    line,
    onValueChange,
    value, // {id, name, description}
    label,
    options: initialOptions,
    addItem,
    onInputChange,
    onBlur: externalBlur,
    fetchItemsOnChange,
    disabled,
    error,
    ...unexpected
  } = props;
  checkForUnexpectedProps("ItemSelect", unexpected);

  const [userInput, setUserInput] = useState("");
  const [currentValue, setCurrentValue] = useState(value);

  const searchByAnyPartOfItem = useSelector(
    (state) => state.userCompanySettings.settings.searchByAnyPartOfItem
  );

  useEffect(() => setCurrentValue(value), [value]);

  // add "Add new item" to the list of options...
  const options = initialOptions ? [...initialOptions] : [];
  if (addItem) {
    options.unshift({
      id: "add",
      name: (
        <Typography
          color="primary"
          sx={{ fontWeight: "fontWeightMedium" }}
          data-testing="quickAddItemOption"
        >
          {ADD_TEXT}
        </Typography>
      ),
      description: "",
    });
  }

  // ...and a "blank" item so that the component can always have something
  // to select
  options.unshift({ id: null, name: null });

  // this gets called whenever the user changes the selected option
  function onChange(_, newValue, reason) {
    if (reason === "clear") {
      setCurrentValue({ id: null, name: null, description: null });
      setUserInput("");
      onValueChange(null);
      return;
    }
    if (newValue.id === "add") {
      newValue.name = "";
    }
    setCurrentValue(newValue);
    onValueChange(newValue, userInput);
  }

  async function onBlur(e) {
    externalBlur && externalBlur();
    if (
      // no option has been selected...
      !currentValue?.id
    ) {
      // ...look up by name, barcode, and SKU (all must be exact
      // matches)
      const items = await getItemByNameBarcodeSku(e.target.value);
      if (items.length === 1) {
        const item = items[0];
        onValueChange(item);

        // if item not already in options, add it
        const foundItem = options.find(({ id }) => id === item.id);
        if (!foundItem) {
          addItem({
            id: item.id,
            name: item.name,
            description: item.description,
          });
        }
      }
    }
  }

  // this gets called when the component needs to know what to display
  // in the input field; not the value, which is the item id, but the
  // human-friendly *name* of the item
  function getOptionLabel(option) {
    // option or option.id can be blank on a newly inserted line;
    // and if we're adding a new item we don't want any text in the
    // component
    if (!Boolean(option?.id) || option.id === "add") {
      return "";
    }
    if (currentValue.name) {
      return currentValue.name;
    }
    const selectedOption = options.find(({ id }) => id === option.id);
    return selectedOption ? selectedOption.name : "";
  }

  // this gets called for each option, when the selected option changes, to
  // determine which, of all the options, was selected
  function isOptionEqualToValue(option, value) {
    return option.id === value.id || (value === "" && option.id === "");
  }

  // this gets called when the user changes what is in the input field; it
  // should return an array of all the options that meet whatever criteria
  // we want to use; in the current case, we're just looking for a simple
  // substring match
  function filterOptions(options, state) {
    return options.filter(({ id, name, description }) => {
      if (id === null) {
        return false;
      }
      // if we're making API calls for queries, the list of options will
      // always be matches, so no need to check; however, if there's a value
      // already selected, skip this and match against that value's name,
      // below
      if (fetchItemsOnChange && !currentValue.name) {
        return true;
      }

      if (id === "add") {
        return true;
      }

      // when focusing back to this field, with a value already selected,
      // state.inputValue will be null (the user hasn't typed anything new
      // here); so use the currentValue instead; if no current value, set
      // the value to match against to ""
      const matchValue = state.inputValue
        ? state.inputValue
        : currentValue.name || "";

      if (searchByAnyPartOfItem) {
        return `${name} ${description}`
          .toLowerCase()
          .includes(matchValue.toLowerCase());
      }

      return (
        name.toLowerCase().startsWith(matchValue.toLowerCase()) ||
        description?.toLowerCase()?.includes(matchValue.toLowerCase())
      );
    });
  }

  function renderOption(props, option) {
    return (
      <li {...props} data-testing="selectOption" key={option.id}>
        <div style={{ display: "flex", width: "100%" }}>
          <div style={{ minHeight: "1.2em", width: "45%" }}>
            {option.name}
            {option.sku && (
              <Box sx={{ color: "secondary.main" }}>{option.sku}</Box>
            )}
          </div>
          <div style={{ width: "10%" }} />
          <div style={{ width: "45%" }}>
            <Truncate lines={3}>{option.description}</Truncate>
          </div>
        </div>
      </li>
    );
  }

  function renderInput(params) {
    return (
      <Tooltip title={currentValue?.name} placement="bottom-start">
        <TextField
          {...params}
          disabled={disabled}
          variant="outlined"
          label={label}
          margin="dense"
          sx={{ ...textFieldStyle, ...outlinedStyles }}
          onChange={(e) => setUserInput(e.target.value)}
          InputProps={{
            ...params.InputProps,
            sx: inputPropStyles,
            style: { paddingRight: "25px" },
          }}
          InputLabelProps={{ shrink: true }}
          error={error}
        />
      </Tooltip>
    );
  }

  const isLoading = !Array.isArray(initialOptions);

  return (
    <Autocomplete
      renderOption={renderOption}
      isOptionEqualToValue={isOptionEqualToValue}
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      onChange={onChange}
      renderInput={renderInput}
      PopperComponent={WideSelectPopper}
      options={isLoading ? [] : options}
      onBlur={onBlur}
      value={currentValue}
      loading={isLoading}
      disableListWrap={true}
      onInputChange={onInputChange}
      fullWidth
      data-testing="itemSelect"
      popupIcon={<KeyboardArrowDownIcon sx={{ color: "selectIcon" }} />}
      sx={clearIndicatorStyles}
    />
  );
}
