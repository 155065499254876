import { Loading } from "classes/Loading";

import { ActionButton } from "components/ActionButton";
import { LineItemTextField } from "components/formFields/LineItemTextField";
import { LineSkeleton } from "components/formFields/LineSkeleton";
import {
  LineTableCell,
  LineTableHeadingCell,
} from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";
import { afterTouchLine } from "services/sosInventoryService/adjustment/afterTouchLine";
import { calculateAdjustmentCostBasis } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";
import { getUomConversionFromUomReference } from "services/utility/uoms";

import { LINE_ITEM_METADATA } from "editConfig";

export function LineCostAdjustmentHeading(props) {
  const { lines, date, lineHandler } = props;

  async function compute() {
    // Go through lines and set valueDiff to Loading
    const newLines = lines.map((line) =>
      line.item.id ? { ...line, valueDiff: new Loading() } : line
    );
    lineHandler({ type: "set", lines: newLines });

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const {
        quantityDiff,
        relatedRecords: { item },
      } = line;
      if (item?.id) {
        const conversion = getUomConversionFromUomReference(
          line.uom,
          item.uoms
        );
        const { costBasis } = await calculateAdjustmentCostBasis(
          item?.id,
          quantityDiff.times(conversion),
          date
        );
        const newLine = { ...line, valueDiff: costBasis };
        setPageDirty();
        lineHandler({
          type: "update",
          updatedLine: afterTouchLine(newLine, "valueDiff"),
        });
      }
    }
  }

  return (
    <LineTableHeadingCell>
      {i18n("columns.AdjustCostBasisBy")}
      <ActionButton
        dataTesting="compute"
        size="small"
        headerButton
        onClick={compute}
      >
        {i18n("global.Compute")}
      </ActionButton>
    </LineTableHeadingCell>
  );
}

export function LineCostAdjustment(props) {
  const { value, onValueChange, objectType, error } = props;
  const isLoading = value instanceof Loading;

  return (
    <LineTableCell sx={{ minWidth: "6rem" }}>
      {isLoading ? (
        <LineSkeleton />
      ) : (
        <LineItemTextField
          money
          name="valueDiff"
          value={value}
          onValueChange={onValueChange}
          metadata={LINE_ITEM_METADATA[objectType].valueDiff}
          error={error}
          dataTesting="lineCostAdjustment"
        />
      )}
    </LineTableCell>
  );
}
