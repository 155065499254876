// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import {
  extractName,
  formatCompanyInfo,
  formatMoneyWithAdornments,
  formatDocumentInfo,
  formatNumberLinkField,
  formatSync,
  truncate,
  formatBooleanToYesOrBlank,
  extractLinkedTransactions,
  extractDescription,
  formatQuantityWithUom,
  formatMoney,
  formatLinkableItem,
  calculatePaymentAmount,
  formatQuickViewTotal,
} from "services/utility/formatting";
import {
  referenceTypeEditStrFunction,
  arrayOfReferencesTypeEditStrFunction,
  inventoryItemTypeEditStrFunction,
  textTypeEditStrFunction,
  linkedTransactionTypeEditStrFunction,
  numberTypeEditStrFunction,
  decimalTypeEditStrFunction,
} from "services/utility/list";
import { parseHTML } from "services/utility/parsing";
import { TYPES } from "services/utility/schema";
import { starColumn } from "services/utility/schema";

import { NO_REF_NUMBER_STRING } from "appConstants";
import {
  DEFAULT_DECIMALS_UNROUNDED,
  DEFAULT_DECIMALS_ROUNDED,
} from "appConstants";

export const columnInfo = [
  starColumn,
  {
    name: "number",
    fixed: true,
    heading: i18n("columns.ReferenceNumber"),
    formatFunc: formatNumberLinkField,
    minWidth: "7em",
    linkField: true,
    sortable: true,
  },
  {
    name: "customer",
    heading: i18n("columns.Customer"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "date",
    heading: i18n("columns.InvoiceDate"),
    defaultSort: true,
    sortDirection: "asc",
    formatFunc: formatDate,
    sortable: true,
    default: true,
  },
  {
    name: "dueDate",
    heading: i18n("columns.DueDate"),
    formatFunc: formatDate,
    sortable: true,
    default: true,
  },
  {
    name: "terms",
    heading: i18n("columns.Terms"),
    formatFunc: extractName,
    sortable: true,
    default: true,
  },
  {
    name: "balance",
    heading: i18n("columns.Balance"),
    formatFunc: formatMoneyWithAdornments,
    align: "right",
    sortable: true,
    default: true,
  },
  {
    name: "comment",
    heading: i18n("columns.Comment"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "shipping",
    heading: i18n("columns.ShipTo"),
    formatFunc: formatCompanyInfo,
    minWidth: "10em",
    maxWidth: "15em",
  },
  {
    name: "salesRep",
    heading: i18n("columns.SalesRep"),
    formatFunc: extractName,
    minWidth: "8em",
    sortable: true,
  },
  {
    name: "customerPO",
    heading: i18n("columns.CustomerPO"),
    sortable: true,
  },
  {
    name: "total",
    heading: i18n("columns.TotalAmt"),
    formatFunc: formatMoneyWithAdornments,
    align: "right",
    minWidth: "10em",
    sortable: true,
  },
  {
    name: "status",
    fieldName: "status",
    heading: i18n("columns.Status"),
    sortable: true,
  },
  {
    name: "channel",
    heading: i18n("columns.Channel"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "sync",
    fieldName: "syncMessage",
    heading: i18n("columns.Sync"),
    formatFunc: formatSync,
    sortable: true,
  },

  {
    name: "trackingNumber",
    heading: i18n("columns.TrackingNumber"),
  },
  {
    name: "department",
    heading: i18n("columns.Department"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "depositAmount",
    heading: i18n("columns.Deposit"),
    formatFunc: formatMoneyWithAdornments,
    align: "right",
  },
  {
    name: "customerNotes",
    heading: i18n("columns.CustomerNotes"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
  },
  {
    name: "billing",
    heading: i18n("columns.Billing"),
    formatFunc: formatCompanyInfo,
  },
  {
    name: "customerMessage",
    heading: i18n("columns.CustomerMessage"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
  },
  {
    name: "hasSignature",
    heading: i18n("columns.Signature"),
    formatFunc: formatBooleanToYesOrBlank,
  },
  {
    name: "linkedPayments",
    heading: i18n("columns.Payments"),
    formatFunc: extractLinkedTransactions,
    minWidth: "10em",
  },
];

export function getEmptyRecord(settings) {
  return {
    archived: false,
    balance: new Money(0),
    billing: {
      company: null,
      contact: null,
      phone: null,
      email: null,
      addressName: null,
      addressType: null,
      address: {
        line1: null,
        line2: null,
        line3: null,
        line4: null,
        line5: null,
        city: null,
        stateProvince: null,
        postalCode: null,
        country: null,
      },
    },
    channel: null,
    comment: null,
    currency: settings.homeCurrencyRef,
    customer: null,
    customerMessage: null,
    customerNotes: null,
    customerPO: null,
    customFields: null,
    date: new Date(),
    dateChanged: false,
    department: null,
    depositAmount: new Money(0),
    depositPercent: new Decimal(0),
    discountAmount: new Money(0),
    discountPercent: new Decimal(0),
    discountTaxable: settings.discountTaxable,
    documents: [],
    dueDate: null,
    exchangeRate: new Decimal(1),
    hasSignature: false,
    keys: null,
    lastSync: "",
    lines: [],
    linkedTransaction: null,
    number: "",
    originalDate: "",
    qboTransactionId: "",
    salesRep: null,
    shipDate: null,
    shipping: {
      company: null,
      contact: null,
      phone: null,
      email: null,
      addressName: null,
      addressType: null,
      address: {
        line1: null,
        line2: null,
        line3: null,
        line4: null,
        line5: null,
        city: null,
        stateProvince: null,
        postalCode: null,
        country: null,
      },
    },
    shippingAmount: new Money(0),
    shippingMethod: null,
    shippingTaxable: false,
    starred: 0,
    status: "Open",
    subTotal: new Money(0),
    summaryOnly: false,
    syncMessage: null,
    syncToken: 0,
    taxAmount: new Money(0),
    taxCode: settings.defaultTaxCode,
    taxPercent: new Decimal(0),
    terms: null,
    total: new Money(0),
    trackingNumber: null,
    transactionLocationQuickBooks: null,
    values: null,
    voided: false,
  };
}

export const LINE_ITEM_METADATA = {
  lineNumber: {},
  item: {
    type: TYPES.reference,
    editStr: inventoryItemTypeEditStrFunction,
    initialValue: { id: null, name: null, description: null },
  },
  class: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  bin: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  jobWorkcenter: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  taxCode: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  description: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  quantity: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weight: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: numberTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weightunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  volume: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  volumeunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  unitprice: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  amount: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_ROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  altAmount: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  cost: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  uom: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  serials: {
    type: TYPES.arrayOfReferences,
    editStr: arrayOfReferencesTypeEditStrFunction,
    initialValue: null,
  },
  lot: {
    type: TYPES.reference,
    editStr: textTypeEditStrFunction,
  },
  linkedTransaction: {
    type: TYPES.linkedTransaction,
    editStr: linkedTransactionTypeEditStrFunction,
  },
  linkedTransactionRefNumber: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  linkedTransactionLineNumber: {
    type: TYPES.number,
    editStr: numberTypeEditStrFunction,
  },
  picked: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  shipped: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  invoiced: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  produced: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  returned: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  listPrice: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  margin: {
    type: TYPES.decimal,
    currency: true,
    placesRight: DEFAULT_DECIMALS_ROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  percentdiscount: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_ROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  available: {
    placesRight: DEFAULT_DECIMALS_ROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  itemDetails: { initialValue: {} },
  relatedRecords: { initialValue: {} },
};

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [
    "exchangeRate",
    "depositPercent",
    "discountPercent",
    "taxPercent",
  ],
  MONEY_FIELDS: [
    "balance",
    "depositAmount",
    "discountAmount",
    "taxAmount",
    "total",
    "shippingAmount",
    "subTotal",
  ],
  DATE_FIELDS: ["dueDate", "shipDate"],
  DATETIME_FIELDS: ["lastSync"],
  NON_UTC_DATETIME_FIELDS: ["date"],
};

export const REFERENCE_FIELDS = ["customer"];

export const LINE_ACTIONS = [
  ["quickView", "pdf", "email"],
  "shipment",
  "salesOrder",
  ["purchaseOrder", "dropShip"],
  "template",
  ["RMA", "return"],
  "rental",
  "payment",
  "archive",
  "delete",
  "history",
  "sync",
  "viewInQuickBooks",
];
export const BATCH_ACTIONS = [
  ["pdf", "email"],
  "delete",
  "archive",
  "merge",
  "sync",
];

export const ITEM_CALCULATED_FIELDS = [
  "available",
  "salesPrice",
  "purchaseCost",
];

export const QUICK_VIEW_CONFIG = {
  footerSetting: "invoiceFooter",
  isSalesTransaction: true,
  addressConfig: [
    { name: "billing", label: i18n("address.BillTo") },
    { name: "shipping", label: i18n("address.ShipTo") },
  ],
  tableConfig: [{ type: "lines" }],
  headerFields: [
    {
      name: "date",
      formatFunc: formatDate,
      label: i18n("columns.Date"),
    },
    {
      name: "number",
      label: i18n("frmLabel.InvoiceNumber"),
      defaultValue: NO_REF_NUMBER_STRING,
    },
    {
      name: "terms",
      formatFunc: extractName,
      label: i18n("frmLabel.Terms"),
    },
    {
      name: "dueDate",
      formatFunc: formatDate,
      label: i18n("columns.DueDate"),
    },
  ],
  fields: [
    {
      name: "customerPO",
      label: i18n("frmLabel.CustomerPO"),
    },
    {
      name: "customerMessage",
      label: i18n("frmLabel.CustomerMessage"),
    },
  ],
  lines: [
    {
      name: "item",
      formatFunc: formatLinkableItem,
      heading: i18n("columns.Item"),
      align: "left",
      isItemField: true,
    },
    {
      name: "description",
      formatFunc: extractDescription,
      heading: i18n("columns.Description"),
      align: "left",
    },
    {
      name: "quantity",
      formatFunc: formatQuantityWithUom,
      heading: i18n("columns.Quantity"),
      align: "right",
    },
    {
      name: "unitprice",
      formatFunc: formatMoney,
      heading: i18n("columns.Rate"),
      align: "right",
    },
    {
      name: "amount",
      formatFunc: formatMoney,
      heading: i18n("columns.Amount"),
      align: "right",
      isTotalColumn: true,
    },
  ],
  tableTotals: [
    {
      label: i18n("quickView.SUBTOTAL"),
      formatFunc: formatMoneyWithAdornments,
      field: "subTotal",
    },
    {
      label: i18n("quickView.DISCOUNT"),
      formatFunc: formatMoneyWithAdornments,
      field: "discountAmount",
    },
    {
      label: i18n("quickView.SHIPPING"),
      formatFunc: formatMoneyWithAdornments,
      field: "shippingAmount",
    },
    {
      label: i18n("quickView.TAX"),
      formatFunc: formatMoneyWithAdornments,
      field: "taxAmount",
    },
    {
      label: i18n("quickView.TOTAL"),
      formatFunc: formatQuickViewTotal,
      alwaysShow: true,
    },
    {
      label: i18n("quickView.PAYMENTS"),
      formatFunc: calculatePaymentAmount,
      field: "balance",
      alwaysShow: true,
    },
    {
      label: i18n("quickView.BALANCEDUE"),
      formatFunc: formatMoneyWithAdornments,
      field: "balance",
      alwaysShow: true,
    },
  ],
};
