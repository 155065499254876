export function downloadDocument(documentBlob, downloadText) {
  const a = document.createElement("a");
  a.style.display = "none";
  document.body.appendChild(a);
  a.href = window.URL.createObjectURL(documentBlob);
  a.setAttribute("download", downloadText);
  a.click();
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
}
