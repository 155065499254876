import { useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";

import { Layout } from "Layout";

import { ListPage } from "components/ListPage/ListPage";

import { i18n } from "services/i18nService";

import { useEditModal } from "hooks/useEditModal";
import { usePrivileges } from "hooks/usePrivileges";

import { Forbidden } from "Forbidden";
import { USER_PRIVILEGE, OBJECT_TYPES } from "appConstants";

const objectType = OBJECT_TYPES.PAYMENT.fullString;

export function PaymentList() {
  const { id } = useParams();
  const { hasPrivilegesOrIsAdmin } = usePrivileges();
  useEditModal(objectType, id);

  const allowList = hasPrivilegesOrIsAdmin([USER_PRIVILEGE.processPayment]);

  const paymentsBoarded = useSelector(
    (state) => state.userCompanySettings.settings.paymentsBoarded
  );

  // if the company is not signed up for payments, we don't show them the
  // payments list view; rather we take them to a promo view for payments
  if (!paymentsBoarded) {
    return <Navigate to="/sospayments" />;
  }

  return (
    <Layout pageTitle={i18n(`objectType.${objectType}.SentencePlural`)}>
      {allowList ? (
        <ListPage objectType={objectType} identifierProperty="number" />
      ) : (
        <Forbidden />
      )}
    </Layout>
  );
}
