// Actual domain logic should be in a domainLogic.js file, at the appropriate
// level in the hierarchy.
import { ITEM_CALCULATED_FIELDS } from "appConfig";
import { IN_TRANSACTION } from "appConfig";

import { Decimal, Money } from "classes/DecimalClasses";

import { updateLineRelatedRecordsItem } from "services/sosInventoryService/domainLogic";
import { getEmptyRecord } from "services/sosInventoryService/purchaseOrder/schema";
import {
  getRecord,
  getRecordFrom,
  getExchangeRate,
} from "services/sosInventoryService/sosApi";
import {
  copyCustomFieldValues,
  reconcileCustomFields,
  getCustomFieldDefinitions,
} from "services/utility/customFields";
import { getRelatedReferenceObjects } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";
import { formatContact } from "services/utility/formatting";

import globalState from "globalState/globalState";

import { OBJECT_TYPES, DEFAULT_DECIMALS_ROUNDED } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.PURCHASE_ORDER.fullString;

export async function getTransaction(
  id,
  newFromId,
  newFromObjectType,
  updaters,
  customFieldDefs,
  dropShip
) {
  let record, lines, relatedRecords;

  if (id || newFromObjectType) {
    if (id) {
      record = await getRecord(OBJECT_TYPE, id);
      relatedRecords = await getRelatedReferenceObjects(OBJECT_TYPE, record);
    } else {
      // it's a "populate from" request
      record = await getRecordFrom(
        OBJECT_TYPE,
        newFromObjectType,
        newFromId,
        dropShip
      );
      delete record.id;
      relatedRecords = await getRelatedReferenceObjects(OBJECT_TYPE, record);

      if (!dropShip) {
        record.shipping = relatedRecords.location;
      }
    }

    record.customFields = reconcileCustomFields(
      customFieldDefs,
      record.customFields
    );

    lines = record.lines;
    delete record.lines;

    updaters.lineHandler({ type: "set", lines });
    updaters.setRecord(record);
    updaters.setRelatedRecords(relatedRecords);

    // get items for each line item, so that we have updated item info for
    // the calculations below
    lines = await updateLineRelatedRecordsItem(
      record.location,
      record.date,
      lines,
      ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
    );
  } else {
    // new record
    const { settings } = globalState.getState().userCompanySettings;
    const defaultLocation =
      globalState.getState().userCompanySettings.settings.defaultLocation;
    const numLinesToAdd =
      globalState.getState().userCompanySettings.settings.numLinesToAdd;
    const defaultLocationForPurchasing =
      globalState.getState().userCompanySettings.settings
        .defaultLocationForPurchasing;

    record = {
      ...getEmptyRecord(settings),
      customFields: reconcileCustomFields(customFieldDefs, []),
    };

    lines = Array(numLinesToAdd).fill(EMPTY_LINE_ITEM[OBJECT_TYPE]);

    relatedRecords = {};

    if (defaultLocation) {
      const txnLocation =
        defaultLocationForPurchasing || defaultLocation || null;
      record.location = txnLocation;

      const location = await getRecord(
        "location",
        txnLocation.id,
        IN_TRANSACTION
      );
      record.shipping = location;
      relatedRecords = { ...relatedRecords, location };
    }
    updaters.setRelatedRecords(relatedRecords);
    updaters.setRecord(record);
  }
  updaters.lineHandler({ type: "set", lines });
}

export async function updateTransaction(
  field,
  newValue,
  transactionCustomFieldDefs,
  currentState,
  updaters,
  currencies
) {
  const { record, lines, relatedRecords, totals } = currentState;
  if (!record || !lines || !relatedRecords) {
    handleProgramError(new Error("update Transaction | invalid currentState"));
  }

  let newRecord, newLines, newOtherCosts, newRelatedRecords;

  switch (field) {
    case "assignedToUser":
    case "billing":
    case "blanketPO":
    case "comment":
    case "contractManufacturing":
    case "customFields":
    case "date":
    case "department":
    case "dropShip":
    case "exchangeRate":
    case "expectedDate":
    case "expectedShip":
    case "number":
    case "shipping":
    case "shippingMethod":
    case "terms":
    case "trackingNumber":
    case "updateDefaultCosts":
    case "vendorNotes":
    case "vendorMessage":
      updaters.setRecord((prev) => ({ ...prev, [field]: newValue }));
      break;

    case "depositPercent":
      const depositPercent = new Decimal(newValue || 0);
      newRecord = {
        ...record,
        depositPercent,
        depositAmount: totals.total
          .times(depositPercent)
          .times(new Decimal(0.01)),
      };
      updaters.setRecord(newRecord);
      break;

    case "depositAmount":
      const depositAmount = new Money(newValue || 0);
      newRecord = {
        ...record,
        depositAmount,
        depositPercent: depositAmount
          .div(totals.total)
          .times(new Decimal(100))
          .round(DEFAULT_DECIMALS_ROUNDED, Decimal.roundDown),
      };
      updaters.setRecord(newRecord);
      break;

    case "location":
      const location = newValue
        ? await getRecord("location", newValue?.id)
        : null;
      newRecord = { ...record, location, shipping: location };
      updaters.setRecord(newRecord);

      break;

    case "customer":
      const customer = newValue
        ? await getRecord("customer", newValue?.id)
        : null;
      const { shipping, name, contact, email, phone, companyName } =
        customer || {};
      newRecord = {
        ...record,
        customer,
        shipping: {
          address: shipping,
          contact: formatContact(contact, name),
          company: companyName,
          email,
          phone,
        },
      };
      updaters.setRecord(newRecord);

      break;
    case "payment":
      newRecord = {
        ...record,
        [field]: newValue?.id ? { id: newValue.id, name: newValue.name } : null,
      };
      updaters.setRecord(newRecord);

      break;

    case "vendor":
      // if the field is empty, null the field
      if (!newValue) {
        newRecord = { ...record, vendor: null };
        updaters.setRecord(newRecord);
        newRelatedRecords = { ...relatedRecords, vendor: null };
        break;
      }

      const vendorCustomFieldDefs = await getCustomFieldDefinitions("vendor");

      try {
        // get the newly selected vendor record, so that we can...
        const vendor = await getRecord("vendor", newValue.id, IN_TRANSACTION);
        // ...see if the vendor has a currency setting; if so, get the
        // exchange rate for that currency
        let newCurrency = null;
        let newExchangeRate = null;

        if (vendor?.currency) {
          const response = await getExchangeRate(
            vendor.currency.name,
            record.date
          );
          if (response) {
            newCurrency = vendor.currency;
            newExchangeRate = response.exchangeRate;
          }
        }

        // be sure there are custom field entries for each defined custom field
        // in the vendor record...
        const vendorCustomFields = reconcileCustomFields(
          vendorCustomFieldDefs,
          vendor.customFields
        );

        const newTransactionCustomFields = copyCustomFieldValues(
          vendorCustomFieldDefs,
          vendorCustomFields,
          transactionCustomFieldDefs,
          record.customFields
        );

        // ...then initialize any transaction custom fields to their matching vendor
        // custom field values, if any
        const { address, contact, name, companyName, phone, email } =
          vendor || {};
        const billing = {
            address,
            contact: formatContact(contact, name),
            company: companyName,
            phone,
            email,
          },
          newRecord = {
            ...record,
            vendor: { id: newValue.id },
            currency: newCurrency ? newCurrency : record.currency,
            exchangeRate: newExchangeRate
              ? newExchangeRate
              : record.exchangeRate,
            vendorNotes: vendor.notes,
            taxCode: vendor.taxCode,
            terms: vendor.terms,
            customFields: newTransactionCustomFields,
            billing,
          };
        updaters.setRecord(newRecord);
        newRelatedRecords = { ...relatedRecords, vendor };
      } catch (e) {
        handleProgramError(e);
      }
      break;

    case "currency":
      const { id: newId, name: newName } = newValue || {};
      updaters.setRecord((prev) => ({
        ...prev,
        currency: newId ? { id: newId, name: newName } : null,
        exchangeRate: newId
          ? currencies.find(({ id }) => id === newId).exchangeRate
          : null,
      }));
      break;

    default:
      handleProgramError(
        new Error(`updateTransaction | unknown field (${field})`)
      );
  }

  if (newLines) {
    updaters.lineHandler({ type: "set", lines: newLines });
  }
  if (newOtherCosts) {
    updaters.otherCostHandler({ type: "set", lines: newOtherCosts });
  }
  if (newRelatedRecords) {
    updaters.setRelatedRecords(newRelatedRecords);
  }
}
