// reportConfig = {
//
// first the structure of the report, from the configuration file
//
//   reportType, like "PhysicalInventoryWorksheet"
//   customFieldObjectTypes,
//   columns,
//   requiredFiltersNotSetMsg,
//   reportNotes,
//   settings,
//
// then, if it's a custom report
//
//   id,
//   name,
//   settingsValues
//
// and if this report is scheduled
//
//   schedule: {
//     id, // refers to the schedule record
//     frequency,
//     timeOfDay,
//     dayOfWeek,
//     email,
//     ftp,
//   }
// }
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import { FrmEmail } from "components/formFields/FrmEmail";
import { FormErrors } from "components/utility/FormErrors";
import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";
import { getReportFavorites } from "services/sosInventoryService/sosApi";
import {
  getReport,
  favoriteReport,
  unfavoriteReport,
  saveAsNewCustomReport,
  updateCustomReport,
  unscheduleReport,
  runReportImmediate,
  scheduleReport,
  REPORT_TOO_LARGE,
  REPORT_TOO_LARGE_TO_DISPLAY,
  FETCH_FAILURE,
} from "services/sosInventoryService/sosApi";
import {
  getCustomFieldNamesFromDefinitions,
  getCustomFieldValue,
} from "services/utility/customFields";
import { formatDate } from "services/utility/dates";
import { downloadDocument } from "services/utility/documents";
import { handleProgramError } from "services/utility/errors";
import { getPageWidth, getPageHeight } from "services/utility/misc";
import {
  processThead,
  processTbody,
  tableSortJs,
  isRetrievalDisabled,
  getFavoriteId,
  extractSettingsValues,
  isValidSchedule,
  formatSettingsForApi,
  groupAndSortJson,
} from "services/utility/reports";
import { formatReportFilterList } from "services/utility/subheaders";

import { useCustomFieldDefinitions } from "hooks/useCustomFieldDefinitions";
import { useFetchFilterNames } from "hooks/useFetchFilterNames";
import { useItems } from "hooks/useItems";
import { useLocations } from "hooks/useLocations";
import { useRelated } from "hooks/useRelated";
import { useSalesReps } from "hooks/useSalesReps";

import { Columns } from "views/Reports/Columns";
import { Filters } from "views/Reports/Filters";
import { Options } from "views/Reports/Options";
import { Favoriting } from "views/Reports/ReportPage/Favoriting";
import { RenameCustomDialog } from "views/Reports/ReportPage/RenameCustomDialog";
import { SaveAsCustomDialog } from "views/Reports/ReportPage/SaveAsCustomDialog";
import { ScheduleDialog } from "views/Reports/ReportPage/ScheduleDialog";
import { UnscheduleDialog } from "views/Reports/ReportPage/UnscheduleDialog";
import { Widgets } from "views/Reports/Widgets";
import { JsonReport } from "views/Reports/jsonReports/JsonReport";
import { STANDARD_REPORT, CUSTOM_REPORT } from "views/Reports/reportConstants";
import { tableStyles } from "views/Reports/tableStyling";
import { initialErrorState } from "views/common";

import { openAlert } from "globalState/alertSlice";
import {
  loadingIndicatorOn,
  loadingIndicatorOff,
} from "globalState/loadingSlice";

import { ITEM_QUICKLIST_CASES } from "appConstants";

const SETTINGS_PANELS_SX = {
  p: 1,
  backgroundColor: "multiPanelBackground",
  height: "fit-content",
};

function isCustom(reportConfig) {
  return Boolean(reportConfig.id);
}

function reportName(reportConfig) {
  return isCustom(reportConfig)
    ? reportConfig.name
    : i18n(`reports.Report.${reportConfig.reportType}`);
}

function reportCategory(reportConfig) {
  return isCustom(reportConfig) ? CUSTOM_REPORT : STANDARD_REPORT;
}

export function ReportPage(props) {
  const { reportConfig: initialReportConfig } = props;
  const [reportConfig, setReportConfig] = useState(initialReportConfig);
  const [report, setReport] = useState({
    head: null,
    body: null,
    foot: null,
    json: null,
    sort: null,
    direction: null,
  });

  const { head, body, foot } = report;

  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [checkedIds, setCheckedIds] = useState([]);

  const userEmail = useSelector(
    (state) => state.userCompanySettings.user.email
  );

  const defaultReportFormat = useSelector(
    (state) => state.userCompanySettings.settings.defaultReportFormat
  );

  const [emailValid, setEmailValid] = useState(Boolean(userEmail));
  const [downloadFormat, setDownloadFormat] = useState(defaultReportFormat);
  const [emailFormat, setEmailFormat] = useState(defaultReportFormat);
  const [emailAddress, setEmailAddress] = useState(userEmail || "");

  const [settingsPanelOpen, setSettingsPanelOpen] = useState(true);
  const [saveAsCustomDialogOpen, setSaveAsCustomDialogOpen] = useState(false);
  const [renameCustomDialogOpen, setRenameCustomDialogOpen] = useState(false);
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState(false);
  const [unscheduleDialogOpen, setUnscheduleDialogOpen] = useState(false);
  const [scheduleErrors, setScheduleErrors] = useState(initialErrorState());
  const [settingsValuesChanged, setSettingsValuesChanged] = useState(false);
  const [customName, setCustomName] = useState("");

  const [errors, setErrors] = useState([]);

  // track if all choices are selected for an individual multi select filter;
  // this allows for sending the "All" specific API value
  const [allFilterChoicesSelected, setAllFilterChoicesSelected] = useState({});

  const reportTable = useRef();

  // extract the report filters from the settings to use in the UI
  const reportFilters = useMemo(
    () => reportConfig.settings.filter(({ type }) => type === "filter"),
    [reportConfig.settings]
  );

  // extract the report options from the settings to use in the UI
  const reportOptions = useMemo(
    () => reportConfig.settings.filter(({ type }) => type === "option"),
    [reportConfig.settings]
  );

  const [filters, setFilters] = useState(
    extractSettingsValues(
      reportFilters,
      reportCategory(reportConfig),
      reportConfig.settingsValues
    )
  );

  const [options, setOptions] = useState(
    extractSettingsValues(
      reportOptions,
      reportCategory(reportConfig),
      reportConfig.settingsValues
    )
  );

  // get related lists for sub header UI
  const filterNames = useFetchFilterNames(
    reportConfig.customReportNameFetchList,
    filters,
    isCustom(reportConfig)
  );

  const staticSettings = extractSettingsValues(
    reportConfig.settings.filter(({ type }) => type === "static")
  );

  const settings = { ...filters, ...options, ...staticSettings };

  const groupByIsActive = options.groupBy?.id && options.groupBy.id !== "None";

  const defaultColumns = useMemo(
    () => reportConfig.columns.filter((c) => c.default),
    [reportConfig.columns]
  );

  const alwaysShowRightColumns = useMemo(
    () => reportConfig.columns.filter((c) => c.alwaysShow && !c.showLeft),
    [reportConfig.columns]
  );

  const alwaysShowLeftColumns = useMemo(
    () => reportConfig.columns.filter((c) => c.alwaysShow && c.showLeft),
    [reportConfig.columns]
  );

  const [columns, setColumns] = useState([]);

  const customFieldDefinitions = useCustomFieldDefinitions(
    reportConfig.customFieldObjectTypes
  );

  const availableColumns = useMemo(() => {
    if (!customFieldDefinitions) {
      return [];
    }
    const customFieldNames = Array.isArray(
      customFieldDefinitions?.definitionDataAsArray
    )
      ? getCustomFieldNamesFromDefinitions(
          customFieldDefinitions.definitionDataAsArray
        )
      : [];
    return reportConfig.columns.concat(
      customFieldNames.map((apiName) => ({ apiName, isCustomField: true }))
    );
  }, [reportConfig.columns, customFieldDefinitions]);

  useEffect(() => {
    if (reportConfig.columnsValue) {
      // Structure of reportConfig.columnsValue is ['OrderNumber', 'Txn Type', ...]
      // Structure of availableColumns is
      // [
      //   { apiName: 'OrderNumber', label: 'Txn #', default: true }
      //   { apiName: 'Txn Type', default: true},
      //   ....
      // ]
      // Want to end up with the full column definition, like availableColumns,
      // but for just columns in availableColumns, because maybe a custom field
      // was deleted after the report definition
      const fullColumnData = reportConfig.columnsValue.reduce(
        (arrayOfDefinitions, col) => {
          // check to verify that the column exists within our availableColumns array
          const fullDefinition = availableColumns.find(
            ({ apiName }) => apiName === col
          );
          // if it does exist, add the full column definition to the array that
          // we're accumulating; if it doesn't exist, skip it, because that column
          // name must be no longer in use (most likely a previously extant custom
          // field that no longer is defined)
          return fullDefinition
            ? [...arrayOfDefinitions, fullDefinition]
            : arrayOfDefinitions;
        },
        []
      );
      setColumns(fullColumnData);
    } else {
      setColumns(defaultColumns);
    }
  }, [availableColumns, reportConfig.columnsValue, defaultColumns]);

  const [favoriteId, setFavoriteId] = useState(null);
  const [favoritingDisabled, setFavoritingDisabled] = useState(false);

  const [subHeader, setSubHeader] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { items: categories } = useItems(ITEM_QUICKLIST_CASES.CATEGORY);
  const locations = useLocations();
  const salesReps = useSalesReps();
  const ftpServers = useRelated("ftpconnection");

  useEffect(() => {
    setSubHeader(
      formatReportFilterList(filters, reportFilters, filterNames, {
        categories: categories?.length,
        locations: locations?.length,
        salesReps: salesReps?.length,
      })
    );
  }, [
    categories?.length,
    locations?.length,
    salesReps?.length,
    filters,
    filterNames,
    reportFilters,
  ]);

  useEffect(() => setReportConfig(initialReportConfig), [initialReportConfig]);

  useEffect(
    () => setReport({ head: null, body: null, foot: null }),
    [filters, columns, options]
  );

  useEffect(() => () => dispatch(loadingIndicatorOff()), [dispatch]);

  useEffect(() => {
    const reportIdentifier =
      reportCategory(reportConfig) === STANDARD_REPORT
        ? reportConfig.reportType
        : reportConfig.id;
    async function _getFavoriteId() {
      const favorites = await getReportFavorites();
      const favoriteId = getFavoriteId(
        favorites,
        reportCategory(reportConfig),
        reportIdentifier
      );
      setFavoriteId(favoriteId);
    }
    _getFavoriteId();
  }, [reportConfig.reportType, reportConfig, reportConfig.id]);

  useEffect(() => {
    if (reportTable.current) {
      tableSortJs(reportTable.current);
    }
  }, [body]);

  const retrievalDisabled = isRetrievalDisabled(reportFilters, filters);

  function retrieve(action) {
    let format = null;
    if (action === "Email") {
      format = emailFormat;
    }
    if (action === "Download") {
      format = downloadFormat;
    }
    retrieveReport(action, format, emailAddress);
    emailDialogOpen && setEmailDialogOpen(false);
    downloadDialogOpen && setDownloadDialogOpen(false);
  }

  // action is "Display", "Download", or "Email"
  // format is "xlsx", "xls", or "csv", valid only for "Download" and "Email"
  async function retrieveReport(action, downloadFormat, emailAddress) {
    dispatch(loadingIndicatorOn());

    const settingsApiValues = formatSettingsForApi(
      reportConfig.settings,
      settings,
      allFilterChoicesSelected,
      reportConfig.includePaymentDates
    );

    if (action === "Display") {
      // clear old report data when user re-clicks display
      setReport({ head: null, body: null, foot: null, json: null });
      // deselect checked ids
      setCheckedIds([]);
    }

    const response = await getReport(
      reportConfig.reportType,
      action,
      downloadFormat,
      emailAddress,
      settingsApiValues,
      reportConfig.isJsonReport,
      columns.map(({ apiName }) => apiName)
    );

    switch (action) {
      case "Display":
        if (response.success) {
          const { thead, tbody, tfoot, data } = response;
          if (reportConfig.isJsonReport) {
            if (groupByIsActive) {
              const groupedData = groupAndSortJson(
                data,
                columns,
                options.groupBy?.id
              );

              setReport((prev) => ({ ...prev, json: groupedData }));
            } else {
              setReport((prev) => ({ ...prev, json: data }));
            }
            break;
          }
          const head = thead ? processThead(thead, reportConfig.columns) : "";
          const body = tbody ? processTbody(tbody) : "";
          setReport({ head, body, foot: tfoot });
          break;
        }
        setSettingsPanelOpen(true);
        if (response.message === REPORT_TOO_LARGE_TO_DISPLAY) {
          const message = i18n("reports.ReportTooLargeToDisplay");
          dispatch(openAlert({ type: "warning", message }));
        } else if (response.message === REPORT_TOO_LARGE) {
          const message = i18n("reports.ReportTooLarge");
          dispatch(openAlert({ type: "warning", message }));
        } else if (response.message === FETCH_FAILURE) {
          const message = i18n("reports.ProbableTimeOut");
          dispatch(openAlert({ type: "warning", message }));
        } else {
          dispatch(openAlert({ type: "error", message: response.message }));
        }
        break;
      case "Download":
        const documentBlob = response;
        downloadDocument(
          documentBlob,
          `${reportName(reportConfig)}.${downloadFormat}`
        );
        break;
      case "Email":
        break;
      default:
        handleProgramError(new Error(`unexpected value for action: ${action}`));
    }
    dispatch(loadingIndicatorOff());
  }

  async function handleFavorite() {
    setFavoritingDisabled(true);
    const response = await favoriteReport(
      reportConfig.reportType,
      reportCategory(reportConfig) === CUSTOM_REPORT ? reportConfig.id : null
    );
    if (response.success) {
      setFavoriteId(response.data.id);
    } else {
      handleProgramError(new Error("Could not favorite this report"));
    }
    setFavoritingDisabled(false);
  }

  async function handleUnfavorite() {
    setFavoritingDisabled(true);
    const response = await unfavoriteReport(favoriteId);
    if (response.success) {
      setFavoriteId(null);
    } else {
      handleProgramError(new Error("Could not unfavorite this report"));
    }
    setFavoritingDisabled(false);
  }

  async function saveAsNewCustom() {
    const settingsApiValues = formatSettingsForApi(
      reportConfig.settings,
      settings,
      allFilterChoicesSelected,
      reportConfig.includePaymentDates
    );
    if (customName === reportConfig.name) {
      const message = i18n("reports.Alert.Custom.DuplicateName");
      dispatch(openAlert({ type: "error", message }));
      return;
    }
    const response = await saveAsNewCustomReport(
      reportConfig.reportType,
      customName,
      settingsApiValues,
      columns.map(({ apiName }) => apiName)
    );
    setSaveAsCustomDialogOpen(false);
    reportConfig.name = customName;
    setSettingsValuesChanged(false);
    navigate(`/customreport/${response.report.id}`);
  }

  function sortJsonReport(column) {
    const { apiName, isCustomField, jsonName, formatFunc, number } = column;
    const sortAscending = report.sort === column && report.direction === "desc";
    const json = report.json.sort((a, b) => {
      let aValue;
      let bValue;
      if (isCustomField) {
        aValue = getCustomFieldValue(a.customFields, apiName) || "";
        bValue = getCustomFieldValue(b.customFields, apiName) || "";
      } else {
        aValue = (formatFunc ? formatFunc(a[jsonName], a) : a[jsonName]) || "";
        bValue = (formatFunc ? formatFunc(b[jsonName], b) : b[jsonName]) || "";
      }

      if (number) {
        return sortAscending ? aValue - bValue : bValue - aValue;
      }

      const sort = aValue < bValue;
      if (sortAscending) {
        return sort ? 1 : -1;
      }
      return sort ? -1 : 1;
    });
    setReport((prev) => ({
      ...prev,
      json,
      sort: column,
      direction: sortAscending ? "asc" : "desc",
    }));
  }

  async function scheduleFunction(newSchedule) {
    let newCustomReportId = null;

    // if this is a standard report, we need to create a custom report first
    if (reportCategory(reportConfig) === STANDARD_REPORT) {
      const settingsApiValues = formatSettingsForApi(
        reportConfig.settings,
        settings,
        allFilterChoicesSelected,
        reportConfig.includePaymentDates
      );
      const response = await saveAsNewCustomReport(
        reportConfig.reportType,
        i18n(`reports.Report.${reportConfig.reportType}`),
        settingsApiValues,
        columns.map(({ apiName }) => apiName)
      );
      newCustomReportId = response.report.id;
    }

    const response = await scheduleReport(
      reportConfig.reportType,
      { id: reportConfig.id || newCustomReportId },
      newSchedule
    );
    if (response.success) {
      const { id, frequency, dayOfWeek, timeOfDay, email, ftp } =
        response.report;
      setReportConfig((prev) => ({
        ...prev,
        schedule: {
          id,
          frequency: frequency.id,
          dayOfWeek,
          timeOfDay,
          email,
          ftp,
          saved: true,
        },
      }));
      const message = i18n("reports.Alert.Scheduling.Success");
      dispatch(openAlert({ type: "success", message }));
      setScheduleDialogOpen(false);
      setSettingsValuesChanged(false);
      if (reportCategory(reportConfig) === STANDARD_REPORT) {
        navigate(`/customreport/${newCustomReportId}`);
      }
    } else {
      const message = i18n("reports.Alert.Scheduling.Error");
      dispatch(openAlert({ type: "error", message }));
    }
  }

  async function unscheduleFunction() {
    const success = await unscheduleReport(reportConfig.schedule.id);
    if (success) {
      setReportConfig({ ...reportConfig, schedule: null });
    } else {
      handleProgramError(new Error("Could not unschedule this report"));
    }
    setUnscheduleDialogOpen(false);
  }

  async function save() {
    const settingsApiValues = formatSettingsForApi(
      reportConfig.settings,
      settings,
      allFilterChoicesSelected,
      reportConfig.includePaymentDates
    );
    const response = await updateCustomReport(
      reportConfig.id,
      reportConfig.reportType,
      reportName(reportConfig),
      settingsApiValues,
      columns.map(({ apiName }) => apiName)
    );
    if (response.success) {
      setSettingsValuesChanged(false);
      dispatch(
        openAlert({ type: "success", message: i18n("reports.Alert.Saved") })
      );
    } else {
      handleProgramError(new Error("Could not save custom report."));
    }
  }

  async function renameCustom() {
    const settingsApiValues = formatSettingsForApi(
      reportConfig.settings,
      settings,
      allFilterChoicesSelected,
      reportConfig.includePaymentDates
    );
    const response = await updateCustomReport(
      reportConfig.id,
      reportConfig.reportType,
      customName,
      settingsApiValues,
      columns.map(({ apiName }) => apiName)
    );
    if (response.success) {
      setReportConfig((prev) => ({ ...prev, name: customName }));
      setRenameCustomDialogOpen(false);
    } else {
      handleProgramError(new Error("Could not update custom report name."));
    }
  }

  async function runImmediate(schedule) {
    const newErrors = isValidSchedule(schedule);
    setScheduleErrors(newErrors);
    if (newErrors.messages?.length !== 0) {
      const message = newErrors.messages.join(" ");
      dispatch(openAlert({ type: "error", message }));
      return;
    }
    const settingsApiValues = formatSettingsForApi(
      reportConfig.settings,
      settings,
      allFilterChoicesSelected,
      reportConfig.includePaymentDates
    );
    const success = await runReportImmediate(
      reportConfig.reportType,
      reportCategory(reportConfig) === CUSTOM_REPORT
        ? { id: reportConfig.id }
        : null,
      settingsApiValues,
      columns.map(({ apiName }) => apiName),
      schedule.frequency,
      schedule.timeOfDay,
      schedule.dayOfWeek,
      schedule.email,
      schedule.ftp
    );
    const message = success
      ? i18n("reports.Alert.Scheduling.Test.Successful")
      : i18n("reports.Alert.Scheduling.Test.Unsuccessful");
    dispatch(openAlert({ type: success ? "success" : "error", message }));
  }

  const reportIsShown = head || body || foot || report.json;
  const wrapperHeightStyles =
    reportIsShown && !settingsPanelOpen ? { maxHeight: getPageHeight() } : {};

  return (
    <>
      <Box
        sx={{
          display: "grid",
          minWidth: getPageWidth(),
          alignContent: "start",
          ...wrapperHeightStyles,
          ...tableStyles,
        }}
        p={2}
      >
        <Box id="header-and-subheader" mb={2}>
          <Box sx={{ display: "flex" }}>
            <Typography variant="h2" component="span">
              {reportName(reportConfig)}
            </Typography>
            {reportCategory(reportConfig) === STANDARD_REPORT && (
              <HoverHelp
                title={i18n(`reports.explanation.${reportConfig.reportType}`)}
                sx={{ top: "1.3rem", ml: "0.3rem" }}
              />
            )}

            {/* Edit report name */}
            {!settingsValuesChanged &&
              reportCategory(reportConfig) === CUSTOM_REPORT && (
                <span style={{ marginTop: "0.8rem", marginLeft: "0.5rem" }}>
                  <Tooltip title="rename">
                    <div>
                      <IconButton
                        onClick={() => setRenameCustomDialogOpen(true)}
                      >
                        <Edit sx={{ cursor: "pointer" }} />
                      </IconButton>
                    </div>
                  </Tooltip>
                </span>
              )}

            <Favoriting
              favoriteId={favoriteId}
              favoritingDisabled={favoritingDisabled}
              handleFavorite={handleFavorite}
              handleUnfavorite={handleUnfavorite}
            />
          </Box>
          {reportCategory(reportConfig) === CUSTOM_REPORT && (
            <Typography
              variant="tag"
              component="p"
              sx={{ marginLeft: "0.2rem", textTransform: "uppercase" }}
            >
              {i18n("reports.CustomDesignation")}{" "}
              {i18n(`reports.Report.${reportConfig.reportType}`)}
            </Typography>
          )}
          {retrievalDisabled ? (
            <Typography sx={{ ml: "0.2em", color: "warning.main" }}>
              {reportConfig.requiredFiltersNotSetMsg}
            </Typography>
          ) : (
            <Typography sx={{ ml: "0.3em" }}>
              {subHeader || <span>&nbsp;</span>}
            </Typography>
          )}
          <FormErrors errors={errors} setErrors={setErrors} />
        </Box>

        <Paper
          id="paper-div-for-widgets"
          elevation={1}
          sx={{
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            mb: 1,
            px: 1,
          }}
        >
          <Widgets
            reportCategory={reportCategory(reportConfig)}
            setEmailDialogOpen={setEmailDialogOpen}
            setDownloadDialogOpen={setDownloadDialogOpen}
            setRenameCustomDialogOpen={setRenameCustomDialogOpen}
            retrieve={retrieve}
            retrievalDisabled={retrievalDisabled}
            settingsPanelOpen={settingsPanelOpen}
            setSettingsPanelOpen={setSettingsPanelOpen}
            settingsValuesChanged={settingsValuesChanged}
            name={reportConfig.name}
            setCustomName={setCustomName}
            setSaveAsCustomDialogOpen={setSaveAsCustomDialogOpen}
            setScheduleDialogOpen={setScheduleDialogOpen}
            setUnscheduleDialogOpen={setUnscheduleDialogOpen}
            scheduleSaved={Boolean(reportConfig.schedule?.saved)}
            schedule={reportConfig.schedule}
            save={save}
            settings={{ ...filters, ...options }}
            checkedIds={checkedIds}
            showReorderReportActions={
              report.json && reportConfig.reportType === "ReorderReport"
            }
          />
        </Paper>

        <Collapse in={settingsPanelOpen} timeout={750}>
          <Paper elevation={1}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "30fr 30fr 40fr",
                columnGap: 1,
                mb: 1,
                p: 1,
              }}
            >
              <Paper sx={SETTINGS_PANELS_SX} elevation={3}>
                <Filters
                  filters={filters}
                  reportFilters={reportFilters}
                  setFilters={(f) => {
                    setFilters(f);
                    setSettingsValuesChanged(true);
                  }}
                  setAllFilterChoicesSelected={setAllFilterChoicesSelected}
                  categories={categories}
                  locations={locations}
                  salesReps={salesReps}
                />
              </Paper>

              <Paper sx={SETTINGS_PANELS_SX} elevation={3}>
                <Options
                  options={options}
                  setOptions={(o) => {
                    setOptions(o);
                    setSettingsValuesChanged(true);
                  }}
                  reportOptions={reportOptions}
                  includePaymentDates={reportConfig.includePaymentDates}
                />
              </Paper>

              {!(
                availableColumns.filter((col) => !col.alwaysShow).length === 0
              ) && (
                <Paper sx={SETTINGS_PANELS_SX} elevation={3}>
                  <Columns
                    columns={columns}
                    availableColumns={availableColumns}
                    setColumns={(c) => {
                      setColumns(c);
                      setSettingsValuesChanged(true);
                    }}
                  />
                </Paper>
              )}
            </Box>
          </Paper>
        </Collapse>

        <Dialog open={emailDialogOpen}>
          <DialogTitle>{i18n("reports.Email")}</DialogTitle>
          <DialogContent>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                {i18n("reports.RetrieveForms.ReportType")}
              </FormLabel>
              <RadioGroup
                value={emailFormat}
                name="radio-buttons-group"
                onChange={(e) => setEmailFormat(e.target.value)}
                sx={{ pb: 1.5 }}
              >
                <FormControlLabel
                  value="xlsx"
                  control={<Radio />}
                  label={i18n("reports.Format.Excel")}
                />
                <FormControlLabel
                  value="xls"
                  control={<Radio />}
                  label={i18n("reports.Format.LegacyExcel")}
                />
                <FormControlLabel
                  value="csv"
                  control={<Radio />}
                  label={i18n("reports.Format.CSV")}
                />
              </RadioGroup>
            </FormControl>
            <FrmEmail
              value={emailAddress}
              label={i18n("reports.RetrieveForms.Email")}
              onBlur={(_, newValue) => setEmailAddress(newValue)}
              setEmailValid={setEmailValid}
            />
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <div></div>
            <div>
              <Button
                color="secondary"
                onClick={() => setEmailDialogOpen(false)}
              >
                {i18n("button.Cancel")}
              </Button>
              <Button
                disabled={!emailValid}
                onClick={() => retrieve("Email")}
                color="secondary"
              >
                {i18n("global.Email")}
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        <Dialog open={downloadDialogOpen}>
          <DialogTitle>{i18n("global.Download")}</DialogTitle>
          <DialogContent>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                {i18n("reports.RetrieveForms.ReportType")}
              </FormLabel>
              <RadioGroup
                value={downloadFormat}
                name="radio-buttons-group"
                onChange={(e) => setDownloadFormat(e.target.value)}
              >
                <FormControlLabel
                  value="xlsx"
                  control={<Radio />}
                  label={i18n("reports.Format.Excel")}
                />
                <FormControlLabel
                  value="xls"
                  control={<Radio />}
                  label={i18n("reports.Format.LegacyExcel")}
                />
                <FormControlLabel
                  value="csv"
                  control={<Radio />}
                  label={i18n("reports.Format.CSV")}
                />
              </RadioGroup>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <div>
              <Button
                color="secondary"
                onClick={() => setDownloadDialogOpen(false)}
              >
                {i18n("button.Cancel")}
              </Button>
              <Button onClick={() => retrieve("Download")} color="secondary">
                {i18n("global.Download")}
              </Button>
            </div>
          </DialogActions>
        </Dialog>

        {reportIsShown && (
          <Fragment>
            <Typography variant="h3">{options.header}</Typography>
            {options.showFromTo && (
              <Typography variant="h4">
                {reportOptions.find(({ apiName }) => apiName === "showFromTo")
                  .isAsOfDate
                  ? `${i18n("global.AsOf")} ${formatDate(filters.date1)}`
                  : `${i18n("reports.From")} ${
                      filters.date1
                        ? formatDate(filters.date1)
                        : i18n("reports.earliest")
                    } ${i18n("reports.to")} ${
                      filters.date2
                        ? formatDate(filters.date2)
                        : i18n("reports.latest")
                    }`}
              </Typography>
            )}
            {(head || body || foot) && (
              <table
                className="table-sort"
                style={{
                  overflowX: "scroll",
                  width: "100%",
                  wordWrap: "break-word",
                }}
                ref={reportTable}
              >
                <thead
                  data-testing="reportTableHeader"
                  dangerouslySetInnerHTML={{ __html: head }}
                />
                <tbody
                  data-testing="reportTableBody"
                  dangerouslySetInnerHTML={{ __html: body }}
                />
                <tfoot dangerouslySetInnerHTML={{ __html: foot }} />
              </table>
            )}
            {report.json && reportConfig.isJsonReport && (
              <JsonReport
                reportAction={reportConfig.reportAction}
                columns={[
                  ...alwaysShowLeftColumns,
                  ...columns,
                  ...alwaysShowRightColumns,
                ]}
                reportJson={report.json}
                sortJsonReport={sortJsonReport}
                setCheckedIds={setCheckedIds}
                groupByIsActive={groupByIsActive}
                checkedIds={checkedIds}
              />
            )}
            <Typography variant="h3">{options.footer}</Typography>
            {reportConfig.notes && (
              <div>
                <div style={{ marginTop: "1rem" }}>{i18n("global.Notes")}</div>
                <ul style={{ marginTop: 0 }}>
                  {reportConfig.notes.map((note, index) => {
                    return <li key={index}>{note}</li>;
                  })}
                </ul>
              </div>
            )}
          </Fragment>
        )}
      </Box>

      <SaveAsCustomDialog
        saveAsCustomDialogOpen={saveAsCustomDialogOpen}
        setSaveAsCustomDialogOpen={setSaveAsCustomDialogOpen}
        customName={customName}
        setCustomName={setCustomName}
        reportConfig={reportConfig}
        saveAsNewCustom={saveAsNewCustom}
      />

      <ScheduleDialog
        schedule={reportConfig.schedule}
        scheduleDialogOpen={scheduleDialogOpen}
        setScheduleDialogOpen={setScheduleDialogOpen}
        reportConfig={reportConfig}
        ftpServers={ftpServers}
        runImmediate={runImmediate}
        scheduleFunction={scheduleFunction}
        scheduleErrors={scheduleErrors}
        setScheduleErrors={setScheduleErrors}
        key={scheduleDialogOpen}
      />

      <UnscheduleDialog
        unscheduleDialogOpen={unscheduleDialogOpen}
        setUnscheduleDialogOpen={setUnscheduleDialogOpen}
        unscheduleReport={unscheduleFunction}
        reportName={reportName(reportConfig)}
      />

      <RenameCustomDialog
        renameCustomDialogOpen={renameCustomDialogOpen}
        setRenameCustomDialogOpen={setRenameCustomDialogOpen}
        customName={customName}
        setCustomName={setCustomName}
        reportConfig={reportConfig}
        renameCustom={renameCustom}
      />
    </>
  );
}
