import { Money, Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import {
  extractName,
  formatDecimal,
  formatMoneyWithoutAdornments,
} from "services/utility/formatting";

export const columnInfo = [
  {
    name: "item",
    heading: i18n("columns.Item"),
    formatFunc: extractName,
    linkField: true,
    defaultSort: true,
    fixed: true,
    sortable: true,
    default: true,
  },
  {
    name: "vendor",
    heading: i18n("columns.Vendor"),
    formatFunc: extractName,
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
    default: true,
  },
  {
    name: "vendorPartNumber",
    heading: i18n("columns.VendorPartNumber"),
    fixedRightOfAction: true,
    fixed: true,
    sortable: true,
    default: true,
  },
  {
    name: "price",
    heading: i18n("columns.Price"),
    formatFunc: formatMoneyWithoutAdornments,
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
    default: true,
  },
  {
    name: "leadTime",
    heading: i18n("columns.LeadTime"),
    formatFunc: formatDecimal,
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
    default: true,
  },
];

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["leadTime"],
  MONEY_FIELDS: ["price"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];

export function getEmptyRecord() {
  return {
    item: null,
    vendor: null,
    price: new Money(0),
    vendorPartNumber: null,
    leadTime: new Decimal(0),
  };
}
