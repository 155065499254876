import { FileSelector } from "components/FileSelector";
import { GroupingBox } from "components/utility/GroupingBox";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { isAttachableMsWordFile } from "services/utility/files";

export function FrmAddFile(props) {
  const { setRecord, record, error, setErrors } = props;

  return (
    <GroupingBox error={error} heading={i18n("frmLabel.File")}>
      <VSpace space={1} />
      <FileSelector
        record={record}
        setRecord={setRecord}
        setErrors={setErrors}
        validator={isAttachableMsWordFile}
      />
    </GroupingBox>
  );
}
