// Actual domain logic should be in a domainLogic.js file, at the appropriate
// level in the hierarchy.
import { IN_TRANSACTION } from "appConfig";

import { Decimal, Money } from "classes/DecimalClasses";

import {
  changeCustomer,
  updateLineItemAvailable,
} from "services/sosInventoryService/salesTransaction/domainLogic";
import { getRecord } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";
import { formatCardOnFile } from "services/utility/formatting";

import { DEFAULT_DECIMALS_ROUNDED, OBJECT_TYPES } from "appConstants";

const OBJECT_TYPE = OBJECT_TYPES.SALES_ORDER.fullString;

export async function updateTransaction(
  field,
  newValue,
  transactionCustomFieldDefs,
  customerCustomFieldDefs,
  currentState,
  taxCodes,
  updaters,
  currencies
) {
  const { record, lines, relatedRecords, totals } = currentState;
  if (!record || !lines || !relatedRecords) {
    handleProgramError(new Error("update Transaction | invalid currentState"));
  }

  let newRecord, newLines, newRelatedRecords;

  switch (field) {
    case "date":
      newRecord = { ...record, date: newValue, dateChanged: true };
      updaters.setRecord(newRecord);
      if (newValue) {
        newLines = await updateLineItemAvailable(newRecord, lines, OBJECT_TYPE);
      }

      break;

    case "location":
      newRecord = { ...record, location: newValue };
      updaters.setRecord(newRecord);
      newLines = await updateLineItemAvailable(newRecord, lines, OBJECT_TYPE);

      break;

    case "customer":
      if (!newValue) {
        newRecord = {
          ...record,
          customer: null,
          accountToken: null,
          paymentInfo: null,
          hasCardOnFile: null,
        };
        updaters.setRecord(newRecord);
        newRelatedRecords = { ...relatedRecords, customer: null };
        break;
      }

      const recordData = await changeCustomer(newValue.id, {
        record,
        customerCustomFieldDefs,
        transactionCustomFieldDefs,
        transactionCustomFields: record.customFields,
        lines,
        taxCodes,
      });

      const priceTier = recordData.customer?.priceTier
        ? await getRecord(
            "pricetier",
            recordData.customer.priceTier.id,
            IN_TRANSACTION
          )
        : null;

      newRecord = { ...recordData.newRecord, priceTier };

      const { hasCardOnFile } = recordData.customer;
      if (hasCardOnFile) {
        newRecord.paymentInfo = formatCardOnFile(null, recordData.customer);
        newRecord.hasCardOnFile = true;
        newRecord.storeCustomerToken = true;
      } else {
        newRecord.accountToken = null;
        newRecord.paymentInfo = null;
        newRecord.hasCardOnFile = false;
        newRecord.storeCustomerToken = false;
      }

      updaters.setRecord(newRecord);
      newLines = recordData.newLines;
      newRelatedRecords = { ...relatedRecords, customer: recordData.customer };

      break;

    case "accountToken":
      newRecord = {
        ...record,
        [field]: newValue.token,
        paymentInfo: newValue.paymentInfo,
        storeCustomerToken: false,
        hasCardOnFile: true,
        showKeepOnFile: true,
      };
      updaters.setRecord(newRecord);

      break;

    case "assignedToUser":
    case "channel":
    case "orderStage":
    case "priority":
    case "salesRep":
    case "terms":
    case "transactionLocationQuickBooks":
    case "billing":
    case "comment":
    case "customerNotes":
    case "customerMessage":
    case "customerPO":
    case "customFields":
    case "department":
    case "dropShip":
    case "number":
    case "priceTier":
    case "shipping":
    case "statusMessage":
    case "storeCustomerToken":
      newRecord = { ...record, [field]: newValue };
      updaters.setRecord(newRecord);
      break;

    case "exchangeRate":
    case "depositPercent":
      const depositPercent = new Decimal(newValue || 0);
      newRecord = {
        ...record,
        depositPercent,
        depositAmount: totals.total
          .times(depositPercent)
          .times(new Decimal(0.01)),
      };
      updaters.setRecord(newRecord);
      break;

    case "depositAmount":
      const depositAmount = new Money(newValue || 0);
      newRecord = {
        ...record,
        depositAmount,
        depositPercent: depositAmount
          .div(totals.total)
          .times(new Decimal(100))
          .round(DEFAULT_DECIMALS_ROUNDED, Decimal.roundDown),
      };
      updaters.setRecord(newRecord);
      break;

    case "shippingAmount":
      newRecord = {
        ...record,
        shippingAmount: new Money(newValue || 0),
        shippingUpdated: true,
      };
      updaters.setRecord(newRecord);
      break;

    case "currency":
      updaters.setRecord((prev) => ({
        ...prev,
        currency: newValue.id ? { id: newValue.id, name: newValue.name } : null,
        exchangeRate: newValue.id
          ? currencies.find(({ id }) => id === newValue.id).exchangeRate
          : null,
      }));
      break;

    default:
      handleProgramError(
        new Error(`updateTransaction | unknown field (${field})`)
      );
  }

  if (newLines) {
    updaters.lineHandler({ type: "set", lines: newLines });
  }
  if (newRelatedRecords) {
    updaters.setRelatedRecords(newRelatedRecords);
  }
}
