import { ITEM_CALCULATED_FIELDS as BUILD_ITEM_CALCULATED_FIELDS } from "services/sosInventoryService/build/schema";
import {
  getDefaultBin,
  getItemRecord,
  updateSingleLineAvailableBinsAndBin,
  getMultipleItemRecords,
} from "services/sosInventoryService/domainLogic";
import { ITEM_CALCULATED_FIELDS as PROCESS_CALCULATED_FIELDS } from "services/sosInventoryService/process/schema";
import { descriptionForPurchasingTransactions } from "services/sosInventoryService/purchasingTransaction/domainLogic";
import {
  getItemBom,
  getItemLocationSettings,
} from "services/sosInventoryService/sosApi";
import { filterAndFormatBinOptions } from "services/utility/misc";
import { normalizeSerials } from "services/utility/serials";
import { getUomConversionFromUomReference } from "services/utility/uoms";
import { getBaseUom } from "services/utility/uoms";

import { ITEM_TYPES } from "appConstants";
import { EMPTY_INPUT_LINE_ITEM, EMPTY_OUTPUT_LINE_ITEM } from "editConfig";

export async function updateLineWithItem(item, line, record) {
  const { location } = record;
  if (!item) {
    return line;
  }
  // transfer the appropriate properties from the new inventory
  // item to the line item
  let newLine = {
    ...line,
    item,
    relatedRecords: { ...line.relatedRecords, item },
    description: descriptionForPurchasingTransactions(item),
    uom: getBaseUom(item.uoms),
    serials: [],
    available: item.available,
    onhand: item.onhand,
    onSO: item.onSO,
    itemDetails: {
      itemUoms: item.uoms,
      serialTracking: item.serialTracking,
      lotTracking: item.lotTracking,
      type: item.type,
    },
  };

  // set the default bin and the available bins for the dropdown
  newLine = await updateSingleLineAvailableBinsAndBin(location, newLine);

  return newLine;
}

export function updateLineItemSerials(autoSerialLot, lines) {
  return lines.map((line) => {
    const { serials, quantity, itemDetails } = line;
    return {
      ...line,
      serials: normalizeSerials(
        serials,
        quantity,
        itemDetails.serialTracking,
        autoSerialLot
      ),
    };
  });
}

export function updateWorkOrderLineWithItem(item, line) {
  const newLine = {
    ...line,
    item,
    description: item.description,
    uom: getBaseUom(item.uoms),
    weightunit: item.weightUnit,
    volumeunit: item.volumeUnit,
    relatedRecords: { ...line.relatedRecords, item },
    itemDetails: {
      itemWeight: item.weight,
      itemVolume: item.volume,
      itemUoms: item.uoms,
      type: item.type,
    },
  };

  return newLine;
}

export async function updateBuildLineItems(date, location, lines) {
  // used to update the fields reliant on location and date

  const newLines = await Promise.all(
    lines.map(async (line) => {
      if (line.item?.id && location) {
        const item = await getItemRecord(
          line.item.id,
          location?.id,
          date,
          BUILD_ITEM_CALCULATED_FIELDS
        );
        const filteredAndFormattedBinOptions = filterAndFormatBinOptions(
          item.locationBins
        );
        const uom = item.uoms.find(({ uom }) => uom.id === line.uom.id);
        const newLine = {
          ...line,
          availableBins: filteredAndFormattedBinOptions,
          relatedRecords: { ...line.relatedRecrods, item },
          onhand: item.onhand,
          onSO: item.onSO,
          uom: uom ? { ...uom, id: line.uom.id } : null,
        };
        newLine.bin = await getDefaultBin(
          item.id,
          location?.id,
          filteredAndFormattedBinOptions
        );
        return newLine;
      } else {
        const newLine = {
          ...line,
          availableBins: [],
          bin: null,
        };
        return newLine;
      }
    })
  );
  return newLines;
}

export async function updateProcessLineItems(date, location, lines) {
  return await Promise.all(
    lines.map(async (line) => {
      let newLine = { ...line };
      if (line.item?.id && location) {
        const item = await getItemRecord(
          line.item.id,
          location?.id,
          date,
          PROCESS_CALCULATED_FIELDS
        );
        const filteredAndFormattedBinOptions = filterAndFormatBinOptions(
          item.locationBins
        );
        const uom = item.uoms.find(({ uom }) => uom.id === line.uom.id);

        newLine.availableBins = filteredAndFormattedBinOptions;
        newLine.relatedRecords = { ...line.relatedRecrods, item };
        newLine.onhand = item.onhand;
        newLine.uom = uom ? { ...uom, id: line.uom.id } : null;
        newLine.bin = await getDefaultBin(
          item.id,
          location?.id,
          filteredAndFormattedBinOptions
        );
      } else {
        newLine.availableBins = [];
        newLine.bin = null;
      }
      return newLine;
    })
  );
}

export async function updateInputs(outputs, date, location) {
  const output = outputs[0];
  if (output?.relatedRecords.item?.type !== ITEM_TYPES.ASSEMBLY) {
    return null;
  }

  const bom = await getItemBom(output.item.id);
  const bomIds = bom.map((bomItem) => bomItem.componentItem.id);
  const items = await getMultipleItemRecords(
    bomIds,
    location?.id,
    date,
    BUILD_ITEM_CALCULATED_FIELDS
  );

  const itemsDefaultBins = location.binTracking
    ? await Promise.all(
        items.map(async (item) => {
          const {
            data: { defaultBin },
          } = await getItemLocationSettings(item.id, location.id);
          return defaultBin;
        })
      )
    : null;

  const newInputs = bom.map((bomItem, index) => {
    const item = items.find(({ id }) => id === bomItem.componentItem.id);
    if (item) {
      const outputConversion = getUomConversionFromUomReference(
        output.uom,
        output.relatedRecords.item.uoms
      );
      const filteredAndFormattedBinOptions = filterAndFormatBinOptions(
        item.locationBins
      );
      const usedPer = bomItem.quantity.times(outputConversion);
      const newLine = {
        ...EMPTY_INPUT_LINE_ITEM.build,
        availableBins: filteredAndFormattedBinOptions,
        item: bomItem.componentItem,
        description: descriptionForPurchasingTransactions(item),
        class: item.class,
        weightunit: item.weightUnit,
        volumeunit: item.volumeUnit,
        uom: getBaseUom(item.uoms),
        onhand: item.onhand,
        usedPer,
        bin: itemsDefaultBins ? itemsDefaultBins[index] : null,
        quantity: usedPer.times(output.quantity),
        itemDetails: {
          itemWeight: item.weight,
          itemVolume: item.volume,
          itemUoms: item.uoms,
          serialTracking: item.serialTracking,
          lotTracking: item.lotTracking,
          type: item.type,
        },
        relatedRecords: { item },
      };
      return newLine;
    }
    return null;
  });
  return newInputs.filter((input) => input);
}

export function updateByMultiplier(multiplier, outputs, inputs) {
  const newOutputs = [...outputs];
  const newInputs = [...inputs];
  outputs.forEach((line, i) => {
    newOutputs[i].quantity = line.quantity.times(multiplier);
  });
  inputs.forEach((line, i) => {
    newInputs[i].quantity = line.quantity.times(multiplier);
  });
  return { newOutputs, newInputs };
}

export async function addTemplateLines(record) {
  const { template, location, date } = record;
  const itemPromises = [];
  for (let i = 0; i < template.outputs.length; i++) {
    const output = template.outputs[i];
    if (output.item?.id) {
      itemPromises.push(
        getItemRecord(
          output.item.id,
          location?.id,
          date,
          PROCESS_CALCULATED_FIELDS
        )
      );
    }
  }
  for (let i = 0; i < template.inputs.length; i++) {
    const input = template.inputs[i];
    if (input.item?.id) {
      itemPromises.push(
        getItemRecord(
          input.item.id,
          location?.id,
          date,
          PROCESS_CALCULATED_FIELDS
        )
      );
    }
  }

  const inventoryItems = await Promise.all(itemPromises);
  const newOutputs = await Promise.all(
    template.outputs.map(async ({ cost, quantity, waste, item }) => {
      const fullItemData = inventoryItems.find(({ id }) => id === item?.id);
      const { serialTracking, uoms, type } = fullItemData;
      const line = {
        ...EMPTY_OUTPUT_LINE_ITEM.process,
        item,
        cost,
        quantity,
        waste,
        description: descriptionForPurchasingTransactions(fullItemData),
        uom: getBaseUom(uoms),
        class: fullItemData.class,
        itemDetails: { type, itemUoms: uoms, serialTracking },
        relatedRecords: { item: fullItemData },
      };

      const newLine = updateLineWithItem(fullItemData, line, record);
      return newLine;
    })
  );

  const newInputs = await Promise.all(
    template.inputs.map(async ({ cost, quantity, item }) => {
      const fullItemData = inventoryItems.find(({ id }) => id === item?.id);
      const { serialTracking, uoms, type, onhand, lotTracking } = fullItemData;
      const line = {
        ...EMPTY_INPUT_LINE_ITEM.process,
        item,
        cost,
        quantity,
        description: descriptionForPurchasingTransactions(fullItemData),
        onhand,
        uom: getBaseUom(uoms),
        class: fullItemData.class,
        itemDetails: { type, itemUoms: uoms, serialTracking, lotTracking },
        relatedRecords: { item: fullItemData },
      };

      const newLine = updateLineWithItem(fullItemData, line, record);
      return newLine;
    })
  );
  return { newOutputs, newInputs };
}
