import { i18n } from "services/i18nService";
import {
  extractName,
  extractId,
  formatMoneyWithoutAdornments,
  formatDecimal,
} from "services/utility/formatting";
import { getReportDecimalTotal } from "services/utility/reports";

import {
  NONE_GROUP_BY_OPTION,
  GROUP_BY_CATEGORY_OR_VENDOR_OPTIONS,
  SUB_COMPONENT,
} from "views/Reports/reportConstants";

import { ALL_FILTER_OPTION, OBJECT_TYPES } from "appConstants";

export const InventorySummary = {
  userHasSufficientPrivilege: (p) =>
    p.viewInventory || p.viewItems || p.editItems,
  planIncludesReport: () => true,
  customReportNameFetchList: [
    { objectType: OBJECT_TYPES.ITEM.fullString, customReportKey: "categories" },
    {
      objectType: OBJECT_TYPES.LOCATION.fullString,
      customReportKey: "locations",
    },
    { objectType: OBJECT_TYPES.VENDOR.fullString },
  ],
  isJsonReport: true,
  customFieldObjectTypes: "item",
  columns: [
    {
      apiName: "Name",
      jsonName: "item",
      formatFunc: extractName,
      linkColumn: true,
      linkObjectType: "item",
      alwaysShow: true,
      showLeft: true,
    },
    {
      apiName: "ItemNumber",
      jsonName: "item",
      formatFunc: extractId,
      label: "Item #",
    },
    { apiName: "Category", jsonName: "category", formatFunc: extractName },
    { apiName: "Description", jsonName: "description", default: true },
    { apiName: "Purchase Description", jsonName: "purchaseDescription" },
    { apiName: "SKU", jsonName: "sku" },
    { apiName: "Barcode", jsonName: "barcode" },
    { apiName: "Vendor", jsonName: "vendor", formatFunc: extractName },
    {
      apiName: "VendorPartNumber",
      jsonName: "vendorPartNumber",
      label: "Vendor Part #",
    },
    {
      apiName: "Sales Price",
      jsonName: "salesPrice",
      formatFunc: formatMoneyWithoutAdornments,
      number: true,
    },
    {
      apiName: "Purchase Cost",
      jsonName: "purchaseCost",
      formatFunc: formatMoneyWithoutAdornments,
      number: true,
    },
    {
      apiName: "Default Bin",
      jsonName: "defaultBin",
      formatFunc: extractName,
    },
    {
      apiName: "Asset Account",
      jsonName: "assetAccount",
      formatFunc: extractName,
    },
    {
      apiName: "Lead Time",
      jsonName: "leadTime",
      formatFunc: formatDecimal,
      number: true,
    },
    {
      apiName: "Reorder Point",
      jsonName: "reorderPoint",
      formatFunc: formatDecimal,
      number: true,
    },
    {
      apiName: "Max Stock",
      jsonName: "maxStock",
      formatFunc: formatDecimal,
      number: true,
    },
    { apiName: "Tags", jsonName: "tags" },
    {
      apiName: "On Hand",
      jsonName: "onHand",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      number: true,
      default: true,
    },
    {
      apiName: "Available",
      jsonName: "available",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      default: true,
      number: true,
    },
    {
      apiName: "On SO",
      jsonName: "onSo",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      inventoryColumn: true,
      inventoryType: "onSo",
      number: true,
      default: true,
    },
    {
      apiName: "On SR",
      jsonName: "onSr",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      inventoryColumn: true,
      inventoryType: "onSr",
      number: true,
      default: true,
    },
    {
      apiName: "On PO",
      jsonName: "onPo",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      inventoryColumn: true,
      inventoryType: "onPo",
      number: true,
      default: true,
    },
    {
      apiName: "On RMA",
      jsonName: "onRma",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      inventoryColumn: true,
      inventoryType: "onRma",
      number: true,
    },
    {
      apiName: "On WO",
      jsonName: "onWo",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      inventoryColumn: true,
      inventoryType: "onWo",
      number: true,
      default: true,
    },
    {
      apiName: "On Rental",
      jsonName: "onRental",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      inventoryColumn: true,
      inventoryType: "onRental",
      number: true,
      default: true,
    },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.asOf"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.AsOfDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date1Macro",
      component: SUB_COMPONENT,
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Location"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: [ALL_FILTER_OPTION],
      includeAllOption: true,
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "suppressIfEmpty",
      defaultValue: false,
      label: i18n("reports.OptionLabel.HideRowsWithAllZeros"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedItems"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "highlightReorders",
      defaultValue: false,
      label: i18n("reports.OptionLabel.HighlightReorders"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowAsOfDate"),
      component: "FrmCheckbox",
      isAsOfDate: true,
      type: "option",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: GROUP_BY_CATEGORY_OR_VENDOR_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
