import { useSelector } from "react-redux";

import { USER_PRIVILEGE } from "appConstants";

const EDIT_PRIVILEGES_BY_OBJECT = {
  adjustment: USER_PRIVILEGE.adjustInventory,
  build: USER_PRIVILEGE.buildAssemblies,
  customer: USER_PRIVILEGE.editCustomers,
  estimate: USER_PRIVILEGE.editEstimates,
  invoice: USER_PRIVILEGE.editInvoices,
  item: USER_PRIVILEGE.editItems,
  itemreceipt: USER_PRIVILEGE.receiveInventory,
  job: USER_PRIVILEGE.editJobs,
  payment: USER_PRIVILEGE.processPayment,
  process: USER_PRIVILEGE.buildAssemblies,
  purchaseorder: USER_PRIVILEGE.editPurchaseOrders,
  rental: USER_PRIVILEGE.editRentals,
  rentalreturn: USER_PRIVILEGE.editRentals,
  return: USER_PRIVILEGE.editShipments,
  returntovendor: USER_PRIVILEGE.receiveInventory,
  rma: USER_PRIVILEGE.editShipments,
  salesreceipt: USER_PRIVILEGE.editSalesReceipts,
  salesorder: USER_PRIVILEGE.editSalesOrders,
  shipment: USER_PRIVILEGE.editShipments,
  transfer: USER_PRIVILEGE.transferInventory,
  vendor: USER_PRIVILEGE.editVendors,
  workorder: USER_PRIVILEGE.editWorkOrders,
};

// note that the isAdmin, isSupport, and canEditObjectType
// functions are not directly exported, but are wrapped with user privi-
// leges in the object returned from this hook; because of this, the signature
// of those returned functions should be noted...they do not need the user's
// privileges to be passed in

function isAdmin(userPrivileges) {
  return userPrivileges && userPrivileges.includes(USER_PRIVILEGE.admin);
}

function isSupport(userPrivileges) {
  if (!userPrivileges) {
    return false;
  }
  return (
    userPrivileges.includes(USER_PRIVILEGE.sosSupport) ||
    userPrivileges.includes(USER_PRIVILEGE.sysAdmin)
  );
}

// returns true if the logged-in user is authorized for a given feature
// or function; to return true, the user must *either* be an admin-
// istrator on the company, or have at least one of the passed-in roles;
// this function *is* exported, because it is used outside of this hook
// in at least one place as of this writing
export function hasPrivilegesOrIsAdmin(roles, userPrivileges) {
  if (!userPrivileges) {
    return false;
  }

  if (isAdmin(userPrivileges)) {
    return true;
  }

  if (Array.isArray(roles)) {
    return roles.some((role) => userPrivileges.includes(role));
  }
  return userPrivileges.includes(roles);
}

// returns true if the logged-in user is authorized for editing the given object
function canEditObjectType(objectType, userPrivileges) {
  return hasPrivilegesOrIsAdmin(
    EDIT_PRIVILEGES_BY_OBJECT[objectType],
    userPrivileges
  );
}

export function usePrivileges() {
  const userPrivileges = useSelector(
    (state) => state.userCompanySettings.company.userPrivileges
  );

  return {
    hasPrivilegesOrIsAdmin: (roles) =>
      hasPrivilegesOrIsAdmin(roles, userPrivileges),
    canEditObjectType: (objectType) =>
      canEditObjectType(objectType, userPrivileges),
    isAdmin: isAdmin(userPrivileges),
    isSupport: isSupport(userPrivileges),
  };
}
