import {
  SERVER,
  LOGIN_PATH,
  DAYS_TO_NEXT_LOGIN_MS,
  AUTH_PATH,
} from "appConfig";

import { i18n } from "services/i18nService";
import { removeItemInStorage } from "services/localStorageManager";
import { log } from "services/logService";
import { retrieveSettings } from "services/sosInventoryService/settings";
import { getSupportPrivileges } from "services/sosInventoryService/sosApi";

import {
  setNeedsCompanyChooser,
  setIsLoggedIn,
} from "globalState/authenticationSlice";
import { setBearerToken as setBearer } from "globalState/bearerTokenSlice";
import globalState from "globalState/globalState";
import {
  setUser,
  reset,
  setCompany,
  setCompanyList,
  setSupportPrivileges,
} from "globalState/userCompanySettingsSlice";

import { SOS_EMAIL_DOMAIN } from "appConstants";

var bearerToken = "";

const EMAIL_NOT_VERIFIED = "Email Not Verified";

export function getBearerToken() {
  return bearerToken?.length ? bearerToken : globalState.getState().bearerToken;
}

export function setBearerToken(token) {
  bearerToken = token;
  globalState.dispatch(setBearer(token));
}

export async function login(email, password, errorHandler, loadingHandler) {
  loadingHandler(true);
  const response = await fetch(SERVER + LOGIN_PATH, {
    method: "POST",
    body: JSON.stringify({ user: email, pass: password, source: "web" }),
  });

  const loginResponse = await response.json();
  const loginError = validateLoginResponse(loginResponse);
  if (loginError) {
    errorHandler(loginError);
    loadingHandler(false);
    return;
  }

  globalState.dispatch(setUser({ name: loginResponse.name, email }));

  const { selectedCompanyId, company, companies, needsCompanyChooser } =
    getCompanyData(loginResponse);

  globalState.dispatch(setNeedsCompanyChooser(needsCompanyChooser));
  globalState.dispatch(setCompany(company));
  globalState.dispatch(setCompanyList(companies));

  await updateTokens({
    email,
    password,
    loginResponse,
    selectedCompanyId,
    errorHandler,
    grantType: "password",
  });

  if (email.endsWith(SOS_EMAIL_DOMAIN)) {
    const { privileges } = await getSupportPrivileges();
    globalState.dispatch(setSupportPrivileges(privileges));
  }

  await retrieveSettings();

  setLastLoginCookie();

  globalState.dispatch(setIsLoggedIn("yes"));

  loadingHandler(false);
  return;
}

export async function thirdPartyLogin(
  authCode,
  realmId,
  error,
  errorHandler,
  loadingHandler
) {
  loadingHandler(true);
  // env is used by BE/QBO to validate the redirect URI
  // possible values - "prod", "beta", "test", and "sandbox"
  // each of these values correspond to a white listed redirect URI for QBO
  // "sandbox" not working until https://app.clickup.com/t/2p83rup complete
  const env = process.env.REACT_APP_ENVIRONMENT;
  const body = JSON.stringify({ authCode, realmId, error, env });
  const response = await fetch(SERVER + AUTH_PATH, { method: "POST", body });

  if (response.status === 500) {
    errorHandler(i18n("auth.IntuitError"));
    return;
  }

  const loginResponse = await response.json();
  if (response.status === 401) {
    const unauthorizedMessage =
      loginResponse?.status === EMAIL_NOT_VERIFIED
        ? i18n("auth.IntuitEmailNotVerified")
        : i18n("auth.IntuitError");
    errorHandler(unauthorizedMessage);
    return;
  }

  const loginError = validateLoginResponse(loginResponse);
  if (loginError) {
    errorHandler(loginError);
    loadingHandler(false);
    return;
  }

  const { name, userEmail } = loginResponse;
  globalState.dispatch(setUser({ name, email: userEmail }));

  const { selectedCompanyId, company, companies, needsCompanyChooser } =
    getCompanyData(loginResponse);
  globalState.dispatch(setNeedsCompanyChooser(needsCompanyChooser));
  globalState.dispatch(setCompany(company));
  globalState.dispatch(setCompanyList(companies));

  const { accessToken, accessTokenSecret } = company;
  await updateTokens({
    email: loginResponse.userEmail,
    accessToken,
    accessTokenSecret,
    loginResponse,
    selectedCompanyId,
    errorHandler,
    grantType: "client_credentials",
  });

  if (userEmail.endsWith(SOS_EMAIL_DOMAIN)) {
    const { privileges } = await getSupportPrivileges();
    globalState.dispatch(setSupportPrivileges(privileges));
  }

  await retrieveSettings();

  setLastLoginCookie();
  loadingHandler(false);
  globalState.dispatch(setIsLoggedIn("yes"));

  return;
}

async function updateTokens({
  email,
  password,
  accessToken,
  accessTokenSecret,
  loginResponse,
  selectedCompanyId,
  errorHandler,
  grantType,
}) {
  const tokenParams = getTokenParams(grantType, {
    email,
    password,
    accessToken,
    accessTokenSecret,
  });
  if (!tokenParams) {
    errorHandler(i18n("auth.Invalid"));
  }

  let tokens = {};

  const promises = loginResponse.companyIds.map(async (companyId) => {
    const params = { ...tokenParams, scope: companyId };
    const body = Object.keys(params)
      .map((key) => key + "=" + encodeURIComponent(params[key]))
      .join("&");

    const response = await fetch(SERVER + "/oauth2/token", {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Host: SERVER,
      },
      credentials: "include",
    });
    const json = await response.json();
    if (response.ok) {
      const token = {
        email,
        token: json.access_token,
        refresh: json.refresh_token,
      };
      tokens[companyId] = token;
    } else {
      errorHandler(json.error_description);
    }
  });

  await Promise.all(promises);
  setBearerToken(tokens[selectedCompanyId]?.token);
}

function getCompanyData(loginResponse) {
  let selectedCompanyIdx = 0;
  let needsCompanyChooser;
  if (loginResponse.defaultCompanyIndex > -1) {
    selectedCompanyIdx = loginResponse.defaultCompanyIndex;
    needsCompanyChooser = false;
  } else if (loginResponse.companyIds.length === 1) {
    needsCompanyChooser = false;
  } else {
    needsCompanyChooser = true;
  }
  const selectedCompanyId = loginResponse.companyIds[selectedCompanyIdx];
  let companies = [];
  for (let idx = 0; idx < loginResponse.companyIds.length; idx++) {
    const company = {
      id: loginResponse.companyIds[idx],
      userId: loginResponse.userIds[idx],
      name: loginResponse.companyNames[idx],
      accessToken: loginResponse.accessTokens[idx],
      accessTokenSecret: loginResponse.accessTokenSecrets[idx],
      expirationDate: loginResponse.expirationDates[idx],
      userPrivileges: loginResponse.permissionsList[idx],
      planLevel: loginResponse.planLevels[idx],
      status: loginResponse.statusList[idx],
      accountNumber: loginResponse.acctNumbers[idx],
      default: idx === loginResponse.defaultCompanyIndex,
    };
    companies.push(company);
  }
  const company = companies[selectedCompanyIdx];
  companies = companies.sort((a, b) => (a.name < b.name ? -1 : 1));

  return { selectedCompanyId, company, companies, needsCompanyChooser };
}

function validateLoginResponse(loginResponse) {
  if (loginResponse.status === "Error: Unknown error.") {
    return i18n("global.SomethingWentWrong");
  } else if (loginResponse.status === "invalid") {
    return i18n("auth.Invalid");
  } else if (loginResponse.status === "locked") {
    return i18n("auth.Locked");
  } else if (!loginResponse.companyIds.length) {
    return i18n("auth.NonUser");
  }
  return null;
}

function getTokenParams(grantType, authData) {
  const { email, password, accessToken, accessTokenSecret } = authData;
  const rememberMe = Boolean(localStorage.getItem("rememberMe"));
  switch (grantType) {
    case "password":
      return {
        username: email,
        password: password,
        grant_type: "password",
        client_id: "sos_web",
        scope: "",
        rememberMe,
      };
    case "client_credentials":
      return {
        grant_type: "client_credentials",
        client_id: accessToken,
        client_secret: accessTokenSecret,
        rememberMe,
      };
    default:
      return null;
  }
}

function setLastLoginCookie() {
  const expires = new Date(Date.now() + DAYS_TO_NEXT_LOGIN_MS);
  document.cookie = `lastLogin=${expires}; expires=${expires.toUTCString()}; SameSite=Strict; path=/;`;
}

export function logout() {
  // call to server to clear the refresh token cookies; we don't care about
  // the result of this call; it sometimes returns a 500, but we've
  // determined that it's not an issue (https://app.clickup.com/t/2d4dj0n)
  try {
    fetch(SERVER + "/oauth2/token/logout", {
      method: "POST",
      credentials: "include",
      headers: new Headers({
        Authorization: "Bearer " + getBearerToken(),
      }),
    });
  } catch (error) {
    log.error(error);
  }
  setBearerToken("");
  removeItemInStorage("userCompanySettings");
  document.cookie =
    "lastLogin=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
  globalState.dispatch(setIsLoggedIn("no"));
  globalState.dispatch(reset());
}

export async function setAuthenticationState() {
  const currentCompany = globalState.getState().userCompanySettings.company.id;

  let isLoggedIn;
  if (document.cookie.indexOf("lastLogin=") === -1) {
    isLoggedIn = "no";
  } else if (getBearerToken()) {
    isLoggedIn = "yes";
  } else if (currentCompany) {
    await getRefreshedBearerToken(currentCompany);
    isLoggedIn = getBearerToken() ? "yes" : "no";
  } else {
    isLoggedIn = "no";
  }
  globalState.dispatch(setIsLoggedIn(isLoggedIn));
}

export async function getRefreshedBearerToken(company) {
  const params = {
    grant_type: "refresh_token",
    refresh_token: "ID:" + company,
    rememberMe: Boolean(localStorage.getItem("rememberMe")),
  };

  const queryString = Object.keys(params)
    .map((key) => key + "=" + encodeURIComponent(params[key]))
    .join("&");
  const response = await fetch(SERVER + "/oauth2/token", {
    method: "POST",
    body: queryString,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Host: SERVER,
    },
    credentials: "include",
  });
  const json = await response.json();

  if (response.ok) {
    setBearerToken(json.access_token);
  }
}
