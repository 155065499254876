import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Card, CardContent } from "@mui/material";

import { Layout } from "Layout";

import { CustomFields } from "components/CustomFields/CustomFields";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { QuickAddCustomer } from "components/QuickAddCustomer";
import { FrmTextField } from "components/formFields/FrmTextField";
import { LineItemTotals } from "components/formFields/Totals/Sales/LineItemTotals";
import { Addresses } from "components/formFields/address/sales/Addresses";
import {
  FrmNumber,
  FrmCustomerWithAdd,
  FrmCustomerNotes,
  FrmCustomerMessage,
  FrmSalesRep,
  FrmChannel,
  FrmTerms,
  FrmTransactionLocation,
  FrmAssignedTo,
  FrmPriority,
  FrmOrderStage,
  FrmLocation,
  FrmDeposit,
  FrmCardInfo,
  FrmStatusMessage,
  FrmStatusLink,
  FrmComment,
  FrmDepartment,
} from "components/formFields/frm";
import { FrmCurrencyExchangeRate } from "components/formFields/frm";
import { FrmApplyPriceTier } from "components/formFields/frm";
import { FrmCheckbox } from "components/formFields/frm/FrmCheckbox";
import { FrmSignature } from "components/formFields/frm/FrmSignature";
import { DateTimePicker } from "components/html/Input/DateTimePicker";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";
import { WarningModal } from "components/utility/WarningModal";

import { i18n } from "services/i18nService";
import {
  refreshTierRates,
  setDiscount,
} from "services/sosInventoryService/salesTransaction/domainLogic";
import {
  updateTotals,
  ZERO_TOTALS,
} from "services/sosInventoryService/salesTransaction/updateTotals";
import { postRecord } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";
import { handleRefreshExchangeRate } from "services/utility/edit";
import { isPersistedRecord } from "services/utility/misc";

import { useCustomFieldDefsAsObject } from "hooks/useCustomFieldDefinitions";
import { useCustomers } from "hooks/useCustomers";
import { useErrors } from "hooks/useErrors";
import { useItems } from "hooks/useItems";
import { useLineHandler } from "hooks/useLineHandler";
import { usePlans } from "hooks/usePlans";
import { usePrivileges } from "hooks/usePrivileges";
import { useRelated } from "hooks/useRelated";
import { useTaxCodes, TAX_CODE_TYPE_SALES } from "hooks/useTaxCodes";

import { updateTransaction } from "views/SalesOrders/SalesOrder/controller";
import { LineItems } from "views/SalesTransactions/LineItems";
import { getTransaction } from "views/SalesTransactions/controller";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { theme } from "SosTheme";
import {
  OBJECT_TYPES,
  USER_PRIVILEGE,
  ITEM_QUICKLIST_CASES,
} from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.SALES_ORDER.fullString;

const CUSTOM_FIELD_OBJECT_TYPES = [
  OBJECT_TYPE,
  OBJECT_TYPES.CUSTOMER.fullString,
];

export function SalesOrder(props) {
  const { id, onClose, newFromId, newFromObjectType } = props;

  // TRANSACTION STATE
  const [record, setRecord] = useState();
  const [relatedRecords, setRelatedRecords] = useState({});
  const [totals, setTotals] = useState(ZERO_TOTALS);
  const [lines, lineHandler] = useLineHandler();
  const CURRENT_STATE = { record, lines, relatedRecords, totals };

  // UI STATE
  const { errors, setErrors, isInError } = useErrors();
  const [clearLines, setClearLines] = useState(false);
  const customFieldDefs = useCustomFieldDefsAsObject(CUSTOM_FIELD_OBJECT_TYPES);
  const [addNewCustomer, setAddNewCustomer] = useState(false);
  const [newCustomerName, setNewCustomerName] = useState("");
  const [customerAdded, setCustomerAdded] = useState(0);
  const [itemAdded, setItemAdded] = useState(0);
  const loadingState = useSelector((state) => state.loading.editModal);

  // RELATED RECORD LISTS
  const taxCodes = useTaxCodes(TAX_CODE_TYPE_SALES);
  const { items, itemsCount } = useItems(ITEM_QUICKLIST_CASES.SALES, itemAdded);
  const customers = useCustomers(customerAdded);
  const classes = useRelated("class");
  const currencies = useRelated("currency");

  // SETTINGS
  const { hasPrivilegesOrIsAdmin } = usePrivileges();
  const showPayments = hasPrivilegesOrIsAdmin([USER_PRIVILEGE.processPayment]);

  const customerPortalEnabled = useSelector(
    (state) => state.userCompanySettings.settings.customerPortalEnabled
  );
  const departmentEnabled = useSelector(
    (state) => state.userCompanySettings.settings.departmentEnabled
  );
  const quickbooksCountry = useSelector(
    (state) => state.userCompanySettings.settings.quickbooksCountry
  );
  const { hasAtLeastPlusPlan } = usePlans();

  // OTHER SETUP
  const dispatch = useDispatch();

  // UPDATERS FOR CONTROLLER TO UPDATE STATE
  const UPDATERS = useMemo(
    () => ({
      setRecord,
      lineHandler,
      setRelatedRecords,
    }),
    [setRecord, lineHandler]
  );

  // LOAD INITIAL TRANSACTION STATE
  useEffect(() => {
    async function _getTransaction() {
      dispatch(editModalLoadingIndicatorOn());
      await getTransaction(
        id,
        newFromId,
        newFromObjectType,
        UPDATERS,
        customFieldDefs[OBJECT_TYPE],
        OBJECT_TYPE
      );
      dispatch(editModalLoadingIndicatorOff());
    }
    if (customFieldDefs) {
      _getTransaction();
    }
  }, [
    dispatch,
    id,
    newFromId,
    newFromObjectType,
    lineHandler,
    UPDATERS,
    customFieldDefs,
  ]);

  useEffect(
    () =>
      updateTotals(setTotals, record, lines, taxCodes, relatedRecords.customer),
    [record, lines, taxCodes, relatedRecords.customer]
  );

  useEffect(() => setDiscount(lines, setRecord), [lines, setRecord]);

  // HANDLE ALL USER INPUT
  function handleInputFieldChange(field, newValue) {
    setPageDirty();
    updateTransaction(
      field,
      newValue,
      customFieldDefs[OBJECT_TYPE],
      customFieldDefs.customer,
      CURRENT_STATE,
      taxCodes,
      UPDATERS,
      currencies
    );
  }

  async function handleQuickAddNewCustomer(name) {
    const newObject = { name, showOnForms: true };

    dispatch(editModalLoadingIndicatorOn());
    const { success, record, message } = await postRecord(
      "customer",
      newObject
    );
    if (success) {
      setCustomerAdded((prev) => prev + 1);
      setRecord((prevRecord) => ({
        ...prevRecord,
        customer: { id: record.id, name: record.name },
      }));
    } else {
      setErrors((prev) => ({ ...prev, messages: [message] }));
    }
    dispatch(editModalLoadingIndicatorOff());
    setPageDirty();
    setAddNewCustomer(false);
  }

  async function handleAddNewCustomer(customer) {
    setPageDirty();
    handleInputFieldChange("customer", customer);
    setCustomerAdded((prev) => prev + 1);
    setAddNewCustomer(false);
  }

  function doClearLines() {
    setPageDirty();
    lineHandler({ type: "clear", blankLine: EMPTY_LINE_ITEM[OBJECT_TYPE] });
    setClearLines(false);
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        lines={lines}
        setErrors={setErrors}
        errors={errors}
        text={record?.number}
        handleClose={onClose}
        customerId={record?.customer?.id}
      />
      {loadingState && <Loading />}
      <ObjectEditDialogContent>
        <Layout
          pageTitle={
            id
              ? `${i18n("objectType.salesorder.Sentence")} ${
                  record?.number || ""
                }`
              : i18n("objectType.salesorder.New")
          }
        >
          <div id="B" style={{ margin: theme.spacing(2) }}>
            <FormErrors errors={errors} setErrors={setErrors} />
            {record ? (
              <>
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }}>
                    <DateTimePicker
                      name="date"
                      value={record.date}
                      label={i18n("frmLabel.DateTime")}
                      onValueChange={handleInputFieldChange}
                      isZoneless={true}
                      error={isInError("date")}
                      dataTesting="date"
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmNumber
                      recordId={record.id}
                      label={i18n("salesOrder.labelNumber")}
                      value={record.number}
                      onValueChange={handleInputFieldChange}
                      error={isInError("number")}
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmLocation
                      value={record.location}
                      error={isInError("location")}
                      onValueChange={handleInputFieldChange}
                      width="25rem"
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmCustomerWithAdd
                      value={record.customer}
                      onValueChange={handleInputFieldChange}
                      setAddNewCustomer={setAddNewCustomer}
                      setNewCustomerName={setNewCustomerName}
                      error={isInError("customer")}
                      customers={customers}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmCustomerNotes
                      value={record.customerNotes}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmCheckbox
                      name="dropShip"
                      value={record.dropShip}
                      onValueChange={handleInputFieldChange}
                      label={i18n("frmLabel.DropShip")}
                      sx={{ marginRight: "8px" }}
                    />
                  </div>
                </FrmFieldGrid>
                <VSpace space={1} />
                <Addresses
                  billing={record.billing}
                  shipping={record.shipping}
                  customer={relatedRecords.customer}
                  handleInputFieldChange={handleInputFieldChange}
                  billingLabel={i18n("address.Billing")}
                  shippingLabel={i18n("address.Shipping")}
                />
                <VSpace space={2} />

                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmCurrencyExchangeRate
                      customerOrVendor={relatedRecords.customer}
                      record={record}
                      currencies={currencies}
                      currencyError={isInError("currency")}
                      exchangeRate={record.exchangeRate}
                      handleInputChange={handleInputFieldChange}
                      handleRefreshExchangeRate={() =>
                        handleRefreshExchangeRate(
                          currencies,
                          record,
                          handleInputFieldChange
                        )
                      }
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    {hasAtLeastPlusPlan && (
                      <div>
                        <FrmApplyPriceTier
                          onValueChange={handleInputFieldChange}
                          value={record?.priceTier}
                          refreshTierRates={(tier) =>
                            refreshTierRates(tier, lines, lineHandler)
                          }
                        />
                      </div>
                    )}
                  </div>
                </FrmFieldGrid>

                <VSpace space={1} />
                <LineItems
                  record={record}
                  objectType={OBJECT_TYPE}
                  customer={relatedRecords.customer}
                  lines={lines}
                  taxCodes={taxCodes}
                  items={items}
                  totals={totals}
                  itemsCount={itemsCount}
                  addItem={() => setItemAdded((prev) => prev + 1)}
                  classes={classes}
                  lineHandler={lineHandler}
                  setClearLines={setClearLines}
                  lineItemsErrors={errors.line}
                  setErrors={setErrors}
                  showPicked={true}
                  showShipped={true}
                  showDueDate={true}
                  showInvoiced={true}
                />

                <VSpace space={2} />

                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }} />
                  <div style={{ gridColumn: "2 / 2" }}>
                    <LineItemTotals
                      objectType={OBJECT_TYPE}
                      totals={totals}
                      record={record}
                      setRecord={setRecord}
                      handleInputFieldChange={handleInputFieldChange}
                      taxCodes={taxCodes}
                    />
                  </div>
                </FrmFieldGrid>

                <VSpace space={4} />

                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmTerms
                      maxWidth="20rem"
                      value={record.terms}
                      onValueChange={handleInputFieldChange}
                      error={isInError("terms")}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmSalesRep
                      maxWidth="20rem"
                      onValueChange={handleInputFieldChange}
                      value={record?.salesRep}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmAssignedTo
                      maxWidth="20rem"
                      onValueChange={handleInputFieldChange}
                      value={record?.assignedToUser}
                    />
                  </div>
                  {departmentEnabled && (
                    <div style={{ gridColumn: "1 / 2" }}>
                      <FrmDepartment
                        maxWidth="20rem"
                        value={record.department}
                        onValueChange={handleInputFieldChange}
                      />
                    </div>
                  )}
                  <div style={{ gridColumn: "1 / 2" }}>
                    <Card variant="outlined" sx={{ maxWidth: "20rem" }}>
                      <CardContent
                        sx={{ "&:last-child": { paddingBottom: "0.5rem" } }}
                      >
                        <FrmDeposit
                          maxWidth="20rem"
                          amountValue={record.depositAmount}
                          percentValue={record.depositPercent}
                          onValueChange={handleInputFieldChange}
                          amountError={isInError("depositAmount")}
                          percentError={isInError("depositPercent")}
                        />
                        {showPayments && !isPersistedRecord(record?.id) && (
                          <FrmCardInfo
                            showKeepOnFile={record.showKeepOnFile}
                            customer={record.customer}
                            onValueChange={handleInputFieldChange}
                            postalCode={record.billing?.postalCode}
                            contact={record.billing?.contact}
                            paymentInfo={record.paymentInfo}
                            storeCustomerToken={record.storeCustomerToken}
                            hasCardOnFile={record.hasCardOnFile}
                          />
                        )}
                      </CardContent>
                    </Card>
                  </div>
                  {(quickbooksCountry === "IN" ||
                    quickbooksCountry === "FR") && (
                    <div style={{ gridColumn: "1 / 2" }}>
                      <FrmTransactionLocation
                        onValueChange={handleInputFieldChange}
                        value={record?.transactionLocationQuickBooks}
                      />
                    </div>
                  )}
                  {id && customerPortalEnabled && (
                    <>
                      <div style={{ gridColumn: "1 / 2" }}>
                        <FrmStatusLink value={record?.statusLink} />
                      </div>
                      <div style={{ gridColumn: "1 / 2" }}>
                        <FrmStatusMessage
                          onValueChange={handleInputFieldChange}
                          value={record?.statusMessage}
                        />
                      </div>
                    </>
                  )}
                  <div style={{ gridColumn: "1 / 2", maxWidth: "100%" }}>
                    <FrmCustomerMessage
                      value={record.customerMessage}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmComment
                      value={record.comment}
                      onValueChange={handleInputFieldChange}
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmChannel
                      maxWidth="20rem"
                      onValueChange={handleInputFieldChange}
                      value={record?.channel}
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmPriority
                      maxWidth="20rem"
                      onValueChange={handleInputFieldChange}
                      value={record?.priority}
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmOrderStage
                      maxWidth="20rem"
                      onValueChange={handleInputFieldChange}
                      value={record?.orderStage}
                    />
                  </div>
                  <div style={{ gridColumn: "2 / 2" }}>
                    <FrmTextField
                      name="customerPO"
                      label={i18n("frmLabel.CustomerPO")}
                      value={record?.customerPO}
                      onValueBlur={handleInputFieldChange}
                      sx={{ maxWidth: "20rem" }}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmSignature
                      objectType={OBJECT_TYPE}
                      id={record.id}
                      hasSignature={record.hasSignature}
                    />
                  </div>
                </FrmFieldGrid>

                <CustomFields
                  customFieldDefinitions={customFieldDefs[OBJECT_TYPE]}
                  customFields={record.customFields}
                  onValueChange={handleInputFieldChange}
                  setErrors={setErrors}
                />
              </>
            ) : (
              !isInError("record") && (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )
            )}
            <VSpace space={4} />
          </div>
          {record && (
            <>
              {!!addNewCustomer && (
                <QuickAddCustomer
                  open
                  initialNameValue={newCustomerName}
                  onClose={() => setAddNewCustomer(false)}
                  onQuickAdd={handleQuickAddNewCustomer}
                  onAdd={handleAddNewCustomer}
                />
              )}
              <WarningModal
                title={i18n("global.ClearLines")}
                message={i18n("global.clearLinesMessage")}
                open={clearLines}
                onOk={doClearLines}
                onClose={() => setClearLines(false)}
              />
            </>
          )}
        </Layout>
      </ObjectEditDialogContent>
    </>
  );
}
