import { useDispatch } from "react-redux";

import { ActionButton } from "components/ActionButton";
import { Tooltip } from "components/Tooltip";

import { i18n } from "services/i18nService";
import { workOrderproduceAll } from "services/sosInventoryService/sosApi";

import { openAlert } from "globalState/alertSlice";
import { openModal } from "globalState/modalSlice";

import { ITEM_TYPES } from "appConstants";
import { QUICK_VIEW_CONFIG } from "quickViewConfig";

function isAssemblyOrKit(itemDetails) {
  const { type } = itemDetails;
  return type === ITEM_TYPES.ASSEMBLY || type === ITEM_TYPES.KIT;
}

export function LineTable(props) {
  const { recordId, lines, showItemNames, showBackordered, objectType, type } =
    props;

  const dispatch = useDispatch();
  const tableLines = QUICK_VIEW_CONFIG[objectType].lines;

  const totalColPosition = tableLines.reduce(
    (seed, { isTotalColumn }, i) => (isTotalColumn ? i : seed),
    null
  );

  async function produceAll() {
    const { success } = await workOrderproduceAll(recordId);
    const message = i18n(
      success ? "alert.SuccessProduceAll" : "alert.ErrorProduceAll"
    );
    dispatch(openAlert({ type: success ? "success" : "error", message }));
  }

  function produce(id, itemDetails) {
    dispatch(
      openModal({
        objectType:
          itemDetails.type === ITEM_TYPES.ASSEMBLY ? "build" : "process",
        modalProps: { newFromObjectTypeLine: objectType, newFromId: id },
      })
    );
  }

  const initialColSpan = showItemNames
    ? totalColPosition - 1
    : totalColPosition - 2;

  const trailingColSpan = showItemNames
    ? tableLines.length - initialColSpan - 2
    : tableLines.length - initialColSpan - 3;

  return (
    <table>
      <thead>
        <tr>
          {tableLines.map(
            ({ name, heading, isItemField, isProduce, align }, i) => {
              if (
                (isItemField && !showItemNames) ||
                (name === "backOrdered" && !showBackordered)
              ) {
                return null;
              }
              if (isProduce) {
                return (
                  <th key={i} style={{ minWidth: "6rem", textAlign: align }}>
                    <Tooltip title={i18n("tooltip.ProduceAll")}>
                      <div>
                        <ActionButton
                          sx={{ m: "4px 0", p: "0px 8px" }}
                          onClick={produceAll}
                        >
                          {heading}
                        </ActionButton>
                      </div>
                    </Tooltip>
                  </th>
                );
              }
              return <th key={i}>{heading}</th>;
            }
          )}
        </tr>
      </thead>
      <tbody>
        {lines.map((line, i) => {
          const { isTotalsRow, isBlankRow, label, value, itemDetails, id } =
            line;
          if (isBlankRow || isTotalsRow) {
            return (
              <tr key={i}>
                <td colSpan={initialColSpan}>&nbsp;</td>
                <td style={{ textAlign: "right" }}>
                  {isBlankRow ? "" : label}
                </td>
                <td style={{ textAlign: "right" }}>
                  {isBlankRow ? "" : value}
                </td>
                {trailingColSpan > 0 && <td colSpan={trailingColSpan}></td>}
              </tr>
            );
          }
          return (
            <tr data-testing={`${type}-row`} key={i}>
              {tableLines.map(
                ({ name, formatFunc, align, isItemField, isProduce }, i) => {
                  if (
                    (isItemField && !showItemNames) ||
                    (name === "backOrdered" && !showBackordered)
                  ) {
                    return null;
                  }
                  if (isProduce && isAssemblyOrKit(itemDetails)) {
                    return (
                      <td style={{ textAlign: align }} key={i}>
                        <ActionButton
                          sx={{ m: "4px 0", p: "0px 8px" }}
                          onClick={() => produce(id, itemDetails)}
                        >
                          {i18n("global.Produce")}
                        </ActionButton>
                      </td>
                    );
                  }
                  return (
                    <td
                      data-testing={name}
                      style={{ textAlign: align, whiteSpace: "pre-wrap" }}
                      key={i}
                    >
                      {formatFunc ? formatFunc(line[name], line) : line[name]}
                    </td>
                  );
                }
              )}
            </tr>
          );
        })}
      </tbody>
      {tableLines.find(({ footerFunc }) => footerFunc) && (
        <tfoot>
          <tr data-testing={`${type}-footer`}>
            {tableLines.map(({ isItemField, footerFunc, name }, i) => {
              if (isItemField && !showItemNames) {
                return null;
              }
              return (
                <td data-testing={name} key={i}>
                  {footerFunc ? footerFunc(lines, name) : ""}
                </td>
              );
            })}
          </tr>
        </tfoot>
      )}
    </table>
  );
}
