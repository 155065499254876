import { i18n } from "services/i18nService";

export const SETTINGS = [
  {
    apiName: "autoSync",
    component: "FrmRadioGroup",
    options: [
      { value: "Hourly", label: i18n("settings.autoSyncOptions.Hourly") },
      { value: "Daily", label: i18n("settings.autoSyncOptions.Daily") },
      { value: "Weekly", label: i18n("settings.autoSyncOptions.Weekly") },
      { value: "Never", label: i18n("settings.autoSyncOptions.Never") },
    ],
  },
  {
    apiName: "syncSOStoQBO",
    component: "FrmCheckbox",
  },
  {
    apiName: "syncQBOtoSOS",
    component: "FrmCheckbox",
  },
  {
    apiName: "postCOGStoQB",
    component: "FrmCheckbox",
  },
  {
    apiName: "classTrackingEnabled",
    component: "FrmCheckbox",
  },
  {
    apiName: "departmentTrackingEnabled",
    component: "FrmCheckbox",
  },
];
