import { Box, Typography } from "@mui/material";

import { FrmEmail } from "components/formFields/FrmEmail";
import { FrmRadioGroup } from "components/formFields/FrmRadioGroup";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmTextField } from "components/formFields/FrmTextField";
import {
  FrmSalesTaxCode,
  FrmCheckbox,
  FrmLocation,
  FrmTerms,
} from "components/formFields/frm";
import { FrmAccount } from "components/formFields/frm/FrmAccount";
import { FrmBarcodeSettings } from "components/formFields/frm/FrmBarcodeSettings";
import { FrmCustomerPortalSubdomain } from "components/formFields/frm/FrmCustomerPortalSubdomain";
import { FrmImageSize } from "components/formFields/frm/FrmImageSize";
import { FrmPaymentMethod } from "components/formFields/frm/FrmPaymentMethod";
import { FrmSerialLotNumbering } from "components/formFields/frm/FrmSerialLotNumbering";
import { FrmTransactionNumber } from "components/formFields/frm/FrmTransactionNumber";

import { i18n } from "services/i18nService";
import { handleProgramError } from "services/utility/errors";

import { usePlans } from "hooks/usePlans";

const baseSettingSx = {
  pt: 1,
  pb: 1,
  px: 2,
  gap: 2,
  display: "flex",
  alignItems: "flex-start",
  "&:hover": {
    backgroundColor: "tableRowHover",
    "& .groupingBoxLabel": { backgroundColor: "tableRowHover" },
  },
  "& .MuiAutocomplete-root": { position: "relative", top: "-1.0rem" },
};

const selectedSettingSx = {
  "@keyframes background-color": {
    "0%": {
      backgroundColor: "inherit",
    },
    "50%": {
      backgroundColor: "configurationSearch.selectedItem",
    },
    "100%": {
      backgroundColor: "inherit",
    },
  },
  animation: "background-color 2s ease",
};

export function Setting(props) {
  const {
    setting,
    level1,
    level2,
    value,
    allSettingsValues,
    onValueChange,
    selectedFromSearch,
  } = props;
  const {
    apiName,
    component,
    planIncludesSetting,
    maxLength,
    width,
    transactionType,
    options,
    displayIf,
    explanationOverride,
    number,
    hasInputLabel,
  } = setting;

  const SETTING_SX = selectedFromSearch
    ? { ...baseSettingSx, ...selectedSettingSx }
    : baseSettingSx;

  const { hasAtLeastPlusPlan, hasAtLeastProPlan } = usePlans();

  function createInputField(component, apiName) {
    switch (component) {
      case "FrmCheckbox":
        return (
          <FrmCheckbox
            name={apiName}
            value={value}
            onValueChange={onValueChange}
            disabled={
              planIncludesSetting &&
              !planIncludesSetting(hasAtLeastPlusPlan, hasAtLeastProPlan)
            }
          />
        );
      case "FrmTerms":
        return (
          <FrmTerms
            label=""
            name={apiName}
            value={value}
            onValueChange={onValueChange}
            disableClearable
          />
        );
      case "FrmSalesTaxCode":
        return (
          <FrmSalesTaxCode
            name={apiName}
            value={value}
            onValueChange={onValueChange}
          />
        );
      case "FrmPaymentMethod":
        return (
          <FrmPaymentMethod
            name={apiName}
            value={value}
            onValueChange={onValueChange}
            disableClearable
          />
        );
      case "FrmRadioGroup":
        return (
          <FrmRadioGroup
            name={apiName}
            options={options}
            value={value}
            onValueChange={onValueChange}
          />
        );
      case "FrmImageSize":
        return (
          <FrmImageSize
            name={apiName}
            value={value}
            onValueChange={onValueChange}
            disabled={
              planIncludesSetting &&
              !planIncludesSetting(hasAtLeastPlusPlan, hasAtLeastProPlan)
            }
          />
        );
      case "FrmTextField":
        return (
          <FrmTextField
            name={apiName}
            value={value}
            number={number}
            onValueBlur={onValueChange}
            disabled={
              planIncludesSetting &&
              !planIncludesSetting(hasAtLeastPlusPlan, hasAtLeastProPlan)
            }
            maxLength={maxLength}
            sx={{ width, position: "relative", top: "-1.0rem" }}
          />
        );
      case "FrmTransactionNumber":
        return (
          <FrmTransactionNumber
            name={apiName}
            value={value}
            onValueChange={onValueChange}
            sx={{ width, position: "relative", top: "-1.0rem" }}
          />
        );
      case "FrmAccount":
        return (
          <FrmAccount
            name={apiName}
            value={value}
            onValueChange={onValueChange}
            accountType={setting.accountType}
            hideHelpText
            disableClearable
          />
        );
      case "FrmResizeTextField":
        return (
          <FrmResizeTextField
            minRows={2}
            name={apiName}
            onValueBlur={onValueChange}
            width="95%"
            value={value}
            sx={{ position: "relative", top: "-1.0rem" }}
          />
        );
      case "FrmSerialLotNumbering":
        return (
          <FrmSerialLotNumbering
            name={apiName}
            value={value}
            onValueChange={onValueChange}
          />
        );
      case "FrmEmail":
        return (
          <FrmEmail
            name={apiName}
            value={value}
            onBlur={onValueChange}
            showHoverHelp={false}
            sx={{ position: "relative", top: "-1.0rem" }}
          />
        );
      case "FrmLocation":
        return (
          <FrmLocation
            name={apiName}
            label=""
            value={value}
            onValueChange={onValueChange}
          />
        );
      case "FrmCustomerPortalSubdomain":
        return (
          <FrmCustomerPortalSubdomain
            name={apiName}
            value={value}
            onValueChange={onValueChange}
            sx={{ position: "relative", top: "-1.0rem" }}
          />
        );
      case "FrmBarcodeSettings":
        return (
          <FrmBarcodeSettings
            name={apiName}
            value={value}
            onValueChange={onValueChange}
            sx={{ position: "relative", top: "-1.0rem" }}
          />
        );
      default:
        handleProgramError(
          new Error(`Unexpected value for component: ${component}`)
        );
    }
  }

  let explanation;
  if (explanationOverride) {
    explanation = (
      <Typography component="span" sx={{ fontStyle: "italic" }}>
        {explanationOverride}
      </Typography>
    );
  }
  if (component === "FrmTransactionNumber") {
    explanation = i18n("settings.transactionNumbering.explanation", {
      transactionType,
    });
  }
  if (!explanation) {
    explanation = i18n(`settings.${level1}.${level2}.${apiName}.explanation`);
  }

  // displayIf turns this setting on or off based on another setting on
  // this page
  if (displayIf && !allSettingsValues[displayIf]) {
    return null;
  }
  const pt = hasInputLabel ? 1 : 0.5;

  return (
    <>
      <Box sx={SETTING_SX} id={apiName}>
        <Box sx={{ flexBasis: "25%", pt }}>
          <Typography sx={{ fontWeight: "medium" }}>
            {i18n(`settings.${level1}.${level2}.${apiName}.label`, {
              transactionType,
            })}
          </Typography>
        </Box>
        <Box sx={{ flexBasis: "35%", alignItems: "top", pt }}>
          {createInputField(component, apiName)}
        </Box>
        <Box sx={{ flexBasis: "40%", paddingLeft: 2, pt }}>
          <Typography component="span" sx={{ fontStyle: "italic" }}>
            {explanation}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
