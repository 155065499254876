import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, Typography, Button } from "@mui/material";

import { MerchantTrack } from "components/MerchantTrack";

import { i18n } from "services/i18nService";
import { getListHeaderText } from "services/utility/list";
import { formatListPageFilterList } from "services/utility/subheaders";

import { OBJECT_TYPES } from "appConstants";

export function Header(props) {
  const { objectType, retrievalParams, totalRecords, statusQueryParam } = props;

  const dateFormat = useSelector(
    (state) => state.userCompanySettings.localization.dateFormat
  );
  const showUpsShipments = useSelector(
    (state) => state.userCompanySettings.settings.showUpsShipments
  );
  const navigate = useNavigate();

  const [subheaderText, setSubheaderText] = useState("");

  useEffect(() => {
    async function getSubheader() {
      if (retrievalParams && retrievalParams.filterSettings) {
        const formattedFilters = await formatListPageFilterList(
          objectType,
          retrievalParams.filterSettings,
          statusQueryParam
        );
        setSubheaderText(formattedFilters);
      }
    }
    getSubheader();
  }, [objectType, retrievalParams, dateFormat, statusQueryParam]);

  function getObjectHeaderButton() {
    switch (objectType) {
      case OBJECT_TYPES.FORM_TEMPLATE.fullString:
        return (
          <Button
            onClick={() => navigate("/configuration/list/templatelibrary")}
          >
            {i18n("objectType.templatelibrary.SentencePlural")}
          </Button>
        );
      case OBJECT_TYPES.PAYMENT.fullString:
        return (
          <Button onClick={() => navigate("/fspayment")}>
            {i18n("objectType.fspayment.SentencePlural")}
          </Button>
        );
      case OBJECT_TYPES.SHIPMENT.fullString:
        return showUpsShipments ? (
          <Button onClick={() => navigate("/upsshipment")}>
            {i18n("objectType.upsshipment.SentencePlural")}
          </Button>
        ) : null;
      case OBJECT_TYPES.FS_PAYMENT.fullString:
        return <MerchantTrack />;

      default:
        return null;
    }
  }

  return (
    <div data-testing="listPageHeader">
      <Box
        mb={1}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <Box>
          <Typography variant="h2">
            {i18n(`objectType.${objectType}.SentencePlural`)}
          </Typography>
          <Typography>{subheaderText}</Typography>
        </Box>
        <Box>
          {getObjectHeaderButton()}
          <div>
            <Typography sx={{ float: "right", pr: "8px" }}>
              {getListHeaderText(objectType, totalRecords, retrievalParams)}
            </Typography>
          </div>
        </Box>
      </Box>
    </div>
  );
}
