import { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Switch, FormControlLabel, Box, Typography } from "@mui/material";

import { ImageFromBase64 } from "components/ImageFromBase64";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { QuickViewHeader } from "components/QuickViewHeader";
import { BuildOutput } from "components/quickView/BuildOutput";
import { CommentTable } from "components/quickView/CommentTable";
import { CustomFields } from "components/quickView/CustomFields";
import { LineTable } from "components/quickView/LineTable";
import { Location } from "components/quickView/Location";
import { RecordFields } from "components/quickView/RecordFields";
import { SignatureLines } from "components/quickView/SignatureLines";
import { StatusLink } from "components/quickView/StatusLink";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import {
  deleteRecord,
  getRecord,
  getList,
  getRecordWithParams,
} from "services/sosInventoryService/sosApi";
import { formatMoneyWithAdornments } from "services/utility/formatting";
import { formatCompanyInfo } from "services/utility/formatting";
import { isNonZeroDecimalOrMoney } from "services/utility/misc";

import { useCustomFieldDefinitions } from "hooks/useCustomFieldDefinitions";
import { useLogo } from "hooks/useLogo";
import { usePlans } from "hooks/usePlans";

import { openAlert } from "globalState/alertSlice";

import { getObjectFromFullString, OBJECT_TYPES } from "appConstants";
import { QUICK_VIEW_CONFIG } from "quickViewConfig";

const COMMENT_OBJECT_TYPE = OBJECT_TYPES.COMMENT.fullString;

const quickViewStyles = {
  height: "93vh",
  display: "flex",
  flexDirection: "column",
  "& b": { fontWeight: "fontWeightBold" },
  "& p": { margin: "0.3rem 0" },
  "& table": { display: "inline", borderCollapse: "collapse" },
  "& th": {
    border: "1px solid black",
    textAlign: "left",
    padding: "0 10px",
    backgroundColor: "tableHeaderFooter",
  },
  "& td": {
    border: "1px solid black",
    padding: "0 10px",
    whiteSpace: "nowrap",
    verticalAlign: "top",
  },
  "& thead": { backgroundColor: "tableHeaderFooter" },
  "& tfoot": {
    "& tr": {
      backgroundColor: "tableHeaderFooter",
      border: "1px solid black",
      fontWeight: "bold",
    },
    "& td": { borderLeft: "none", borderRight: "none", textAlign: "right" },
  },
  "& .MuiFormControlLabel-labelPlacementStart": {
    marginLeft: 0,
    marginRight: 0,
  },
  "& .MuiFormControlLabel-root": { displayPrint: "none" },
};

export function QuickView(props) {
  const { id, onClose, objectType } = props;

  const dispatch = useDispatch();

  const quickViewConfig = QUICK_VIEW_CONFIG[objectType];
  const {
    honorShipFromAddressOfLocationSetting,
    signatureLinesLabel,
    addressConfig,
    headerFields,
    fields,
    isSalesTransaction,
    showStatusLink,
    titleSetting,
    footerSetting,
    tableTotals,
  } = quickViewConfig;

  const settings = useSelector((state) => state.userCompanySettings.settings);
  const { hideItemName, companyHeader, shipFromAddressOfLocation } = settings;

  const { hasAtLeastProPlan } = usePlans();

  const [record, setRecord] = useState(null);
  const [headerBlock, setHeaderBlock] = useState(null);
  const [comments, setComments] = useState(null);
  const [showItemNames, setShowItemNames] = useState(!hideItemName);
  const [showBackordered, setShowBackordered] = useState(true);
  const transactionCustomFieldDefs = useCustomFieldDefinitions(objectType);
  const logoInfo = useLogo();

  useEffect(() => {
    async function _getRecord() {
      const record = await getRecordWithParams(
        objectType,
        id,
        objectType === OBJECT_TYPES.SHIPMENT.fullString
          ? { includeBackordered: "true" }
          : {}
      );
      if (isSalesTransaction) {
        // add "total" calculation rows for sales transactions
        record.lines.push({ isBlankRow: true });
        tableTotals.forEach(({ label, field, formatFunc, alwaysShow }) => {
          if (isNonZeroDecimalOrMoney(record[field]) || alwaysShow) {
            record.lines.push({
              isTotalsRow: true,
              label,
              value: formatFunc
                ? formatFunc(record[field], record)
                : formatMoneyWithAdornments(record[field]),
            });
          }
        });
      }
      setRecord(record);
    }
    _getRecord();
  }, [id, objectType, isSalesTransaction, tableTotals]);

  useEffect(() => {
    async function getCommentHistory() {
      const type = getObjectFromFullString(objectType).typeString;
      const commentHistory = await getList(COMMENT_OBJECT_TYPE, { type, id });
      setComments(commentHistory);
    }
    getCommentHistory();
  }, [objectType, id]);

  useEffect(() => {
    async function setAddressOfLocation() {
      if (!record) {
        return null;
      }
      const locationInfo = await getRecord("location", record.location.id);
      setHeaderBlock(formatCompanyInfo(locationInfo, true));
    }
    if (!honorShipFromAddressOfLocationSetting || !shipFromAddressOfLocation) {
      setHeaderBlock(companyHeader);
    } else {
      setAddressOfLocation();
    }
  }, [
    companyHeader,
    honorShipFromAddressOfLocationSetting,
    shipFromAddressOfLocation,
    record,
  ]);

  async function handleDeleteComment(id) {
    const { success } = await deleteRecord(COMMENT_OBJECT_TYPE, id);
    if (success) {
      setComments((prevRecord) =>
        prevRecord.filter((comment) => comment.id !== id)
      );
    }
    const message = success
      ? i18n("alert.SuccessDeletedComment")
      : i18n("alert.ErrorDeletedComment");
    dispatch(
      openAlert({
        type: success ? "success" : "error",
        message,
      })
    );
  }

  return (
    <Box sx={quickViewStyles}>
      <QuickViewHeader
        handleClose={onClose}
        objectType={objectType}
        text={record?.number}
      />
      {!record && <Loading />}
      <ObjectEditDialogContent sx={{ height: "100%", overflowY: "auto" }}>
        {record && comments && logoInfo !== undefined ? (
          <>
            <div
              style={{
                display: "flex",
                gap: "2em",
                justifyContent: "space-between",
                marginTop: "1rem",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  {logoInfo && (
                    <div>
                      <ImageFromBase64
                        width={logoInfo.width}
                        height={logoInfo.height}
                        base64String={logoInfo.base64String}
                      />
                    </div>
                  )}
                  <div>
                    <Typography
                      variant="div"
                      sx={{
                        whiteSpace: "pre-wrap",
                        display: "block",
                        lineHeight: 1.2,
                      }}
                    >
                      {headerBlock}
                    </Typography>
                  </div>
                </Box>
                <div style={{ marginBottom: "1rem" }}>
                  <Typography
                    variant="h4"
                    sx={{ textAlign: "right", mb: "0.5rem" }}
                  >
                    {titleSetting
                      ? settings[titleSetting]
                      : i18n(`objectType.${objectType}.Sentence`)}
                  </Typography>
                  <RecordFields record={record} list={headerFields} />
                </div>
              </Box>
            </div>
            <VSpace space={1} />
            {objectType === OBJECT_TYPES.BUILD.fullString && (
              <BuildOutput output={record.outputs[0]} />
            )}
            {addressConfig && (
              <>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {addressConfig.map(({ name, label }, i) => {
                    return (
                      <Location key={i} address={record[name]} label={label} />
                    );
                  })}
                </div>
                <VSpace space={2} />
              </>
            )}
            <Box sx={{ display: "flex" }}>
              {!quickViewConfig.hideShowItemNames && (
                <div>
                  <FormControlLabel
                    control={<Switch />}
                    label={i18n("quickView.ShowItemNames")}
                    checked={showItemNames}
                    labelPlacement="start"
                    onChange={(e) => setShowItemNames(e.target.checked)}
                  />
                </div>
              )}

              {quickViewConfig.showBackorderedSwitch && (
                <div>
                  <FormControlLabel
                    control={<Switch />}
                    label={i18n("quickView.ShowBackorderedSwitch")}
                    checked={showBackordered}
                    labelPlacement="start"
                    onChange={(e) => setShowBackordered(e.target.checked)}
                  />
                </div>
              )}
            </Box>

            {quickViewConfig.tableConfig.map(({ type, label }, i, array) => {
              return (
                <Fragment key={i}>
                  {label && <div style={{ fontWeight: "bold" }}>{label}</div>}
                  <LineTable
                    recordId={id}
                    type={type}
                    objectType={objectType}
                    showItemNames={showItemNames}
                    showBackordered={showBackordered}
                    lines={record[type]}
                  />
                  {array[i + 1] && <VSpace space={2} />}
                </Fragment>
              );
            })}
            <VSpace space={2} />
            <RecordFields record={record} list={fields} />
            <VSpace space={2} />
            <CustomFields
              customFieldDefinitions={transactionCustomFieldDefs}
              customFields={record.customFields}
            />
            <VSpace space={2} />
            {showStatusLink && <StatusLink value={record?.statusLink} />}
            {signatureLinesLabel && (
              <SignatureLines label={signatureLinesLabel} />
            )}
            <VSpace space={1} />
            {settings[footerSetting] && (
              <Typography>{settings[footerSetting]}</Typography>
            )}
            <VSpace space={2} />
            {hasAtLeastProPlan && comments && (
              <CommentTable
                handleDelete={handleDeleteComment}
                currentComment={record.comment}
                commentHistory={comments}
              />
            )}
            <VSpace space={2} />
          </>
        ) : (
          <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
        )}
      </ObjectEditDialogContent>
    </Box>
  );
}
