import { useState } from "react";

import { Dialog, Typography, DialogTitle, Collapse } from "@mui/material";

import { DocumentAdd } from "components/DocumentAdd";
import { DocumentList } from "components/DocumentList";
import { CloseX } from "components/utility/CloseX";

import { i18n } from "services/i18nService";

import { getObjectFromFullString } from "appConstants";

const styles = {
  "& .MuiDialog-paper": { height: "fit-content", maxHeight: "100%" },
};

export function DocumentDialog(props) {
  const { open, closeDocumentDialog, objectType, documents, setDocuments, id } =
    props;
  const { typeString } = getObjectFromFullString(objectType);
  const [record, setRecord] = useState({ description: "", canShare: false });
  const [addDocument, setAddDocument] = useState(false);
  const [documentAdded, setDocumentAdded] = useState(0);

  return (
    <Dialog
      open={open}
      onClose={() => closeDocumentDialog()}
      maxWidth="sm"
      fullWidth
      sx={styles}
    >
      <DialogTitle sx={{ textAlign: "right", paddingBottom: 0 }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h3">
            {addDocument
              ? i18n("document.AddNewDocument")
              : i18n("document.Documents")}
          </Typography>
          {!addDocument && <CloseX onClick={() => closeDocumentDialog()} />}
        </div>
      </DialogTitle>
      <Collapse in={!addDocument} timeout={500}>
        <DocumentList
          id={id}
          objectType={objectType}
          documents={documents}
          setDocuments={setDocuments}
          typeString={typeString}
          setAddDocument={setAddDocument}
          closeDocumentDialog={closeDocumentDialog}
          documentAdded={documentAdded}
          record={record}
          setRecord={setRecord}
        />
      </Collapse>
      <Collapse in={addDocument} timeout={500}>
        <DocumentAdd
          id={id}
          typeString={typeString}
          setAddDocument={setAddDocument}
          setDocumentAdded={setDocumentAdded}
          setDocuments={setDocuments}
          record={record}
          setRecord={setRecord}
        />
      </Collapse>
    </Dialog>
  );
}
