import { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";

import { TableCell } from "components/TableCell";
import { CloseX } from "components/utility/CloseX";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { LinkText } from "components/utility/LinkText";
import { Loading } from "components/utility/Loading";
import { ModalTitle } from "components/utility/ModalTitle";

import { i18n } from "services/i18nService";
import { getJobDashboard } from "services/sosInventoryService/sosApi";
import { formatDate } from "services/utility/dates";
import { handleProgramError } from "services/utility/errors";

import { openModal } from "globalState/modalSlice";

import {
  getObjectFromTypeString,
  OBJECT_TYPES,
  NO_REF_NUMBER_STRING,
} from "appConstants";

const styles = {
  "& .MuiDialog-paper": { minHeight: "50vh", maxHeight: "93vh" },
};

export function JobDashboard(props) {
  const { close, id, identifierText } = props;
  const [records, setRecords] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    async function _getJobDashboard() {
      try {
        setIsLoading(true);
        const records = await getJobDashboard(id);
        const sortedRecords = records.reduce((seed, record) => {
          const { fullString } = getObjectFromTypeString(record.type);
          const lookupString = record.direction
            ? `${fullString}.${record.direction}`
            : fullString;
          if (!seed[lookupString]) {
            seed[lookupString] = [];
          }
          seed[lookupString].push(record);
          return seed;
        }, {});
        setRecords(Object.entries(sortedRecords));
        setIsLoading(false);
      } catch (e) {
        handleProgramError(e);
      }
    }
    _getJobDashboard();
  }, [id, dispatch]);

  function openEditModal(objectType, id) {
    dispatch(openModal({ objectType, modalProps: { id } }));
  }

  function getStatusString(fullString, isClosed) {
    if (fullString !== OBJECT_TYPES.PURCHASE_ORDER.fullString) {
      return "";
    }
    return ` (${i18n(isClosed ? "global.Closed" : "global.Open")})`;
  }

  function getFullString(fullString) {
    return i18n(`objectType.${fullString}.SentencePlural`);
  }

  return (
    <Dialog open={true} onClose={close} maxWidth="sm" fullWidth sx={styles}>
      <DialogTitle
        sx={{
          backgroundColor: "dialogTitleBackground",
          borderBottom: "1px solid",
          borderBottomColor: "lineSeparatorMinor",
          padding: "0.5rem 1rem",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={8}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CloseX onClick={close} />
              <ModalTitle
                label={i18n("objectType.jobDashboard")}
                text={identifierText}
              />
            </div>
          </Grid>
        </Grid>
      </DialogTitle>
      {isLoading && <Loading />}
      <DialogContent>
        {!isLoading ? (
          <TableContainer component={Paper} sx={{ mt: 1, height: "80vh" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{i18n("columns.Type")}</TableCell>
                  <TableCell>{i18n("columns.Date")}</TableCell>
                  <TableCell>{i18n("columns.ReferenceNumber")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {records?.map(([fullString, txns], index) => (
                  <Fragment key={index}>
                    {txns.map((txn, index) => {
                      const contactName = txn.customerName || txn.vendorName;
                      const contactString = `${
                        contactName ? ` (${contactName})` : ""
                      }`;
                      const statusString = getStatusString(
                        fullString,
                        txn.isClosed
                      );
                      return (
                        <TableRow key={index}>
                          {index === 0 && (
                            <TableCell
                              sx={{ verticalAlign: "top" }}
                              rowSpan={txns.length}
                            >
                              {getFullString(fullString)}
                            </TableCell>
                          )}
                          <TableCell>{formatDate(txn.txnDate)}</TableCell>
                          <TableCell>
                            <LinkText
                              onClick={() => openEditModal(fullString, txn.id)}
                            >
                              {txn.refNumber || NO_REF_NUMBER_STRING}
                            </LinkText>
                            <span>{`${contactString}${statusString}`}</span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
        )}
      </DialogContent>
    </Dialog>
  );
}
