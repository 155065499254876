import { i18n } from "services/i18nService";

import { OBJECT_TYPES } from "appConstants";

export const CONFIGURATION_NAVIGATION = [
  {
    routePart: "list",
    label: i18n("settings.list.label"),
    level2Selections: [
      {
        routePart: OBJECT_TYPES.ALERT.fullString,
        label: i18n("objectType.alert.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.AOP_RULE.fullString,
        label: i18n("objectType.aoprule.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.BIN.fullString,
        label: i18n("objectType.bins.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.CHANNEL.fullString,
        label: i18n("objectType.channel.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.CUSTOM_FIELD.fullString,
        label: i18n("objectType.customfield.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.CUSTOMER_MESSAGE.fullString,
        label: i18n("objectType.customermessage.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.CUSTOMER_TYPE.fullString,
        label: i18n("objectType.customertype.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.DOCUMENT.fullString,
        label: i18n("objectType.document.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.FORM_TEMPLATE.fullString,
        label: i18n("objectType.formtemplate.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.LOCATION.fullString,
        label: i18n("objectType.location.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.ORDER_STAGE.fullString,
        label: i18n("objectType.orderstage.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.PAYMENT_METHOD.fullString,
        label: i18n("objectType.paymentmethod.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.PRICE_TIER.fullString,
        label: i18n("objectType.pricetier.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.PRIORITY.fullString,
        label: i18n("objectType.priority.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.PROCESS_TEMPLATE.fullString,
        label: i18n("objectType.processtemplate.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.SALES_REP.fullString,
        label: i18n("objectType.salesrep.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.SHIP_METHOD.fullString,
        label: i18n("objectType.shipmethod.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.TAG.fullString,
        label: i18n("objectType.tag.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.TERM.fullString,
        label: i18n("objectType.terms.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.UOM.fullString,
        label: i18n("objectType.uom.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.VENDOR_MESSAGE.fullString,
        label: i18n("objectType.vendormessage.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.VENDOR_ITEM.fullString,
        label: i18n("objectType.vendoritem.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.WARRANTY.fullString,
        label: i18n("objectType.warranty.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.WORK_CENTER.fullString,
        label: i18n("objectType.workcenter.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.WORKER.fullString,
        label: i18n("objectType.worker.SentencePlural"),
      },
    ],
  },
  {
    routePart: "company",
    label: i18n("settings.company.label"),
    level2Selections: [
      {
        routePart: "general",
        label: i18n("settings.general.label"),
      },
      {
        routePart: "user",
        label: i18n("settings.userAdministration.label"),
      },
    ],
  },
  {
    routePart: "inventory",
    label: i18n("settings.inventory.label"),
    level2Selections: [
      {
        routePart: "general",
        label: i18n("settings.general.label"),
      },
      {
        routePart: "accounts",
        label: i18n("settings.accounts.label"),
      },
      {
        routePart: "transactions",
        label: i18n("settings.transactions.label"),
      },
      {
        routePart: "barcode",
        label: i18n("settings.barcode.label"),
      },
    ],
  },
  {
    routePart: "purchasing",
    label: i18n("settings.purchasing.label"),
    level2Selections: [
      {
        routePart: "general",
        label: i18n("settings.general.label"),
      },
      {
        routePart: "purchaseOrders",
        label: i18n("objectType.purchaseorder.SentencePlural"),
      },
      {
        routePart: "itemReceipts",
        label: i18n("objectType.itemreceipt.SentencePlural"),
      },
      {
        routePart: "returnsToVendors",
        label: i18n("objectType.returntovendor.SentencePlural"),
      },
    ],
  },
  {
    routePart: "sales",
    label: "Sales",
    level2Selections: [
      {
        routePart: "general",
        label: i18n("settings.general.label"),
      },
      {
        routePart: "salesOrders",
        label: i18n("objectType.salesorder.SentencePlural"),
      },
      {
        routePart: "estimates",
        label: i18n("objectType.estimate.SentencePlural"),
      },
      {
        routePart: "invoices",
        label: i18n("objectType.invoice.SentencePlural"),
      },
      {
        routePart: "salesReceipts",
        label: i18n("objectType.salesreceipt.SentencePlural"),
      },
      {
        routePart: "rmas",
        label: i18n("objectType.rma.SentencePlural"),
      },
      {
        routePart: "returns",
        label: i18n("objectType.return.SentencePlural"),
      },
      {
        routePart: "customerPortal",
        label: i18n("settings.customerPortal.label"),
      },
    ],
  },
  {
    routePart: "fulfillment",
    label: i18n("settings.fulfillment.label"),
    level2Selections: [
      {
        routePart: "shipments",
        label: i18n("objectType.shipment.SentencePlural"),
      },
      {
        routePart: "pickTickets",
        label: i18n("objectType.pickticket.SentencePlural"),
      },
    ],
  },
  {
    routePart: "rentals",
    label: i18n("objectType.rental.SentencePlural"),
    level2Selections: [
      {
        routePart: "rentals",
        label: i18n("objectType.rental.SentencePlural"),
      },
      {
        routePart: "rentalReturns",
        label: i18n("objectType.rentalreturn.SentencePlural"),
      },
    ],
  },
  {
    routePart: "payments",
    label: i18n("settings.payments.label"),
    level2Selections: [],
  },
  {
    routePart: "connections",
    label: i18n("settings.connections.label"),
    level2Selections: [
      {
        routePart: "quickbooks",
        label: i18n("settings.quickbooks.label"),
      },
      {
        routePart: "shipstation",
        label: i18n("settings.shipstation.label"),
      },
      {
        routePart: "ups",
        label: i18n("settings.ups.label"),
      },
      {
        routePart: "cart",
        label: i18n("settings.cart.label"),
      },
      {
        routePart: "ftp",
        label: i18n("settings.ftp.label"),
      },
    ],
  },
];

export function getLevel2Selections(level1RoutePart) {
  return CONFIGURATION_NAVIGATION.find(
    ({ routePart }) => routePart === level1RoutePart
  ).level2Selections;
}

let level1Mappings = {};
CONFIGURATION_NAVIGATION.forEach((entry) => {
  level1Mappings[entry.routePart] = entry.label;
});
export const ROUTE_PART_TO_LABEL_LEVEL1 = level1Mappings;

let level2Mappings = {};
CONFIGURATION_NAVIGATION.forEach((level1Entry) => {
  level1Entry.level2Selections.forEach((level2Entry) => {
    level2Mappings[level2Entry.routePart] = level2Entry.label;
  });
});
export const ROUTE_PART_TO_LABEL_LEVEL2 = level2Mappings;
