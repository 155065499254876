import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";

import { addYears, subYears } from "date-fns";

const SECONDS = 1000; // JavaScript delay values are in milliseconds
export const MINUTES = 60 * SECONDS;

// setting date select bounds to catch two-digit date mistakes
export const DATE_SELECT_MIN_DATE = subYears(new Date(), 100);
export const DATE_SELECT_MAX_DATE = addYears(new Date(), 100);

// time inverval to refetch data
export const FETCH_NOTIFICATIONS_INTERVAL = 5 * MINUTES;
export const SYNC_STATUS_INTERVAL_ACTIVE = 5 * SECONDS;
export const SYNC_STATUS_INTERVAL_PASSIVE = 30 * SECONDS;

// plans
export const PLAN_COMPANION = 0;
export const PLAN_PLUS = 1;
export const PLAN_PRO = 2;

// SOS pay
export const SOS_PAY_CREDIT = "Credit";
export const SOS_PAY_ACH = "ACH";

// U+2003 is the Unicode character for "em space" (a wide space)
export const INDENTED_LIST_PADDING = "\u2003\u2003";

// email domain used for support privilege fetch check
export const SOS_EMAIL_DOMAIN = "@sosinventory.com";

export const ITEM_QUICKLIST_FORM_TYPES = {
  PURCHASING: "purchasing",
  SALES: "sales",
  PRODUCTION: "manufacturing", // legacy term used by the back end
};

export const ITEM_QUICKLIST_CASES = {
  ...ITEM_QUICKLIST_FORM_TYPES, // FE and BE tracked item types
  SERIAL: "serial", // only items where serialTracked is true
  LOT: "lot", // only items where lotTracked is true
  ALL: "all", // all inventory items
  CATEGORY: "category", // only items with type "Category"
  ASSEMBLY_AND_KIT: "assembly and kit", // only items with type "Assembly" or "Item Group" ("kit" is the preferred term, which is why we use it for our constant here)
};

// misc
export const FINISHED_GOODS = { id: 1, name: "Finished Goods" };
export const NO_REF_NUMBER_STRING = "[no number]";
export const NO_REF_NAME_STRING = "[no name]";
export const MAX_CHARS_LINE_ITEM_DESCRIPTION = 4000;

// max list results
export const DEFAULT_LIST_MAX_RESULTS = 25;
export const LIST_MAX_RESULTS_OPTIONS = [10, 25, 50, 100, 200];
export const LIST_MAX_MAX_RESULTS = Math.max(...LIST_MAX_RESULTS_OPTIONS);

export const MIN_SUPPORTED_PAGE_SIZE = "1280px";
export const TOP_NAV_BAR_HEIGHT = "50px";
export const CONFIGURATION_BAR_HEIGHT = "70px";

export const CONFIGURATION_PANEL1_WIDTH = "160px";
export const CONFIGURATION_PANEL_2_WIDTH = "160px";

export const LIST_TABLE_HEIGHT_OFFSET = 18;
export const CONFIGURATION_LIST_TABLE_OFFSET = 25;
export const NOTIFICATION_TABLE_HEIGHT_OFFSET = 16;
export const SYNC_TABLE_HEIGHT_OFFSET = 4;

// Fullsteam payment script urls
export const MERCHANT_TRACK_PROD_URL =
  "https://merchanttrack.fullsteampay.net/Account/SingleSignOn";
export const MERCHANT_TRACK_TEST_URL =
  "https://merchanttrack-ext.fullsteampay.net/Account/SingleSignOn";
export const FULLSTEAM_PROD_SCRIPT_URL =
  "https://hostedpayments.fullsteampay.net/js/hostedforms/1.0.0/fullsteam.hostedforms.js";
export const FULLSTEAM_TEST_SCRIPT_URL =
  "https://hostedpayments-ext.fullsteampay.net/js/hostedforms/1.0.0/fullsteam.hostedforms.js";
export const MAIN_SOS_TEST_ACCOUNT_ID = 1201;

// user privileges
export const USER_PRIVILEGE = {
  adjustInventory: "AdjustInventory",
  approvePos: "ApprovePOs",
  admin: "admin",
  buildAssemblies: "BuildAssemblies",
  canChangePricingOnSales: "CCP", // Abbreviated due to v8 cookie issue
  editCustomers: "EditCustomers",
  editEstimates: "EditEstimates",
  editInvoices: "EInv",
  editItems: "EditItems",
  editJobs: "EditJobs",
  editPurchaseOrders: "EditPOs",
  editRentals: "EditRentals",
  editSalesOrders: "EditSalesOrders",
  editSalesReceipts: "EditSRs",
  editSalesReps: "EditSalesReps",
  editShipments: "EditShipments",
  editVendors: "EditVendors",
  editWorkOrders: "EditWOs",
  processPayment: "PrPa",
  receiveInventory: "ReceiveInventory",
  sosSupport: "SosSupport",
  sync: "Sync",
  sysAdmin: "sysAdmin",
  transferInventory: "TransferInventory",
  viewCosts: "ViewCosts",
  viewCustomers: "ViewCustomers",
  viewEstimates: "ViewEstimates",
  viewInventory: "ViewInventory",
  viewInvoices: "VInv",
  viewItems: "ViewItems",
  viewJobs: "ViewJobs",
  viewPurchaseOrders: "ViewPOs",
  viewRentals: "ViewRentals",
  viewSalesOrders: "ViewSalesOrders",
  viewSalesReceipts: "ViewSRs",
  viewShipments: "ViewShipments",
  viewVendors: "ViewVendors",
  viewWorkOrders: "ViewWOs",
};

const privilegeArray = Object.keys(USER_PRIVILEGE).map((key) => [
  USER_PRIVILEGE[key],
  key,
]);
export const PRIVILEGE_STRING_TO_PROPERTY = Object.fromEntries(privilegeArray);

export const OBJECT_TYPES = {
  ACCOUNT: { fullString: "account", typeString: "account" },
  ADJUSTMENT: {
    fullString: "adjustment",
    shortString: "",
    typeString: "inventory adjustment",
    customFieldString: "Adjustments",
    emailBody: "",
  },
  ALERT: { fullString: "alert" },
  AOP_RULE: { fullString: "aoprule" },
  BILL: { fullString: "bill", typeString: "bill" },
  BIN: { fullString: "bins", typeString: "bin" },
  COMMENT: { fullString: "comment" },
  BUILD: {
    fullString: "build",
    shortString: "",
    typeString: "build",
    customFieldString: "Builds",
    emailBody: "",
    reportLink: "BuildAssembly",
  },
  CHANNEL: { fullString: "channel", reportLink: "Channel" },
  CLASS: { fullString: "class", typeString: "class", reportLink: "Class" },
  CREDIT_CARD_CHARGE: {
    fullString: "creditcardcharge",
    typeString: "credit card charge",
  },
  CREDIT_MEMO: { fullString: "creditmemo", typeString: "credit memo" },
  CUSTOMER: {
    fullString: "customer",
    shortString: "",
    typeString: "customer",
    customFieldString: "Customers",
    emailBody: "",
    reportLink: "Customer",
  },
  CUSTOM_FIELD: { fullString: "customfield" },
  CUSTOMER_TYPE: { fullString: "customertype", reportLink: "CustomerType" },
  CUSTOMER_MESSAGE: { fullString: "customermessage" },
  DEPARTMENT: {
    fullString: "department",
    typeString: "department",
    reportLink: "Department",
  },
  DOCUMENT: { fullString: "document" },
  DELETED_ITEM: { fullString: "deleteditem" },
  EMPLOYEE: { fullString: "employee", typeString: "employee" },
  ESTIMATE: {
    fullString: "estimate",
    shortString: "",
    typeString: "estimate",
    customFieldString: "Estimates",
    emailBody: "emailBodyEstimates",
    reportLink: "Estimate",
  },
  FORM_TEMPLATE: { fullString: "formtemplate" },
  FS_PAYMENT: { fullString: "fspayment" },
  INVOICE: {
    fullString: "invoice",
    shortString: "inv",
    // needed due to BE sending inconsistent linkedTransaction
    // transactionType values on payment and shipment records
    linkedTransactionString: "Invoice",
    typeString: "invoice",
    customFieldString: "Invoices",
    emailBody: "emailBodyInvoices",
    reportLink: "Invoice",
  },
  ITEM: {
    fullString: "item",
    shortString: "",
    typeString: "item",
    customFieldString: "Items",
    emailBody: "",
    reportLink: "Item",
  },
  ITEM_RECEIPT: {
    fullString: "itemreceipt",
    shortString: "IR",
    typeString: "item receipt",
    customFieldString: "Item Receipts",
    emailBody: "",
    reportLink: "ItemReceipt",
  },
  JOB: {
    fullString: "job",
    shortString: "",
    typeString: "job",
    customFieldString: "Jobs",
    emailBody: "",
    reportLink: "Job",
  },
  JOURNAL_ENTRY: { fullString: "journalentry", typeString: "journal entry" },
  LOCATION: {
    fullString: "location",
    reportLink: "Location",
  },
  LOT: {
    fullString: "lot",
    shortString: "",
    typeString: "lot",
    customFieldString: "Lots",
    emailBody: "",
    reportLink: "Lot",
  },
  PAYMENT: {
    fullString: "payment",
    typeString: "payment",
    shortString: "payment",
    customFieldString: "Payments",
  },
  ORDER_STAGE: { fullString: "orderstage" },
  PAYMENT_METHOD: { fullString: "paymentmethod", typeString: "payment method" },
  PICK_TICKET: {
    fullString: "pickticket",
    shortString: "pick ticket",
    typeString: "pick ticket",
    customFieldString: "Pick Tickets",
    emailBody: "emailBodyPickTickets",
    reportLink: "PickTicket",
  },
  PRICE_TIER: { fullString: "pricetier" },
  PRIORITY: { fullString: "priority" },
  PROCESS: {
    fullString: "process",
    shortString: "",
    typeString: "process transaction",
    customFieldString: "Process Transactions",
    emailBody: "",
    reportLink: "ProcessTransaction",
  },
  PROCESS_TEMPLATE: { fullString: "processtemplate" },
  PURCHASE_ORDER: {
    fullString: "purchaseorder",
    shortString: "PO",
    typeString: "purchase order",
    customFieldString: "Purchase Orders",
    emailBody: "emailBodyPurchaseOrders",
    reportLink: "PurchaseOrder",
  },
  PURCHASE: { fullString: "purchase", typeString: "purchase" },
  RENTAL: {
    fullString: "rental",
    shortString: "",
    typeString: "rental",
    customFieldString: "Rentals",
    emailBody: "emailBodyRentals",
    reportLink: "Rental",
  },
  RENTAL_RETURN: {
    fullString: "rentalreturn",
    shortString: "RentalReturn",
    typeString: "rental return",
    customFieldString: "Rental Rets",
    emailBody: "",
  },
  REORDER: { fullString: "reorder" },
  RETURN: {
    fullString: "return",
    shortString: "return",
    typeString: "return",
    customFieldString: "Returns",
    emailBody: "",
  },
  RETURN_TO_VENDOR: {
    fullString: "returntovendor",
    shortString: "",
    typeString: "return to vendor",
    customFieldString: "RTVs",
    emailBody: "",
    reportLink: "ReturnToVendor",
  },
  RMA: {
    fullString: "rma",
    shortString: "RMA",
    typeString: "RMA",
    customFieldString: "RMAs",
    emailBody: "emailBodyRMAs",
  },
  SALES_ORDER: {
    fullString: "salesorder",
    shortString: "SO",
    typeString: "sales order",
    customFieldString: "Sales Orders",
    emailBody: "emailBodySalesOrders",
    reportLink: "SalesOrder",
  },
  SALES_RECEIPT: {
    fullString: "salesreceipt",
    shortString: "SR",
    typeString: "sales receipt",
    customFieldString: "Sales Receipts",
    emailBody: "emailBodySalesReceipts",
    reportLink: "SalesReceipt",
  },
  SALES_REP: { fullString: "salesrep" },
  SALES_TERM: { typeString: "sales term" },
  SERIAL: {
    fullString: "serial",
    shortString: "",
    typeString: "serial item",
    customFieldString: "Serials",
    emailBody: "",
  },
  SHIPMENT: {
    fullString: "shipment",
    shortString: "ship",
    typeString: "shipment",
    customFieldString: "Shipments",
    emailBody: "emailBodyShipments",
    reportLink: "Shipment",
  },
  SHIP_METHOD: { fullString: "shipmethod" },
  SYNC_ERROR: {
    fullString: "syncerror",
    endpointUrl: "sync/error",
  },
  SYNC_ITEM: { fullString: "syncitem" },
  TAG: { fullString: "tag" },
  TERM: { fullString: "terms" },
  TEMPLATE_LIBRARY: { fullString: "templatelibrary" },
  TASK: { fullString: "task", typeString: "task" },
  TAX_CODE: { fullString: "taxcode" },
  TRANSFER: {
    fullString: "transfer",
    shortString: "",
    typeString: "transfer",
    customFieldString: "Transfers",
    emailBody: "",
  },
  UOM: { fullString: "uom" },
  UPS_SHIPMENT: { fullString: "upsshipment", typeString: "UPS shipment" },
  USER: { fullString: "user" },
  VENDOR: {
    fullString: "vendor",
    shortString: "",
    typeString: "vendor",
    customFieldString: "Vendors",
    emailBody: "",
    reportLink: "Vendor",
  },
  VENDOR_CREDIT: { typeString: "vendor credit" },
  VENDOR_ITEM: { fullString: "vendoritem" },
  VENDOR_MESSAGE: { fullString: "vendormessage" },
  WARRANTY: { fullString: "warranty" },
  WORK_CENTER: { fullString: "workcenter" },
  WORK_ORDER: {
    fullString: "workorder",
    shortString: "WO",
    typeString: "work order",
    customFieldString: "Work Orders",
    emailBody: "emailBodyWorkOrders",
    reportLink: "WorkOrder",
  },
  WORKER: { fullString: "worker" },
};

// FULL_OBJECT_TYPE_FROM_SHORT will be an object with shortStrings
// as keys and fullStrings as values; so, for example:
// FULL_OBJECT_TYPE_FROM_SHORT["IR"] = "itemreceipt"
const objectTypeArray = Object.entries(OBJECT_TYPES);
const fullFromShortArray = objectTypeArray.map((o) => {
  return [o[1].shortString, o[1].fullString];
});
export const FULL_OBJECT_TYPE_FROM_SHORT =
  Object.fromEntries(fullFromShortArray);

export function getObjectFromFullString(string) {
  return Object.values(OBJECT_TYPES).find(
    ({ fullString }) => fullString === string
  );
}

export function getObjectFromTypeString(string) {
  return Object.values(OBJECT_TYPES).find(
    ({ typeString }) => typeString === string
  );
}

export function getObjectFromReportString(string) {
  return Object.values(OBJECT_TYPES).find(
    ({ reportLink }) => reportLink === string
  );
}

export const DEFAULT_BOX_WEIGHT_UNIT = "lb";
export const DEFAULT_BOX_DIMENSION_UNIT = "in";

export const DEFAULT_DECIMALS_UNROUNDED = 5;
export const DEFAULT_DECIMALS_ROUNDED = 2;

export function getSalesDecimalPlaces(roundSalesPrices) {
  return roundSalesPrices
    ? DEFAULT_DECIMALS_ROUNDED
    : DEFAULT_DECIMALS_UNROUNDED;
}

export const ITEM_TYPES = {
  INVENTORY_ITEM: "Inventory Item",
  NON_INVENTORY_ITEM: "Non-inventory Item",
  CATEGORY: "Category",
  EXPENSE: "Expense",
  ASSEMBLY: "Assembly",
  KIT: "Item Group",
  SERVICE: "Service",
  LABOR: "Labor",
  OVERHEAD: "Overhead",
  OTHER: "Other",
};

export const ITEM_TYPE_OPTIONS = [
  { id: ITEM_TYPES.INVENTORY_ITEM, name: i18n("item.typeOptionInventoryItem") },
  {
    id: ITEM_TYPES.NON_INVENTORY_ITEM,
    name: i18n("item.typeOptionNonInventory"),
  },
  { id: ITEM_TYPES.CATEGORY, name: i18n("item.typeOptionCategory") },
  { id: ITEM_TYPES.EXPENSE, name: i18n("item.typeOptionExpense") },
  { id: ITEM_TYPES.ASSEMBLY, name: i18n("item.typeOptionAssembly") },
  { id: ITEM_TYPES.KIT, name: i18n("item.typeOptionItemGroup") },
  { id: ITEM_TYPES.SERVICE, name: i18n("item.typeOptionService") },
  { id: ITEM_TYPES.LABOR, name: i18n("item.typeOptionLabor") },
  { id: ITEM_TYPES.OVERHEAD, name: i18n("item.typeOptionOverhead") },
  { id: ITEM_TYPES.OTHER, name: i18n("item.typeOptionOther") },
];

export const FROM_FORM_ITEM_TYPE_OPTIONS = ITEM_TYPE_OPTIONS.filter(
  ({ id }) => id !== ITEM_TYPES.CATEGORY
);

export const ITEM_TYPES_WITH_INVENTORY_QUANTITIES = [
  ITEM_TYPES.INVENTORY_ITEM,
  ITEM_TYPES.ASSEMBLY,
];
export const ITEM_TYPES_WITH_AVAILABILITY = [
  ITEM_TYPES.KIT,
  ITEM_TYPES.INVENTORY_ITEM,
  ITEM_TYPES.ASSEMBLY,
];

export const ITEM_TYPES_THAT_CAN_BE_ALWAYS_SHIPPABLE = [
  ITEM_TYPES.NON_INVENTORY_ITEM,
  ITEM_TYPES.EXPENSE,
  ITEM_TYPES.KIT,
  ITEM_TYPES.SERVICE,
  ITEM_TYPES.LABOR,
  ITEM_TYPES.OVERHEAD,
  ITEM_TYPES.OTHER,
];

export const ITEM_TYPES_REQUIRING_BINS = [
  ITEM_TYPES.INVENTORY_ITEM,
  ITEM_TYPES.ASSEMBLY,
];

// Tracks which entities track to/from locations
// Tracks which entities map fromBin to bin on lineitems
export const TO_FROM_BIN_OBJECTS = {
  transfer: { fromBin: "fromBin" },
  rental: { fromBin: "bin" },
  rentalreturn: { fromBin: "bin" },
};

export const ITEM_TYPES_WITH_BOM = [ITEM_TYPES.KIT, ITEM_TYPES.ASSEMBLY];

export const CUSTOMER_DATA_RESULTS_WITH_SINGLE_TXN = [
  OBJECT_TYPES.RENTAL_RETURN.fullString,
  OBJECT_TYPES.RETURN.fullString,
];

export const REQUIRE_BIN_SELECTION_FOR_LOT_SERIAL_SELECT = [
  OBJECT_TYPES.ADJUSTMENT.fullString,
  OBJECT_TYPES.BUILD.fullString,
  OBJECT_TYPES.PICK_TICKET.fullString,
  OBJECT_TYPES.PROCESS.fullString,
  OBJECT_TYPES.RENTAL.fullString,
  OBJECT_TYPES.RETURN_TO_VENDOR.fullString,
  OBJECT_TYPES.SHIPMENT.fullString,
  OBJECT_TYPES.TRANSFER.fullString,
];

export const VIEW_COST_REQUIRED = {
  pdf: [
    OBJECT_TYPES.ITEM_RECEIPT.fullString,
    OBJECT_TYPES.PURCHASE_ORDER.fullString,
    OBJECT_TYPES.RETURN.fullString,
  ],
  email: [
    OBJECT_TYPES.ITEM_RECEIPT.fullString,
    OBJECT_TYPES.PURCHASE_ORDER.fullString,
    OBJECT_TYPES.RETURN.fullString,
  ],
  quickView: [
    OBJECT_TYPES.ITEM_RECEIPT.fullString,
    OBJECT_TYPES.PURCHASE_ORDER.fullString,
    OBJECT_TYPES.RETURN.fullString,
  ],
  history: [
    OBJECT_TYPES.VENDOR.fullString,
    OBJECT_TYPES.ITEM.fullString,
    OBJECT_TYPES.SERIAL.fullString,
  ],
};

export const DEFAULT_ITEM_TYPE = ITEM_TYPE_OPTIONS[0].id;

export const WEIGHT_UNITS = ["lb", "oz", "kg", "g"];

export const VOLUME_UNITS = ["cbm"];

export const DIMENSION_UNITS = ["in", "cm"];

// convert *from* the key unit to grams
export const WEIGHT_CONVERSION_FACTORS = {
  g: new Decimal(1),
  oz: new Decimal(28.3495),
  lb: new Decimal(453.592),
  kg: new Decimal(1000),
};

export const NO_COLUMNS = "[none]";

export const NOT_LOGGED_IN_PATHS = ["/login", "/login/auth"];
export const CONFIGURATION_PATH = "/configuration";
export const HIDDEN_TOP_NAV_PATHS = NOT_LOGGED_IN_PATHS;
export const HIDDEN_LEFT_NAV_PATHS = [
  ...NOT_LOGGED_IN_PATHS,
  CONFIGURATION_PATH,
  "/choosecompany",
  "/expired",
  "/disabled",
];

export const SUPPORTED_IMAGE_TYPES = ["bmp", "gif", "jpeg", "png"];

export const ATTACHABLE_FILE_TYPES = [
  // from the SOS Inventory back end validation of file extensions
  "image/jpeg",
  "image/gif",
  "image/png",
  "image/bmp",
  "application/vnd.ms-excel",
  "application/msword",
  "application/vnd.ms-powerpoint",
  "application/pdf",
  "application/vnd.ms-project",
  "application/zip",
  "application/vnd.rar",
  "image/vnd.dwg",
  "application/x-msaccess",
  "text/csv",
  "application/x-mspublisher",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/macwriteii",
  "application/rtf",
  "text/plain",
  "application/x-tex",
  "application/vnd.wordperfect",
  "application/vnd.ms-works",
  "image/vnd.adobe.photoshop",
  "application/postscript",
  "application/dxf",
  "application/postscript",
  "application/eps",
  "application/x-eps",
  "image/eps",
  "image/x-eps",
  "image/svg+xml",
  "application/vnd.ms-powerpoint",
  "audio/mpeg",
  "video/mp4",
  "video/quicktime",
  "video/x-msvideo",
  "video/x-ms-wmv",
  "audio/x-ms-wma",
  "audio/wav",
  "audio/basic",
  "application/lotus123",
  "video/mpeg",
  "application/x-mspublisher",
  // Microsoft Office OpenXML formats
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  // .dat Too broad, can't find specific MIME type
  // .dbf, .mdf FoxPro, couldn't find MIME type
  // .wpt Corel WordPerfect; couldn't find MIME type
  // .pst Microsoft items like calendar events, contact, email messages
];

export const ATTACHABLE_MS_WORD_TYPES = [
  "application/msword",
  "application/vnd.ms-word",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
];

export const FREIGHT_CLASSES = [
  "50",
  "55",
  "60",
  "65",
  "70",
  "77.5",
  "85",
  "92.5",
  "100",
  "110",
  "125",
  "150",
  "175",
  "200",
  "250",
  "300",
  "400",
  "500",
];

export const SEARCH_ABBREVIATIONS = [
  "cust (customer)",
  "adj (adjustment)",
  "build",
  "job",
  "lot",
  "sn (serial number)",
  "ship (shipment)",
  "proc (process transaction)",
  "pick (pick ticket)",
  "xfer (inventory transfer)",
  "est (estimate)",
  "inv (invoice)",
  "item",
  "wo (work order)",
  "po (purchase order)",
  "ir (item receipt)",
  "rent (rental)",
  "ret (return)",
  "rma",
  "rr (rental return)",
  "rtv (return to vendor)",
  "so (sales order)",
  "sr (sales receipt)",
  "vend (vendor)",
];

export const ALL_FILTER_OPTION = { id: 0, name: i18n("filter.All") };

export const ITEM_HISTORY_DEFAULT_FILTERS = {
  direction: "desc",
  maxResults: DEFAULT_LIST_MAX_RESULTS,
  query: "",
  sort: "",
  start: 1,
  fromLocation: ALL_FILTER_OPTION,
  toLocation: ALL_FILTER_OPTION,
  type: ALL_FILTER_OPTION,
  toDate: null,
  fromDate: null,
};

export const NAME_LINKED_FIELD_OBJECTS = [
  OBJECT_TYPES.CLASS.fullString,
  OBJECT_TYPES.CUSTOMER.fullString,
  OBJECT_TYPES.DELETED_ITEM.fullString,
  OBJECT_TYPES.DEPARTMENT.fullString,
  OBJECT_TYPES.ITEM.fullString,
  OBJECT_TYPES.JOB.fullString,
  OBJECT_TYPES.TASK.fullString,
  OBJECT_TYPES.VENDOR.fullString,
];

export const LIST_PAGE_HIDE_ADD_NEW_OBJECTS = [
  OBJECT_TYPES.DELETED_ITEM.fullString,
  OBJECT_TYPES.FS_PAYMENT.fullString,
  OBJECT_TYPES.SYNC_ITEM.fullString,
  OBJECT_TYPES.TEMPLATE_LIBRARY.fullString,
];

export const SYNC_ITEM_ACTIONS = [
  { id: "Add", name: i18n("global.Add") },
  { id: "Modify", name: i18n("global.Modify") },
  { id: "Delete", name: i18n("global.Delete") },
];

export const SYNC_ITEM_OBJECT_TYPES = [
  { id: "", name: i18n("filter.All") },
  { id: "Account", name: i18n("global.Account") },
  { id: "Bill", name: i18n("global.Bill") },
  { id: "Class", name: i18n("global.Class") },
  { id: "Credit Memo", name: i18n("objectType.creditmemo.Sentence") },
  { id: "Customer", name: i18n("objectType.customer.Sentence") },
  { id: "Customer Type", name: i18n("customer.CustomerType") },
  { id: "Department", name: i18n("global.Department") },
  { id: "Estimate", name: i18n("objectType.estimate.Sentence") },
  { id: "Invoice", name: i18n("objectType.invoice.Sentence") },
  { id: "Item", name: i18n("global.Item") },
  { id: "Journal Entry", name: i18n("objectType.journalentry.Sentence") },
  { id: "Payment Method", name: i18n("objectType.paymentmethod.Sentence") },
  { id: "Purchase", name: i18n("objectType.purchase.Sentence") },
  { id: "Purchase Order", name: i18n("objectType.purchaseorder.Sentence") },
  { id: "Sales Receipt", name: i18n("objectType.salesreceipt.Sentence") },
  { id: "Sales Term", name: i18n("objectType.salesterm.Sentence") },
  { id: "Vendor", name: i18n("objectType.vendor.Sentence") },
  { id: "Vendor Credit", name: i18n("objectType.vendorcredit.Sentence") },
];

export const SYNC_ITEM_OBJECT_TYPES_GO_TO = [
  OBJECT_TYPES.CLASS.typeString,
  OBJECT_TYPES.DEPARTMENT.typeString,
  OBJECT_TYPES.CUSTOMER.typeString,
  OBJECT_TYPES.CUSTOMER_TYPE.typeString,
  OBJECT_TYPES.ESTIMATE.typeString,
  OBJECT_TYPES.INVOICE.typeString,
  OBJECT_TYPES.ITEM.typeString,
  OBJECT_TYPES.PURCHASE_ORDER.typeString,
  OBJECT_TYPES.SALES_RECEIPT.typeString,
  OBJECT_TYPES.VENDOR.typeString,
];

export const MONTHS = [
  i18n("calendar.january"),
  i18n("calendar.february"),
  i18n("calendar.march"),
  i18n("calendar.april"),
  i18n("calendar.may"),
  i18n("calendar.june"),
  i18n("calendar.july"),
  i18n("calendar.august"),
  i18n("calendar.september"),
  i18n("calendar.october"),
  i18n("calendar.november"),
  i18n("calendar.december"),
];

export const DAYS_OF_WEEK = [
  i18n("calendar.sunday"),
  i18n("calendar.monday"),
  i18n("calendar.tuesday"),
  i18n("calendar.wednesday"),
  i18n("calendar.thursday"),
  i18n("calendar.friday"),
  i18n("calendar.saturday"),
];
