// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import {
  extractName,
  formatCompanyInfo,
  formatDateTimeToDate,
  truncate,
  formatDocumentInfo,
  formatNumberLinkField,
  formatMoneyWithAdornments,
  formatBooleanToYesOrBlank,
} from "services/utility/formatting";
import { formatLinkableItem } from "services/utility/formatting";
import { extractDescription } from "services/utility/formatting";
import { formatQuantityWithUom } from "services/utility/formatting";
import {
  inventoryItemTypeEditStrFunction,
  textTypeEditStrFunction,
  decimalTypeEditStrFunction,
  numberTypeEditStrFunction,
  linkedTransactionTypeEditStrFunction,
  referenceTypeEditStrFunction,
  arrayOfReferencesTypeEditStrFunction,
  dateTypeEditStrFunction,
} from "services/utility/list";
import { parseHTML } from "services/utility/parsing";
import { TYPES } from "services/utility/schema";
import { starColumn } from "services/utility/schema";

import { DEFAULT_DECIMALS_UNROUNDED } from "appConstants";
import { NO_REF_NUMBER_STRING } from "appConstants";

export const columnInfo = [
  starColumn,
  {
    name: "number",
    fixed: true,
    heading: i18n("columns.ReferenceNumber"),
    formatFunc: formatNumberLinkField,
    minWidth: "7em",
    linkField: true,
    sortable: true,
  },
  {
    name: "date",
    heading: i18n("columns.TxnDate"),
    formatFunc: formatDateTimeToDate,
    defaultSort: true,
    sortDirection: "asc",
    minWidth: "8em",
    sortable: true,
    default: true,
  },
  {
    name: "customer",
    heading: i18n("columns.Customer"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "location",
    heading: i18n("columns.Location"),
    formatFunc: extractName,
    sortable: true,
    default: true,
  },
  {
    name: "comment",
    heading: i18n("columns.Comment"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "shipping",
    heading: i18n("columns.ShipTo"),
    formatFunc: formatCompanyInfo,
    minWidth: "10em",
    maxWidth: "15em",
  },
  {
    name: "shipBy",
    heading: i18n("columns.ShipBy"),
    formatFunc: formatDate,
    minWidth: "10em",
    maxWidth: "15em",
    sortable: true,
  },
  {
    name: "channel",
    heading: i18n("columns.Channel"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "shippingMethod",
    heading: i18n("columns.ShipMethod"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "priority",
    heading: i18n("columns.Priority"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "customerPO",
    heading: i18n("columns.CustomerPO"),
    sortable: true,
  },
  {
    name: "assignedToUser",
    heading: i18n("columns.AssignedTo"),
    formatFunc: extractName,
    minWidth: "10em",
    sortable: true,
  },
  {
    name: "trackingNumber",
    heading: i18n("columns.TrackingNumber"),
    minWidth: "10em",
    sortable: true,
  },
  {
    name: "department",
    heading: i18n("columns.Department"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "customerNotes",
    heading: i18n("columns.CustomerNotes"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
  },
  {
    name: "billing",
    heading: i18n("columns.CustomerAddress"),
    formatFunc: formatCompanyInfo,
    minWidth: "13em",
    maxWidth: "18em",
  },
  {
    name: "shippingAmount",
    heading: i18n("columns.ShippingCost"),
    formatFunc: formatMoneyWithAdornments,
  },
  {
    name: "customerMessage",
    heading: i18n("columns.CustomerMessage"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
  },
  {
    name: "hasSignature",
    heading: i18n("columns.Signature"),
    formatFunc: formatBooleanToYesOrBlank,
  },
];

export function getEmptyRecord(settings) {
  return {
    starred: 0,
    syncToken: 1,
    number: "",
    date: new Date(),
    customer: null,
    location: null,
    billing: {
      company: null,
      contact: null,
      phone: null,
      email: null,
      addressName: null,
      addressType: null,
      address: {
        line1: null,
        line2: null,
        line3: null,
        line4: null,
        line5: null,
        city: null,
        stateProvince: null,
        postalCode: null,
        country: null,
      },
    },
    shipping: {
      company: null,
      contact: null,
      phone: null,
      email: null,
      addressName: null,
      addressType: null,
      address: {
        line1: null,
        line2: null,
        line3: null,
        line4: null,
        line5: null,
        city: null,
        stateProvince: null,
        postalCode: null,
        country: null,
      },
    },
    channel: null,
    department: null,
    priority: null,
    assignedToUser: null,
    shippingMethod: null,
    trackingNumber: null,
    linkedTransaction: null,
    customerMessage: null,
    comment: null,
    customerNotes: null,
    customFields: null,
    customerPO: null,
    shipBy: null,
    shippingAmount: new Decimal(0),
    total: new Decimal(0),
    forceToShipStation: settings.shipStationEnabled,
    archived: false,
    summaryOnly: false,
    hasSignature: false,
    trackingLink: null,
    documents: [],
    keys: null,
    values: null,
    createBillForShippingAmount: false,
    lines: [],
  };
}

export const LINE_ITEM_METADATA = {
  lineNumber: {},
  item: {
    type: TYPES.reference,
    editStr: inventoryItemTypeEditStrFunction,
    initialValue: { id: null, name: null, description: null },
  },
  description: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  quantity: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  // there is a "shipped" field on all sales and fulfillment transactions;
  // this field should be ignored on shipment transactions, although it
  // sounds like a shipment field; the quantity shipped on a shipment trans-
  // action is in the "quantity" field
  weight: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: numberTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weightunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  volume: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  volumeunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  class: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  jobWorkcenter: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  bin: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  linkedTransaction: {
    type: TYPES.linkedTransaction,
    editStr: linkedTransactionTypeEditStrFunction,
  },
  linkedTransactiontransactionType: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  linkedTransactionRefNumber: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  linkedTransactionLineNumber: {
    type: TYPES.number,
    editStr: numberTypeEditStrFunction,
  },
  uom: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  serials: {
    type: TYPES.arrayOfReferences,
    editStr: arrayOfReferencesTypeEditStrFunction,
    initialValue: null,
  },
  lot: {
    type: TYPES.reference,
    editStr: textTypeEditStrFunction,
  },
  lotExpiration: {
    type: TYPES.date,
    editStr: dateTypeEditStrFunction,
  },
  onhand: {
    type: TYPES.itemField,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  itemDetails: { initialValue: {} },
  relatedRecords: { initialValue: {} },
};

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: ["shippingAmount", "total"],
  DATE_FIELDS: ["shipBy"],
  DATETIME_FIELDS: ["date"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const LINE_ACTIONS = [
  ["quickView", "pdf", "email"],
  ["shipUPS", "sendEdi"],
  "invoice",
  "template",
  ["RMA", "return"],
  "archive",
  "delete",
  "history",
  "sync",
];
export const BATCH_ACTIONS = [
  ["pdf", "email"],
  "delete",
  "archive",
  ["sendEdi", "updateEdi"],
  "sync",
];
export const REFERENCE_FIELDS = ["location", "customer"];

export const ITEM_CALCULATED_FIELDS = ["onhand"];

export const QUICK_VIEW_CONFIG = {
  titleSetting: "shipmentTitle",
  footerSetting: "shipmentFooter",
  signatureLinesLabel: i18n("colon.ReceivedBy"),
  showBackorderedSwitch: true,
  addressConfig: [
    { name: "billing", label: i18n("address.Customer") },
    { name: "shipping", label: i18n("address.ShipTo") },
  ],
  tableConfig: [{ type: "lines" }],
  headerFields: [
    {
      name: "date",
      formatFunc: formatDateTimeToDate,
      label: i18n("columns.Date"),
    },
    {
      name: "number",
      label: i18n("frmLabel.ShipmentNumber"),
      defaultValue: NO_REF_NUMBER_STRING,
    },
  ],
  fields: [
    {
      name: "customerMessage",
      label: i18n("frmLabel.CustomerMessage"),
    },
  ],
  lines: [
    {
      name: "item",
      formatFunc: formatLinkableItem,
      heading: i18n("columns.Item"),
      align: "left",
      isItemField: true,
    },
    {
      name: "description",
      formatFunc: extractDescription,
      heading: i18n("columns.Description"),
      align: "left",
    },
    {
      name: "quantity",
      formatFunc: formatQuantityWithUom,
      heading: i18n("columns.Shipped"),
      align: "right",
    },
    {
      name: "backOrdered",
      formatFunc: formatQuantityWithUom,
      heading: i18n("columns.Backordered"),
      align: "right",
    },
  ],
};
