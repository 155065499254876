import { useState } from "react";

import {
  FormControlLabel,
  Checkbox,
  Button,
  DialogActions,
  DialogContent,
} from "@mui/material";

import { FileSelector } from "components/FileSelector";
import { Tooltip } from "components/Tooltip";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { ButtonProgress } from "components/utility/ButtonProgress";
import { FormErrors } from "components/utility/FormErrors";
import { HoverHelp } from "components/utility/HoverHelp";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import {
  postDocument,
  getLinkedDocuments,
  SUCCESS,
  POST_DOCUMENT_DUPLICATE_FILENAME,
} from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";
import { isAttachableFile } from "services/utility/files";

import { useErrors } from "hooks/useErrors";

export function DocumentAdd(props) {
  const {
    setAddDocument,
    typeString,
    id,
    setDocumentAdded,
    setDocuments,
    record,
    setRecord,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const { errors, setErrors } = useErrors();

  function handleValueChange(name, value) {
    setRecord((prevRecord) => ({ ...prevRecord, [name]: value }));
  }

  // when a new file is to be uploaded
  async function handlePostDocument() {
    setIsLoading(true);
    const response = await postDocument({
      ...record,
      lines: [{ type: typeString, id }],
    });
    if (response === SUCCESS) {
      setDocumentAdded((prev) => prev + 1);
      setAddDocument(false);
      const response = await getLinkedDocuments(typeString, id);
      setDocuments(response);
    } else {
      if (response === POST_DOCUMENT_DUPLICATE_FILENAME) {
        setErrors((prev) => ({
          ...prev,
          messages: [i18n("document.DuplicateFileName")],
        }));
      } else {
        handleProgramError(new Error("Unexpected error in handlePostDocument"));
      }
    }
    setIsLoading(false);
  }

  function cancel() {
    setRecord({});
    setErrors((prev) => ({ ...prev, messages: [] }));
    setAddDocument(false);
  }

  return (
    <>
      <VSpace space={1} />
      <DialogContent>
        <FormErrors errors={errors} setErrors={setErrors} />
        <FrmResizeTextField
          minRows={2}
          name="description"
          label={i18n("document.Description")}
          onValueBlur={handleValueChange}
          width="95%"
          value={record.description}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) =>
                  handleValueChange(e.target.name, e.target.checked)
                }
                name="canShare"
                checked={record.canShare}
              />
            }
            sx={{ marginLeft: 0, marginRight: 0 }}
            label={i18n("document.Share")}
          />
          <HoverHelp title={i18n("hoverHelp.Share")} sx={{ marginLeft: 0.5 }} />
        </div>
        <VSpace space={1} />
        <FileSelector
          record={record}
          setRecord={setRecord}
          setErrors={setErrors}
          validator={isAttachableFile}
        />
        <VSpace space={2} />
      </DialogContent>
      <DialogActions>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button color="secondary" onClick={cancel}>
            {i18n("button.Cancel")}
          </Button>

          <Tooltip
            title={record.content ? "" : i18n("tooltip.ApplyNewDocument")}
          >
            <div style={{ position: "relative" }}>
              <Button
                color="secondary"
                disabled={!record.content}
                onClick={handlePostDocument}
              >
                {i18n("button.AddDocument")}
              </Button>
              {isLoading && <ButtonProgress color="secondary" />}
            </div>
          </Tooltip>
        </div>
      </DialogActions>
    </>
  );
}
