import { useState } from "react";

import { Clear } from "@mui/icons-material";
import {
  Button,
  IconButton,
  DialogContent,
  DialogActions,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  Box,
} from "@mui/material";

import { DEBOUNCE_WAIT } from "appConfig";

import { DocumentSearch } from "components/DocumentSearch";
import { Tooltip } from "components/Tooltip";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import {
  getLinkedDocuments,
  deleteDocumentLink,
  getDocument,
  getDocuments,
  linkDocument,
} from "services/sosInventoryService/sosApi";
import { downloadDocument } from "services/utility/documents";

const sx = {
  "& .MuiTableCell-root": { border: 0, padding: "0 0.5rem" },
  "& .MuiTable-root": { width: "100%" },
};

async function downloadAttachedDocument(documentId, downloadText) {
  const documentBlob = await getDocument(documentId);
  downloadDocument(documentBlob, downloadText);
}

export function DocumentList(props) {
  const {
    objectType,
    documents,
    setDocuments,
    typeString,
    id,
    closeDocumentDialog,
    setAddDocument,
    setRecord,
  } = props;

  const [timeoutId, setTimeoutId] = useState();
  const [searchDocuments, setSearchDocuments] = useState([]);

  async function onSearchChange(query) {
    if (!query) {
      clearTimeout(timeoutId);
      setSearchDocuments([]);
      return;
    }
    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(async () => {
        const reponse = await getDocuments(query);
        setSearchDocuments(reponse);
      }, DEBOUNCE_WAIT)
    );
  }

  async function handleDelete(documentId) {
    await deleteDocumentLink(typeString, id, documentId);
    const newDocuments = documents.filter(({ id }) => id !== documentId);
    setDocuments(newDocuments);
  }

  async function addDocumentLink(document) {
    const { option } = document;
    // user selects "Add document" option
    if (option.id === "add") {
      setRecord({ description: "", canShare: false });
      setAddDocument(true);
      return;
    }
    // user selects an existing document to link
    await linkDocument(typeString, id, option.id);
    // Re-fetch linked documents as the quick list doesn't have description data
    const documents = await getLinkedDocuments(typeString, id);
    setDocuments(documents);
  }

  return (
    <>
      <DialogContent>
        <DocumentSearch
          loading={!documents}
          options={searchDocuments}
          documents={documents}
          onValueChange={addDocumentLink}
          onInputChange={onSearchChange}
        />
        <VSpace space={2} />

        {documents?.length > 0 ? (
          <Typography variant="h5">
            {i18n("document.AssociatedDocuments", {
              objectType: i18n(`objectType.${objectType}.Lower`),
            })}
          </Typography>
        ) : (
          <Typography variant="h5">
            {i18n("document.NoAssociatedDocuments", {
              objectType: i18n(`objectType.${objectType}.Lower`),
            })}
          </Typography>
        )}
        <VSpace space={0.5} />
        <div
          style={{
            minHeight: "200px",
            maxHeight: "200px",
            overflowY: "auto",
            scrollPadding: "0",
          }}
        >
          {documents?.length > 0 && (
            <>
              <TableContainer sx={sx}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell sx={{ width: "20ch" }}>
                        {i18n("document.FileName")}
                      </TableCell>
                      <TableCell>{i18n("document.Description")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documents.map(({ fileName, description, id }, i) => {
                      return (
                        <TableRow sx={{ maxWidth: "100%" }} key={i}>
                          <TableCell
                            sx={{ textAlign: "center", width: "1rem" }}
                          >
                            <IconButton
                              onClick={() => handleDelete(id)}
                              size="small"
                            >
                              <Clear sx={{ color: "selectIcon" }} />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <Tooltip title={fileName} placement="bottom-start">
                              <div
                                style={{
                                  maxWidth: "20ch",
                                  minWidth: "20ch",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                }}
                              >
                                <Box
                                  sx={{
                                    color: "selectIcon",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    downloadAttachedDocument(id, fileName)
                                  }
                                >
                                  {fileName}
                                </Box>
                              </div>
                            </Tooltip>
                          </TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "grid",
                                maxWidth: "100%",
                                minWidth: "100%",
                              }}
                            >
                              <Tooltip
                                title={description}
                                placement="bottom-start"
                              >
                                <div
                                  style={{
                                    maxWidth: "100%",
                                    minWidth: "100%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {description}
                                </div>
                              </Tooltip>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "end" }}>
        <Button color="secondary" onClick={() => closeDocumentDialog()}>
          {i18n("button.Close")}
        </Button>
      </DialogActions>
    </>
  );
}
