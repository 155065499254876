import { useEffect, useState } from "react";

import { IN_TRANSACTION } from "appConfig";

import { getQuickList } from "services/sosInventoryService/sosApi";

export function useVendors(vendorAddedCount) {
  const [records, setRecords] = useState(null);

  useEffect(() => {
    async function retrieveRecords() {
      const response = await getQuickList("vendor", IN_TRANSACTION);
      setRecords(response.data);
    }
    retrieveRecords();
  }, [vendorAddedCount]);

  return records;
}
