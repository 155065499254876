import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { Box } from "@mui/material";

import { Layout } from "Layout";

import { NEW_RECORD_SKELETON } from "appConfig";

import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmTextFieldWithHoverHelp } from "components/formFields/FrmTextFieldWithHoverHelp";
import { FrmAddFile } from "components/formFields/frm/FrmAddFile";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { FORM_TYPES } from "services/sosInventoryService/formTemplate/schema";
import { getRecord } from "services/sosInventoryService/sosApi";

import { useErrors } from "hooks/useErrors";
import { useRecord } from "hooks/useRecord";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

export function FormTemplate(props) {
  const { id, onClose, objectType } = props;

  const dispatch = useDispatch();
  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();

  const loadingState = useSelector((state) => state.loading.editModal);

  useEffect(() => {
    async function _getRecord() {
      if (id) {
        dispatch(editModalLoadingIndicatorOn());
        const response = await getRecord(objectType, id);
        setRecord(response);
        dispatch(editModalLoadingIndicatorOff());
      } else {
        const emptyRecord = NEW_RECORD_SKELETON[objectType]();
        setRecord(emptyRecord);
      }
    }
    _getRecord();
  }, [id, dispatch, setRecord, objectType]);

  // if new transaction with record not defined
  // show null till record set in useObject
  if (!id && !record) {
    return null;
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={objectType}
        setErrors={setErrors}
        text={record?.name || ""}
        handleClose={onClose}
      />
      {loadingState && <Loading />}
      <ObjectEditDialogContent>
        <Layout
          pageTitle={
            id
              ? `${i18n(`objectType.${objectType}.Sentence`)} ${
                  record?.number || ""
                }`
              : i18n(`objectType.${objectType}.New`)
          }
        >
          <Box flexGrow="1" position="relative" m={2} mb={0}>
            <FormErrors errors={errors} setErrors={setErrors} />
            {record ? (
              <Box>
                <FrmFieldGrid gridAutoFlow="dense">
                  <div style={{ gridColumn: "1 / 2", maxWidth: "24rem" }}>
                    <FrmTextFieldWithHoverHelp
                      autoFocus
                      name="name"
                      label={i18n("frmLabel.Name")}
                      onValueBlur={handleInputFieldChange}
                      value={record.name}
                      helpText={i18n("hoverHelp.UniqueName", {
                        objectType: i18n(`objectType.${objectType}.Lower`),
                      })}
                      error={isInError("name")}
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmFieldGrid gridAutoFlow="dense">
                      <FrmResizeTextField
                        name="description"
                        value={record.description}
                        label={i18n("frmLabel.Description")}
                        minRows={2}
                        width="100%"
                        onValueBlur={handleInputFieldChange}
                      />
                    </FrmFieldGrid>
                  </div>
                  <div style={{ gridColumn: "1 / 2", maxWidth: "24rem" }}>
                    <FrmSelectFromObjects
                      name="form"
                      options={FORM_TYPES}
                      onValueChange={handleInputFieldChange}
                      value={record.form}
                      label={i18n("frmLabel.FormType")}
                      disableClearable
                    />
                  </div>
                  <div style={{ gridColumn: "1 / 2" }}>
                    <FrmAddFile
                      record={record}
                      setRecord={setRecord}
                      setErrors={setErrors}
                      error={isInError("file")}
                    />
                  </div>
                </FrmFieldGrid>
                <VSpace space={1} />
              </Box>
            ) : (
              !isInError("record") && (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )
            )}
          </Box>
          <VSpace space={4} />
        </Layout>
      </ObjectEditDialogContent>
    </>
  );
}
