import { CONFIGURATION_LISTS } from "appConfig";

import { ContactMessageEdit } from "components/ContactMessageEdit";
import { NameObjectEdit } from "components/NameObjectEdit";

import { LINE_ITEM_METADATA as lineItemMetaDataAdjustment } from "services/sosInventoryService/adjustment/schema";
import {
  LINE_ITEM_METADATA as lineItemMetaDataBuild,
  OUTPUTS_METADATA as outputLineItemMetaDataBuild,
  INPUTS_METADATA as inputLineItemMetaDataBuild,
} from "services/sosInventoryService/build/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataEstimate } from "services/sosInventoryService/estimate/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataInvoice } from "services/sosInventoryService/invoice/schema";
import {
  OTHER_COST_METADATA as otherCostMetaDataItemReceipt,
  LINE_ITEM_METADATA as lineItemMetaDataItemReceipt,
} from "services/sosInventoryService/itemReceipt/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataPayment } from "services/sosInventoryService/payment/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataPickTicket } from "services/sosInventoryService/pickTicket/schema";
import {
  OUTPUTS_METADATA as outputLineItemMetaDataProcess,
  INPUTS_METADATA as inputLineItemMetaDataProcess,
  LINE_ITEM_METADATA as lineItemMetaDataProcess,
} from "services/sosInventoryService/process/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataPurchaseOrder } from "services/sosInventoryService/purchaseOrder/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataRental } from "services/sosInventoryService/rental/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataRentalReturn } from "services/sosInventoryService/rentalReturn/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataReturn } from "services/sosInventoryService/return/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataReturnToVendor } from "services/sosInventoryService/returnToVendor/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataRma } from "services/sosInventoryService/rma/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataSalesOrder } from "services/sosInventoryService/salesOrder/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataSalesReceipt } from "services/sosInventoryService/salesReceipt/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataShipment } from "services/sosInventoryService/shipment/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataTransfer } from "services/sosInventoryService/transfer/schema";
import { LINE_ITEM_METADATA as lineItemMetaDataWorkOrder } from "services/sosInventoryService/workOrder/schema";
import { TYPES } from "services/utility/schema";

import { Adjustment } from "views/Adjustments/Adjustment/Adjustment";
import { Alert } from "views/Alerts/Alert/Alert";
import { Bin } from "views/Bins/Bin/Bin";
import { Build } from "views/Builds/Build/Build";
import { Channel } from "views/Channels/Channel/Channel";
import { CustomField } from "views/CustomFields/CustomField/CustomField";
import { Customer } from "views/Customers/Customer/Customer";
import { Estimate } from "views/Estimates/Estimate/Estimate";
import { FormTemplate } from "views/FormTemplates/FormTemplate/FormTemplate";
import { Invoice } from "views/Invoices/Invoice/Invoice";
import { ItemReceipt } from "views/ItemReceipts/ItemReceipt/ItemReceipt/ItemReceipt";
import { Item } from "views/Items/Item/Item";
import { Job } from "views/Jobs/Job/Job";
import { Location } from "views/Locations/Location/Location";
import { Lot } from "views/Lots/Lot/Lot";
import { OrderStage } from "views/OrderStages/OrderStage/OrderStage";
import { Payment } from "views/Payments/Payment/Payment";
import { PickTicket } from "views/PickTickets/PickTicket/PickTicket";
import { Priority } from "views/Priorities/Priority/Priority";
import { ProcessTemplate } from "views/ProcessTemplates/ProcessTemplate/ProcessTemplate";
import { Process } from "views/Processes/Process/Process";
import { PurchaseOrder } from "views/PurchaseOrder/PurchaseOrder/PurchaseOrder";
import { RentalReturn } from "views/RentalReturns/RentalReturn/RentalReturn";
import { Rental } from "views/Rentals/Rental/Rental";
import { Reorder } from "views/Reorder/Reorder";
import { Return } from "views/Returns/Return/Return";
import { ReturnToVendor } from "views/ReturnsToVendor/ReturnToVendor/ReturnToVendor";
import { Rma } from "views/Rmas/Rma/Rma";
import { SalesOrder } from "views/SalesOrders/SalesOrder/SalesOrder";
import { SalesReceipt } from "views/SalesReceipts/SalesReceipt/SalesReceipt";
import { SalesRep } from "views/SalesReps/SalesRep/SalesRep";
import { Serial } from "views/Serials/Serial/Serial";
import { ShipMethod } from "views/ShipMethods/ShipMethod/ShipMethod";
import { Shipment } from "views/Shipments/Shipment/Shipment";
import { SyncItem } from "views/SyncItem/SyncItem";
import { Task } from "views/Tasks/Task/Task";
import { TemplateLibrary } from "views/TemplateLibrary/TemplateLibrary/TemplateLibrary";
import { Term } from "views/Terms/Term/Term";
import { Transfer } from "views/Transfers/Transfer/Transfer";
import { Uom } from "views/Uoms/Uom/Uom";
import { VendorItem } from "views/VendorItems/VendorItem/VendorItem";
import { Vendor } from "views/Vendors/Vendor/Vendor";
import { Warranty } from "views/Warranties/Warranty/Warranty";
import { WorkCenter } from "views/WorkCenters/WorkCenter/WorkCenter";
import { WorkOrder } from "views/WorkOrders/WorkOrder/WorkOrder";
import { Worker } from "views/Workers/Worker/Worker";

import { OBJECT_TYPES } from "appConstants";

export const saveActions = {
  alert: ["goTo", "keepEditing", "new"],
  adjustment: ["goTo", "keepEditing", "new", "pdf"],
  bins: ["goTo", "keepEditing", "new"],
  build: ["goTo", "keepEditing", "new", "pdf"],
  channel: ["goTo", "keepEditing", "new"],
  class: [],
  customermessage: ["goTo", "keepEditing", "new"],
  customfield: ["goTo", "keepEditing", "new"],
  customertype: ["goTo", "keepEditing", "new"],
  customer: ["goTo", "keepEditing", "new", "pdf"],
  department: [],
  estimate: ["goTo", "keepEditing", "new", "pdf", "email"],
  formtemplate: ["goTo", "new"],
  invoice: ["goTo", "keepEditing", "new", "pdf", "email"],
  itemreceipt: ["goTo", "keepEditing", "new", "pdf", "email"],
  item: ["goTo", "keepEditing", "new", "pdf"],
  job: ["goTo", "keepEditing", "new"],
  location: ["goTo", "keepEditing", "new"],
  lot: ["goTo", "keepEditing", "new"],
  orderstage: ["goTo", "keepEditing", "new"],
  paymentmethod: ["goTo", "keepEditing", "new"],
  payment: ["goTo", "new"],
  pickticket: ["goTo", "keepEditing", "new", "pdf", "email"],
  process: ["goTo", "keepEditing", "new", "pdf"],
  processtemplate: ["goTo", "keepEditing", "new"],
  priority: ["goTo", "keepEditing", "new"],
  purchaseorder: ["goTo", "keepEditing", "new", "pdf", "email"],
  rentalreturn: ["goTo", "keepEditing", "new", "pdf"],
  reorder: ["goTo", "keepEditing", "new"],
  return: ["goTo", "keepEditing", "new", "pdf"],
  returntovendor: ["goTo", "keepEditing", "new", "pdf"],
  salesorder: ["goTo", "keepEditing", "new", "pdf", "email"],
  salesreceipt: ["goTo", "keepEditing", "new", "pdf", "email", "ship"],
  salesrep: ["goTo", "keepEditing", "new"],
  serial: ["goTo", "keepEditing", "new"],
  shipment: ["goTo", "keepEditing", "new", "pdf", "email"],
  shipmethod: ["goTo", "keepEditing", "new"],
  syncitem: [],
  tag: ["goTo", "keepEditing", "new"],
  task: ["goTo", "keepEditing", "new"],
  terms: ["goTo", "keepEditing", "new"],
  templatelibrary: ["goTo", "new"],
  transfer: ["goTo", "keepEditing", "new", "pdf", "email"],
  uom: ["goTo", "keepEditing", "new"],
  vendor: ["goTo", "keepEditing", "new", "pdf"],
  vendoritem: ["goTo", "keepEditing", "new"],
  vendormessage: ["goTo", "keepEditing", "new"],
  workorder: ["goTo", "keepEditing", "new", "pdf"],
  rental: ["goTo", "keepEditing", "new", "pdf", "email"],
  rma: ["goTo", "keepEditing", "new", "pdf", "email"],
  warranty: ["goTo", "keepEditing", "new"],
  worker: ["goTo", "keepEditing", "new"],
  workcenter: ["goTo", "keepEditing", "new"],
};

export const OBJECT_EDIT = {
  adjustment: Adjustment,
  alert: Alert,
  bins: Bin,
  build: Build,
  class: NameObjectEdit,
  customermessage: ContactMessageEdit,
  customertype: NameObjectEdit,
  customfield: CustomField,
  channel: Channel,
  customer: Customer,
  department: NameObjectEdit,
  estimate: Estimate,
  formtemplate: FormTemplate,
  invoice: Invoice,
  item: Item,
  itemreceipt: ItemReceipt,
  job: Job,
  location: Location,
  lot: Lot,
  orderstage: OrderStage,
  payment: Payment,
  paymentmethod: NameObjectEdit,
  pickticket: PickTicket,
  process: Process,
  processtemplate: ProcessTemplate,
  priority: Priority,
  purchaseorder: PurchaseOrder,
  rental: Rental,
  rentalreturn: RentalReturn,
  reorder: Reorder,
  return: Return,
  returntovendor: ReturnToVendor,
  rma: Rma,
  salesorder: SalesOrder,
  salesreceipt: SalesReceipt,
  salesrep: SalesRep,
  serial: Serial,
  shipment: Shipment,
  shipmethod: ShipMethod,
  syncitem: SyncItem,
  tag: NameObjectEdit,
  task: Task,
  terms: Term,
  templatelibrary: TemplateLibrary,
  transfer: Transfer,
  uom: Uom,
  vendor: Vendor,
  vendoritem: VendorItem,
  vendormessage: ContactMessageEdit,
  warranty: Warranty,
  workorder: WorkOrder,
  workcenter: WorkCenter,
  worker: Worker,
};

export const NON_FULL_SCREEN_EDIT_MODALS = [
  ...CONFIGURATION_LISTS,
  OBJECT_TYPES.CUSTOMER.fullString,
  OBJECT_TYPES.CLASS.fullString,
  OBJECT_TYPES.DEPARTMENT.fullString,
  OBJECT_TYPES.FORM_TEMPLATE.fullString,
  OBJECT_TYPES.ITEM.fullString,
  OBJECT_TYPES.JOB.fullString,
  OBJECT_TYPES.LOT.fullString,
  OBJECT_TYPES.REORDER.fullString,
  OBJECT_TYPES.SERIAL.fullString,
  OBJECT_TYPES.SYNC_ITEM.fullString,
  OBJECT_TYPES.TASK.fullString,
  OBJECT_TYPES.VENDOR.fullString,
];

export const NO_DOCUMENTS_OBJECTS_TYPES = [
  OBJECT_TYPES.ALERT.fullString,
  OBJECT_TYPES.BIN.fullString,
  OBJECT_TYPES.CHANNEL.fullString,
  OBJECT_TYPES.CLASS.fullString,
  OBJECT_TYPES.CUSTOM_FIELD.fullString,
  OBJECT_TYPES.CUSTOMER_MESSAGE.fullString,
  OBJECT_TYPES.CUSTOMER_TYPE.fullString,
  OBJECT_TYPES.DEPARTMENT.fullString,
  OBJECT_TYPES.DOCUMENT.fullString,
  OBJECT_TYPES.FORM_TEMPLATE.fullString,
  OBJECT_TYPES.LOCATION.fullString,
  OBJECT_TYPES.ORDER_STAGE.fullString,
  OBJECT_TYPES.PAYMENT_METHOD.fullString,
  OBJECT_TYPES.PRIORITY.fullString,
  OBJECT_TYPES.PROCESS_TEMPLATE.fullString,
  OBJECT_TYPES.PRICE_TIER.fullString,
  OBJECT_TYPES.SALES_REP.fullString,
  OBJECT_TYPES.SHIP_METHOD.fullString,
  OBJECT_TYPES.SYNC_ITEM.fullString,
  OBJECT_TYPES.TAG.fullString,
  OBJECT_TYPES.TASK.fullString,
  OBJECT_TYPES.TEMPLATE_LIBRARY.fullString,
  OBJECT_TYPES.TERM.fullString,
  OBJECT_TYPES.UOM.fullString,
  OBJECT_TYPES.UPS_SHIPMENT.fullString,
  OBJECT_TYPES.USER.fullString,
  OBJECT_TYPES.VENDOR_ITEM.fullString,
  OBJECT_TYPES.VENDOR_MESSAGE.fullString,
  OBJECT_TYPES.WARRANTY.fullString,
  OBJECT_TYPES.WORK_CENTER.fullString,
  OBJECT_TYPES.WORKER.fullString,
];

const OTHER_COST_METADATA = {
  itemreceipt: otherCostMetaDataItemReceipt,
};

export const LINE_ITEM_METADATA = {
  adjustment: lineItemMetaDataAdjustment,
  build: lineItemMetaDataBuild,
  estimate: lineItemMetaDataEstimate,
  itemreceipt: lineItemMetaDataItemReceipt,
  invoice: lineItemMetaDataInvoice,
  purchaseorder: lineItemMetaDataPurchaseOrder,
  payment: lineItemMetaDataPayment,
  pickticket: lineItemMetaDataPickTicket,
  process: lineItemMetaDataProcess,
  rental: lineItemMetaDataRental,
  rentalreturn: lineItemMetaDataRentalReturn,
  return: lineItemMetaDataReturn,
  returntovendor: lineItemMetaDataReturnToVendor,
  rma: lineItemMetaDataRma,
  salesorder: lineItemMetaDataSalesOrder,
  salesreceipt: lineItemMetaDataSalesReceipt,
  shipment: lineItemMetaDataShipment,
  transfer: lineItemMetaDataTransfer,
  workorder: lineItemMetaDataWorkOrder,
};

export const OUTPUT_METADATA = {
  process: outputLineItemMetaDataProcess,
  build: outputLineItemMetaDataBuild,
};
export const INPUT_METADATA = {
  process: inputLineItemMetaDataProcess,
  build: inputLineItemMetaDataBuild,
};

const lineItemFields = {};
export let EMPTY_LINE_ITEM = {};
[
  OBJECT_TYPES.ITEM_RECEIPT.fullString,
  OBJECT_TYPES.PURCHASE_ORDER.fullString,
  OBJECT_TYPES.RETURN_TO_VENDOR.fullString,
  OBJECT_TYPES.ESTIMATE.fullString,
  OBJECT_TYPES.SALES_ORDER.fullString,
  OBJECT_TYPES.SALES_RECEIPT.fullString,
  OBJECT_TYPES.INVOICE.fullString,
  OBJECT_TYPES.SHIPMENT.fullString,
  OBJECT_TYPES.PAYMENT.fullString,
  OBJECT_TYPES.PICK_TICKET.fullString,
  OBJECT_TYPES.TRANSFER.fullString,
  OBJECT_TYPES.WORK_ORDER.fullString,
  OBJECT_TYPES.ADJUSTMENT.fullString,
  OBJECT_TYPES.PROCESS.fullString,
  OBJECT_TYPES.BUILD.fullString,
  OBJECT_TYPES.RENTAL.fullString,
  OBJECT_TYPES.RENTAL_RETURN.fullString,
  OBJECT_TYPES.RMA.fullString,
  OBJECT_TYPES.RETURN.fullString,
].forEach((object) => {
  EMPTY_LINE_ITEM[object] = getEmptyLineItem(object, LINE_ITEM_METADATA);
  lineItemFields[object] = {
    [TYPES.number]: getArrayofType(object, LINE_ITEM_METADATA, "number"),
    [TYPES.decimal]: getArrayofType(object, LINE_ITEM_METADATA, "decimal"),
    [TYPES.money]: getArrayofType(object, LINE_ITEM_METADATA, "money"),
    [TYPES.reference]: getArrayofType(object, LINE_ITEM_METADATA, "reference"),
    [TYPES.date]: getArrayofType(object, LINE_ITEM_METADATA, "date"),
    [TYPES.itemField]: getArrayofType(object, LINE_ITEM_METADATA, "itemField"),
  };
});

const outputLineItemFields = {};
export let EMPTY_OUTPUT_LINE_ITEM = {};
[OBJECT_TYPES.PROCESS.fullString, OBJECT_TYPES.BUILD.fullString].forEach(
  (object) => {
    EMPTY_OUTPUT_LINE_ITEM[object] = getEmptyLineItem(object, OUTPUT_METADATA);
    outputLineItemFields[object] = {
      [TYPES.number]: getArrayofType(object, OUTPUT_METADATA, "number"),
      [TYPES.decimal]: getArrayofType(object, OUTPUT_METADATA, "decimal"),
      [TYPES.money]: getArrayofType(object, OUTPUT_METADATA, "money"),
      [TYPES.reference]: getArrayofType(object, OUTPUT_METADATA, "reference"),
      [TYPES.date]: getArrayofType(object, OUTPUT_METADATA, "date"),
      [TYPES.itemField]: getArrayofType(object, OUTPUT_METADATA, "itemField"),
    };
  }
);

const inputLineItemFields = {};
export let EMPTY_INPUT_LINE_ITEM = {};
[OBJECT_TYPES.PROCESS.fullString, OBJECT_TYPES.BUILD.fullString].forEach(
  (object) => {
    EMPTY_INPUT_LINE_ITEM[object] = getEmptyLineItem(object, INPUT_METADATA);
    inputLineItemFields[object] = {
      [TYPES.number]: getArrayofType(object, INPUT_METADATA, "number"),
      [TYPES.decimal]: getArrayofType(object, INPUT_METADATA, "decimal"),
      [TYPES.money]: getArrayofType(object, INPUT_METADATA, "money"),
      [TYPES.reference]: getArrayofType(object, INPUT_METADATA, "reference"),
      [TYPES.date]: getArrayofType(object, INPUT_METADATA, "date"),
      [TYPES.itemField]: getArrayofType(object, INPUT_METADATA, "itemField"),
    };
  }
);

export const LINE_ITEM_FIELDS = lineItemFields;
export const OUTPUT_LINE_ITEM_FIELDS = outputLineItemFields;
export const INPUT_LINE_ITEM_FIELDS = inputLineItemFields;

export const EMPTY_OTHER_COST = {
  itemreceipt: getEmptyOtherCost("itemreceipt"),
};

export const OTHER_COST_FIELDS = {
  itemreceipt: getOtherCostFields("itemreceipt"),
};

function getEmptyLineItem(objectType, metaData) {
  return Object.fromEntries(
    Object.entries(metaData[objectType]).map(([key, value]) => {
      const initialValue =
        typeof value.initialValue !== "undefined" ? value.initialValue : null;
      return [key, initialValue];
    })
  );
}

function getArrayofType(objectType, metaData, type) {
  const arrayOfMetadata = Object.entries(metaData[objectType]);
  const arrayOfType = arrayOfMetadata.filter(
    (entry) => entry[1].type === TYPES[type]
  );
  return arrayOfType.map((entry) => entry[0]);
}

function getEmptyOtherCost(objectType) {
  return Object.fromEntries(
    Object.entries(OTHER_COST_METADATA[objectType]).map(([key, value]) => {
      const initialValue =
        typeof value.initialValue !== "undefined" ? value.initialValue : null;
      return [key, initialValue];
    })
  );
}

function getOtherCostFields(objectType) {
  const arrayOfOtherCostMetadata = Object.entries(
    OTHER_COST_METADATA[objectType]
  );
  const arrayOfOtherCostNumbers = arrayOfOtherCostMetadata.filter(
    (entry) => entry[1].type === TYPES.number
  );
  return { [TYPES.number]: arrayOfOtherCostNumbers.map((entry) => entry[0]) };
}
