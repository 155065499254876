import { useParams } from "react-router-dom";

import { Layout } from "Layout";

import { ListPage } from "components/ListPage/ListPage";

import { i18n } from "services/i18nService";

import { useEditModal } from "hooks/useEditModal";
import { usePrivileges } from "hooks/usePrivileges";

import { Forbidden } from "Forbidden";
import { USER_PRIVILEGE, OBJECT_TYPES } from "appConstants";

const objectType = OBJECT_TYPES.ADJUSTMENT.fullString;

export function AdjustmentList() {
  const { id } = useParams();

  const { hasPrivilegesOrIsAdmin } = usePrivileges();
  const adjustInventory = hasPrivilegesOrIsAdmin(
    USER_PRIVILEGE.adjustInventory
  );
  const viewCosts = hasPrivilegesOrIsAdmin(USER_PRIVILEGE.viewCosts);

  const allowList = adjustInventory && viewCosts;

  useEditModal(objectType, id, allowList);

  return (
    <Layout pageTitle={i18n(`objectType.${objectType}.SentencePlural`)}>
      {allowList ? (
        <ListPage objectType={objectType} identifierProperty="number" />
      ) : (
        <Forbidden />
      )}
    </Layout>
  );
}
