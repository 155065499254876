import { CustomFieldDefinitions } from "classes/CustomFieldDefinitions";
import { Money } from "classes/DecimalClasses";

import { getAllReference } from "services/sosInventoryService/sosApi";

import {
  DATE_SELECT_MIN_DATE,
  DATE_SELECT_MAX_DATE,
  getObjectFromFullString,
} from "appConstants";
import {
  isValid,
  isBefore,
  isAfter,
  getDate,
  getMonth,
  getYear,
  parse,
} from "date-fns";

// input a field name as a string; return boolean, indicating whether or
// not the field is a custom field
export function isCustomField(fieldName) {
  return fieldName.slice(0, 8) === "[custom]";
}

// extract the name of a custom field from the custom field name as given
// in the showFields property of list settings
export function getCustomFieldName(fieldName) {
  return fieldName.slice(8);
}

// add the "[custom]" prefix to a field name
export function makeCustomFieldName(fieldName) {
  return "[custom]" + fieldName;
}

export function getCustomFieldValue(customFieldArray, fieldName) {
  return customFieldArray.find(({ name }) => name === fieldName)?.value;
}

export function reconcileCustomFields(
  customFieldDefinitions,
  inputCustomFields
) {
  if (!customFieldDefinitions) {
    return [];
  }

  const customFields = inputCustomFields || [];
  const newCustomFields = [];
  customFieldDefinitions.asArray.forEach(({ name, id, dataType }) => {
    const matchingField = customFields.reduce(
      (acc, field) => (field.id === id ? { ...field, name } : acc),
      null
    );
    if (matchingField) {
      newCustomFields.push(matchingField);
    } else {
      const value = dataType === "Boolean" ? false : "";
      newCustomFields.push({ id, name, dataType, value });
    }
  });
  return newCustomFields.sort((a, b) => (a.name < b.name ? -1 : 1));
}

/**
 * @name    convertCustomToNative
 * @summary Convert custom field values (strings) to native JS types
 *
 * @description
 * All custom field values are stored as strings, but we want to manipulate
 * them as their native JavaScript types (or our own classes, like Money).
 * This function converts the custom field value strings to these native
 * values.
 *
 * @param (array) customFields - an array of customFields, as they are
 * returned from the API on various objects

 * @returns (array) - an array similar to the input customFields, with the
 * field values converted per above
 *
 * @example
 * converting this set of custom fields to their native representations...
 * [
 *   {
 *       "id": 2,
 *       "name": "Multilinetext",
 *       "value": "",
 *       "dataType": "TextArea"
 *   },
 *   {
 *       "id": 5,
 *       "name": "Checkbox",
 *       "value": "false",
 *       "dataType": "Boolean"
 *   },
 * ]
 * ...returns:
 * [
 *   {
 *       "id": 2,
 *       "name": "Multilinetext",
 *       "value": "",
 *       "dataType": "TextArea"
 *   },
 *   {
 *       "id": 5,
 *       "name": "Checkbox",
 *       "value": false,
 *       "dataType": "Boolean"
 *   },
 * ]
 * (Note that the only change is the value of the custom field with id 5.)
 */
export function convertCustomToNative(customFields) {
  const newCustomFields = [];
  if (!customFields) {
    return newCustomFields;
  }
  customFields.forEach((field) => {
    const { value, dataType } = field;
    switch (dataType) {
      case "Boolean":
        newCustomFields.push({ ...field, value: value === "true" });
        break;
      case "Date":
        newCustomFields.push({ ...field, value: value || null });
        break;
      case "Money":
        newCustomFields.push({
          ...field,
          value: value ? new Money(value) : null,
        });
        break;
      default:
        newCustomFields.push(field);
    }
  });
  return newCustomFields;
}

/**
 * @name    convertNativeToCustom
 * @summary Convert custom field native Javascript types to strings
 *
 * @description
 * See convertCustomToNative, above. This function reverses what
 * convertCustomToNative does, putting the custom fields back into their
 * string forms, which is what the API expects.
 *
 * @param (array) customFields - an array of custom fields, with their
 * values represented as native JavaScript values (or our own classes)

 * @returns (array) - an array of customFields, as they are expected to
 * be formatted by the API
 */
export function convertNativeToCustom(customFields) {
  if (!customFields) {
    return [];
  }
  return customFields.map((field) =>
    field.dataType === "Boolean"
      ? { ...field, value: field.value ? "true" : "false" }
      : field
  );
}

export function copyCustomFieldValues(fromDefs, fromValues, toDefs, toValues) {
  let newToValues = [...toValues];
  fromDefs.asArray.forEach((fromDef) => {
    const matchingToDef = toDefs.asArray.find(({ id }) => id === fromDef.id);
    if (matchingToDef) {
      const fromValue = fromValues.find(({ id }) => id === fromDef.id).value;
      const matchingToValueIndex = newToValues.findIndex(
        ({ id }) => id === matchingToDef.id
      );
      newToValues[matchingToValueIndex].value = fromValue;
    }
  });
  return newToValues;
}

export function pruneOldCustomFieldColumns(columns, customFields) {
  return columns.filter(({ name, isCustomField }) => {
    const customFieldName = getCustomFieldName(name);
    return isCustomField
      ? customFields.asArray.some(({ name }) => name === customFieldName)
      : true;
  });
}

/**
 * @name        getCustomFieldDefinitions
 *
 * @summary     Get the current custom field definitions for an array of object types
 *              or a singular object type.
 *
 * @description Self-explanatory.
 *
 * @param       objectTypeData (array or string)
 *
 * @returns     (CustomFieldDefinitions) - custom field definitions for the
 *              requested object type
 */
export async function getCustomFieldDefinitions(objectTypeData) {
  if (Array.isArray(objectTypeData)) {
    const allDefinitions = await getAllReference("customfield");
    const totalArray = objectTypeData.reduce((seed, objectType) => {
      const { customFieldString } = getObjectFromFullString(objectType);
      const defArray = allDefinitions.filter(({ showOn }) =>
        showOn.includes(customFieldString)
      );
      return [...seed, ...defArray];
    }, []);
    return new CustomFieldDefinitions([...new Set(totalArray)]);
  } else {
    const allDefinitions = await getAllReference("customfield");
    const defArray = allDefinitions.filter(({ showOn }) =>
      showOn.includes(getObjectFromFullString(objectTypeData).customFieldString)
    );
    return new CustomFieldDefinitions(defArray);
  }
}

/**
 * @name        getCustomFieldDefsAsObject
 *
 * @summary     Get the current custom field definitions for an array of object types.
 *
 * @description Self-explanatory.
 *
 * @param       objectTypeData (array)
 *
 * @returns     (CustomFieldDefinitions) - an object with each object type key
 *              corresponding to its matching custom field definitions
 */
export async function getCustomFieldDefsAsObject(objectTypeArray) {
  const allDefinitions = await getAllReference("customfield");
  return objectTypeArray.reduce((seed, objectType) => {
    const { customFieldString } = getObjectFromFullString(objectType);
    const defArray = allDefinitions.filter(({ showOn }) =>
      showOn.includes(customFieldString)
    );
    seed[objectType] = new CustomFieldDefinitions(defArray);
    return seed;
  }, {});
}

/**
 * @name        getCustomFieldNamesFromDefinitions
 *
 * @summary     Given the standard custom field name definitions array,
 *              return an array of just the custom field names
 *
 * @description Self-explanatory.
 *
 * @param       customFieldDefinitions (array of objects)
 *
 * @returns     array of strings, enumerating the custom field names
 */
export function getCustomFieldNamesFromDefinitions(customFieldDefinitions) {
  return customFieldDefinitions.map(({ name }) => name);
}

/**
 * @name        isValidDateString
 *
 * @summary     Takes a date string, and checks to verify that is in both the
 *              correct format (either MM/dd/yyyy or dd/MM/yyyy)
 *
 * @param       dateString (string) -  date string supplied from the text field
 *
 * @param       dateFormat (string) - users date format
 *
 * @returns     Boolean that corresponds to whether the supplied dateString is valid
 *              for that users date format
 */
export function isValidDateString(dateString, dateFormat) {
  // regular expression to match "xx/xx/xxxx" format
  const regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

  // test the input against the regex pattern
  if (!regex.test(dateString)) {
    return false;
  }

  const date = parse(dateString, dateFormat, new Date());
  return (
    isValid(date) &&
    isBefore(date, DATE_SELECT_MAX_DATE) &&
    isAfter(date, DATE_SELECT_MIN_DATE)
  );
}

export function convertDateToDateString(date, dateFormat) {
  const day = getDate(date);
  const month = getMonth(date) + 1; // getMonth returns month zero indexed
  const year = getYear(date);

  return dateFormat === "M/d/yyyy"
    ? `${month}/${day}/${year}`
    : `${day}/${month}/${year}`;
}
