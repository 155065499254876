import { useParams } from "react-router-dom";

import { Layout } from "Layout";

import { ListPage } from "components/ListPage/ListPage";

import { i18n } from "services/i18nService";

import { useEditModal } from "hooks/useEditModal";
import { usePrivileges } from "hooks/usePrivileges";

import { Forbidden } from "Forbidden";
import { OBJECT_TYPES, USER_PRIVILEGE } from "appConstants";

const objectType = OBJECT_TYPES.REORDER.fullString;

export function ReorderList() {
  const { id } = useParams();

  useEditModal(objectType, id);
  const { hasPrivilegesOrIsAdmin } = usePrivileges();
  const allowList = hasPrivilegesOrIsAdmin(USER_PRIVILEGE.editPurchaseOrders);

  return (
    <Layout pageTitle={i18n(`objectType.reorder.SentencePlural`)}>
      {allowList ? (
        <ListPage objectType={objectType} identifierProperty="item" />
      ) : (
        <Forbidden />
      )}
    </Layout>
  );
}
